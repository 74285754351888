import React, { useEffect } from 'react';
import { useValue } from '../../context/ContextProvider';
import img from '../../themes/image'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';

export default function PaymentSuccess() {
    const { t } = useTranslation();
    const {
        state: { },
        dispatch,
    } = useValue();

    const navigate = useNavigate();


    // useEffect
    useEffect(() => {

    })

    const onClickGoto = (a) => {

        dispatch({ type: 'CHECK_BILL_STATUS', payload: [] });
        dispatch({ type: 'GET_HISTORY_BUY_LOTO_BY_LOT_ID', payload: [] });
        dispatch({ type: 'GET_HISTORY_BUY_LOTO', payload: [] });
        dispatch({ type: 'LIST_LOTO_BUY_SUCCESS', payload: [] });
        if (a === 'HOME') {
            dispatch({ type: 'CLOSE_PAYMENT' });
            navigate('/')
        }
    }
    return (
        <>
            <div className="offcanvas-body" style={{ backgroundColor: '#fafafa' }}>
                <div className="hgroup flex-column mt-4">
                    {/* <div className="icons w-48">
                        <img className="w-100" src={img.iconSuccess} alt />
                    </div> */}
                    
                    <div className="icons w-225">
                        <img className="w-100" src={img.ic_login} alt />
                    </div>
                    <h3>{t('txtCongetion')}</h3>
                    <h1 className="green">{t('txtcompletePayment')}</h1>
                    {/* class="green" */}
                </div>

                {/* navigate('/DetailHistory') */}
                <div className="offcanvas-footer h-142">
                    <div className="card-info card-fixed">
                        <a className="btn btn-lg btn-yellow w-100" onClick={() => onClickGoto('HOME')}>
                            <span className="fs-14">{t('BackToMainPage')}</span>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}