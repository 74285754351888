import React, { useEffect } from 'react';
import { Box, List, ListItem, Divider, ListItemText, ListItemAvatar, Avatar, Typography, Paper, IconButton } from '@mui/material';
import { HeaderCompoment } from '../../compoment'
import { useValue } from '../../context/ContextProvider'
import { GetContact } from '../../actions/ResetClient'
import { useTranslation } from "react-i18next";
import { Call, Email } from '@mui/icons-material';

export default function Contact() {
    const { i18n } = useTranslation();
    const {
        state: { tokenInfo, getContact },
        dispatch,
    } = useValue();

    useEffect(() => {

        if (!getContact) {
            if (tokenInfo) {
                const token = tokenInfo.access_token
                GetContact(token, dispatch);
            }
        }
    }, []);



    function stringToColor(string: string) {
        let hash = 0;
        let i;
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        return color;
    }

    function stringAvatar(name: string) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[1][0]}${name.split(' ')[2][0]}`,
        };
    }


    return (
        <Box component="main" sx={{ width: '100%' }}>
            <HeaderCompoment icon={true} icondat={false} textHeader='contact' />
            <br />
            <br />
            <br />
            <br />

            <Paper elevation={12} sx={{ m: 1, p: 1 }}>
                {
                    getContact ? getContact.length > 0 ?
                        getContact.map((row) => (
                            row.contactStatus === 1 ?
                                <List key={row.contactId}>
                                    <ListItem
                                        alignItems="flex-start"
                                        secondaryAction={
                                            <>
                                                <IconButton href={'mailto:' + row.email} aria-label="Email">
                                                    <Email />
                                                </IconButton>
                                                <IconButton href={'tel:' + row.msisdn} aria-label="Call">
                                                    <Call />
                                                </IconButton>
                                            </>
                                        }>
                                        <ListItemAvatar>
                                            <Avatar {...stringAvatar(i18n.language === 'en' ? row.firstNameEn + ' ' + row.lastNameEn : row.firstNameLa + ' ' + row.lastNameLa)} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={i18n.language === 'en' ? row.firstNameEn + ' ' + row.lastNameEn : row.firstNameLa + ' ' + row.lastNameLa}
                                            secondary={
                                                <React.Fragment>
                                                    {
                                                        row.email ?
                                                            <Typography
                                                                sx={{ display: 'inline' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                {row.email}
                                                            </Typography> : null
                                                    }

                                                    {
                                                        row.msisdn ? <Typography>
                                                            {row.msisdn}
                                                        </Typography> : null
                                                    }

                                                    {
                                                        row.descriptionEn || row.descriptionLa ?
                                                            <Typography>
                                                                {i18n.language === 'en' ? row.descriptionEn : row.descriptionLa}
                                                            </Typography> : null
                                                    }

                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </List> : null
                        )) : null : null
                }

            </Paper>

        </Box>
    )
}

