import React from 'react'
import { Box } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import { Login, NotFound, ResetPassword, RegisterAccount,PaymentSuccess } from '../screen'
import { Loading, Notification } from '../compoment'
export default function router() {
    const list = [
        {
            title: 'Login',
            link: '',
            component: <Login />,
        },
        {
            title: 'ResetPassword',
            link: 'ResetPassword',
            component: <ResetPassword />,
        },
        {
            title: 'RegisterAccount',
            link: 'RegisterAccount',
            component: <RegisterAccount />,
        },
        {
            title: 'PaymentSuccess',
            link: 'PaymentSuccess',
            component: <PaymentSuccess />,
        },
        {
            title: 'NotFound',
            link: '/*',
            component: <NotFound />,
        }
    ]
    return (
        <Box
            sx={{
                display: 'flex',
                height: '100vh'
            }}
        >
            <Loading />
            <Notification />
            <Routes basename='/Login'>
                {list.map((item) => (
                    <Route key={item.title} path={item.link} exact element={item.component} />
                ))}
            </Routes>
        </Box>
    )
}





// import React from 'react'
// import { Navigate, useRoutes } from 'react-router-dom';
// import { Login, NotFound, ResetPassword, RegisterAccount } from '../screen'
// // import { Loading, Notification } from '../compoment'
// export default function Router() {

//     return useRoutes([
//         {
//             path: "/",
//             element: <Login />
//         },
//         {
//             path: "/ResetPassword",
//             element: <ResetPassword />,
//         },
//         {
//             path: "/RegisterAccount",
//             element: <RegisterAccount />,
//         },
//         {
//             path: "/",
//             children: [
//                 { path: "404", element: <NotFound /> },
//                 { path: "*", element: <Navigate to="/404" /> },
//                 { path: "/", element: <Navigate to="/" /> },
//             ],
//         },
//         { path: "*", element: <Navigate to="/404" replace /> },
//     ]);
// }