import React, { useEffect } from 'react';
import { Box, styled } from '@mui/material';
import { HeaderCompoment } from '../../compoment'
import { useValue } from '../../context/ContextProvider'
import { GetAppContentByType } from '../../actions/ResetClient'
import { useTranslation } from "react-i18next";

export default function Policy() {
  const { i18n } = useTranslation();
  const {
    state: { tokenInfo, getAppContentByType },
    dispatch,
  } = useValue();

  const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: 10
  });

  useEffect(() => {
    //console.log('Policy:', getAppContentByType)
    if (tokenInfo) {
      const param = '2'
      const token = tokenInfo.access_token
      //console.log('param:', param)
      //console.log('token:', token)
      const displayAlert = false
      GetAppContentByType(token, param, displayAlert, dispatch);
    }
    
  }, []);

  return (
    <Box component="main" sx={{ width: '100%' }}>
      <HeaderCompoment icon={true} icondat={false} textHeader='PrivacyPolicy' />
      <br />
      <br />
      {
        getAppContentByType ? getAppContentByType.appStatus === 1 ?
          <div className='offcanvas-body'>
            <div className='accordion mt-5'>
              <div className="card-accordion">
                <h6 data-bs-toggle="collapse" data-bs-target="#collapse_1" className aria-expanded="true">
                  {i18n.language === 'en' ? getAppContentByType.appTitleEn : getAppContentByType.appTitleLa}
                </h6>
                <div id="collapse_1" className="collapse show" data-bs-parent="#accordion">
                  <div className="card-body">
                    <p className='textJutify'>{i18n.language === 'en' ? getAppContentByType.appContentEn : getAppContentByType.appContentLa}</p>
                    {
                      getAppContentByType.appImgPath ?
                        <Box sx={{ marginBottom: 2 }}>
                          <Img alt='Banner discount' src={getAppContentByType.appImgPath} />
                        </Box> : null
                    }
                    <p className='textJutify'>{i18n.language === 'en' ? getAppContentByType.appDetailEn : getAppContentByType.appDetailLa}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null : null
      }

    </Box>
  )
}

