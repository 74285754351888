import React from 'react'
import './style.css';
import img from '../../themes/image'

export default function CongratLotto() {
    return (
        <div class="offcanvas-body">
            <p class="icon_one goback" href="#">
                <span class="icons" />
            </p>
            <div class="hgroup flex-column mt-2">
                <div class="icons w-225">
                    <img class="w-100" src={img.photoCongran} alt='test' />
                </div>
                <h2>ยินดีด้วยค่ะ</h2>
                <h1 class="green">คุณถูกรางวัลที่ 5</h1>
            </div>
            <div class="card-info1 mt-4">
                <h6 class="my-auto fw-light">รวมรางวัลมูลค่า</h6>
                <h2 class="h1 ms-auto fw-semibold green">฿20,000</h2>
            </div>
            <div class="card-info mt-2">
                <table class="table-total lotto my-1">
                    <tbody>
                        <tr>
                            <th>243256</th>
                            <td width="60%" class="text-center"><strong>ถูกรางวัลที่ 5</strong></td>
                            <td class="green fs-14">฿20,000</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {/*card-info*/}
            <div class="offcanvas-footer mt-5">
                <p class="btn_butt" href="give-money.html">
                    รับรางวัล
                </p>
            </div>
            <p class="p-2" />
        </div>

    )
}
