import React, { useState, useEffect } from "react";
import {
  styled,
  Button,
  Link,
  Box,
  Container,
  Typography,
  Paper,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  TextinputComponent,
  HeaderCompoment,
  OutlinedInputPass,
} from "../../compoment";
import img from "../../themes/image";
import { useValue } from "../../context/ContextProvider";
import { useTranslation } from "react-i18next";
import { getToket } from "../../actions/token";
import { useNavigate } from "react-router-dom";
// import ipify from "ipify";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

export default function SignIn() {
  const { t, i18n } = useTranslation();
  const {
    state: { dataRemember },
    dispatch,
  } = useValue();
  const navigate = useNavigate();
  const [userNameError, setuserNameError] = useState(null);
  const [userName, setuserName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [valueRemamber, setValueRemamber] = useState(false);

  const onChangeLang = () => {
    
    if (i18n.language === "en") {
      i18n.changeLanguage("lo");
    } else {
      i18n.changeLanguage("en");
    }
  };


  const handleClearCach = () => {
    dispatch({ type: 'UPDATE_USER', payload: null });
    dispatch({ type: 'TOKEN_INFO', payload: null });
    dispatch({ type: 'GET_CUSTOMER_INFO_BY_MSISDNNAME', payload: [] });
    dispatch({ type: 'GET_HISTORY_BUY_LOTO', payload: [] });
    dispatch({ type: 'GET_WINNERLIST', payload: [] });
    dispatch({ type: 'GET_HOTNUMBER_BY_LOTO_ID', payload: [] });
    dispatch({ type: 'GET_DASHBOARD', payload: [] });
    dispatch({ type: 'BANNER_APP', payload: [] });
    dispatch({ type: 'RESULT_SEARCH_LOTO', payload: [] })
    dispatch({ type: 'GET_HISTORY_BUY_LOTO_BY_LOT_ID', payload: [] });
    dispatch({ type: 'LIST_LOTO_BUY_SUCCESS', payload: [] });
    dispatch({ type: 'GET_NETWORK_MEMBER', payload: null });
    dispatch({ type: 'GET_CUSTOMER_POINT', payload: null });
    dispatch({ type: 'GET_MEMBEROFPARTNER', payload: [] });
  };



  const onLogin = (event) => {
    event.preventDefault();
    if (userName && password) {
      const user_cus = userName;
      const pass_cus = password;
      const lang = i18n.language || "en";
      getToket(user_cus, pass_cus, valueRemamber, lang, dispatch);
      handleClearCach()
    } else {
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: t("PleaseEnterUserNamePassword"),
        },
      });
    }
  };

  const onuserName = (event) => {
    const value = event.target.value;
    const userNameError =
      !value || value.length < 3 ? t("errorUsername") : null;
    setuserName(value);
    setuserNameError(userNameError);
  };
  const onPassword = (event) => {
    const value = event.target.value;
    const passwordError =
      !value || value.length < 3 ? t("errorUsername") : null;
    setPassword(value);
    setPasswordError(passwordError);
  };

  useEffect(() => {
    //console.log('-----dataRemember---', dataRemember)
    if (dataRemember.length > 0) {
      setuserName(dataRemember[0].username);
      setPassword(dataRemember[0].password);
      setValueRemamber(true);
    } else {
      setuserName("");
      setPassword("");
      setValueRemamber(false);
    }
    // ipify({ useIPv6: false, endpoint: "json" }).then((ip) => {
    //   console.log("--ip:", ip);
    // });
  }, [dataRemember]);

  const onClickCheckRemember = (event) => {
    //console.log('---event.target.checked---', event.target.checked)
    setValueRemamber(event.target.checked);
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#d90404",
        height: "100%",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <HeaderCompoment
          logoHeader={false}
          icon={false}
          icondat={false}
          lang={true}
          textHeader=""
          onChangeLang={() => onChangeLang()}
        />
        <br />
        <br />
        <br />
        <Container component={"main"} maxWidth="lg">
          <Paper elevation={16} sx={{ p: 2, m: 1 }}>
            {/* <Box sx={{ m: 1 }}>
                        <Img src={img.logoapp} alt='logoapp' />
                    </Box> */}
            <Typography component="h1" variant="h5" align="center">
              {t("login")}
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextinputComponent
                margin="normal"
                error={userNameError ? true : false}
                required
                fullWidth
                label={t("userName")}
                value={`${userName}`}
                id="userName"
                name="userName"
                autoComplete="userName"
                onChange={onuserName}
                numLength={20}
                helperText={userNameError}
              />

              <OutlinedInputPass
                label={t("Password")}
                value={`${password}`}
                name="password"
                error={passwordError ? true : false}
                onChange={onPassword}
                autoComplete="current-password"
                numLength={10}
                inputMode="numeric"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={onClickCheckRemember}
                    checked={valueRemamber}
                    color="primary"
                  />
                }
                label={t("RememberMe")}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 2,
                }}
              >
                <Link
                  onClick={() => navigate("/ResetPassword")}
                  variant="body2"
                >
                  {t("ForgotPassword")}
                </Link>
              </Box>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={(e) => onLogin(e)}
                disabled={
                  userNameError || passwordError || !userName || !password
                    ? true
                    : false
                }
              >
                {t("login")}
              </Button>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Link
                  onClick={() => navigate("/RegisterAccount")}
                  variant="body2"
                >
                  {t("Register")}
                </Link>
              </Box>
            </Box>
          </Paper>
        </Container>
      </Box>

      <Box
        sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}
      >
        <Img src={img.imageFooter} alt="logoapp" />
      </Box>
    </Box>
  );
}
