import React, { useState, useEffect } from "react";
import {
  Paper,
  Box,
  Grid,
  Stack,
  styled,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next'
import img from '../../themes/image'
import { useNavigate } from 'react-router-dom';
import { useValue } from '../../context/ContextProvider';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '45%',
  maxHeight: '45%',
  marginBottom: 5
});

const LotoScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: {
      currentUser
    },
    dispatch
  } = useValue();
  const [menuList, setmenuList] = useState([]);


  useEffect(() => {

    // console.log("currentUser:", currentUser)

    if (currentUser) {
      var icon = null;
      if (currentUser.whitelistStatus) {
        icon = [
          {
            id: 1,
            url: img.ic_loto,
            caption: t('BuyLabel'),
            link: 'BuyLoto'
          },
          {
            id: 2,
            url: img.ic_promotion,
            caption: t('Promotion'),
            link: '/ComingSoon'
          },
          {
            id: 3,
            url: img.ic_help,
            caption: t('Help'),
            link: '/Help'
          },
          {
            id: 5,
            url: img.icon_business,
            caption: t('TeamSales'),
            link: '/ReportOfTeam'
          },
          {
            id: 4,
            url: img.ic_member,
            caption: t('member'),
            link: '/SalesSummary'
          }
          
        ];
      } else {

        icon = [
          {
            id: 1,
            url: img.ic_loto,
            caption: t('BuyLabel'),
            link: 'BuyLoto'
          },
          {
            id: 2,
            url: img.ic_promotion,
            caption: t('Promotion'),
            link: '/ComingSoon'
          },
          {
            id: 3,
            url: img.ic_help,
            caption: t('Help'),
            link: '/Help'
          },
          {
            id: 4,
            url: img.ic_member,
            caption: t('member'),
            link: '/SalesSummary'
          }
        ];
      }
      setmenuList(icon)
    }
  },[]);

  const onGetEventClick = (value) => {
    if (value === 'BuyLoto') {
      dispatch({ type: 'UPDATE_SECTION', payload: 1 })
    } else {
      navigate(value)
    }

  }

  return (

    <Paper elevation={12} sx={{ mb: 2, borderRadius: 2, p: 1 }}>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between', m: 1 }}>
            {menuList.slice(0, 5).map((menuList) => (
              <a key={menuList.id} onClick={() => onGetEventClick(menuList.link)}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <Box>
                    <Img alt={menuList.caption} src={menuList.url} />
                    <Typography align='center' fontSize={11}>
                      {menuList.caption}
                    </Typography>
                  </Box>
                </Box>
              </a>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Paper>

  )
}
export default LotoScreen
