import React, { useEffect, useState } from 'react';
import { useValue } from '../../context/ContextProvider';
import img from '../../themes/image'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';
import { formatNumber } from '../../utils/Formater'
import html2canvas from "html2canvas";
import moment from 'moment';
import { reqcheckBillStatusById } from '../../actions/ResetClient'
export default function PaymentCompleted() {
    const ref = React.createRef();
    const { t } = useTranslation();
    const {
        state: { dataBuySuccess, currentUser, checkBillStatusById, tokenInfo },
        dispatch,
    } = useValue();

    const [lotDate, setLotDate] = useState('')
    const [cusName, setCusName] = useState('')
    const [listArray, setListArray] = useState(null)
    const navigate = useNavigate();


    // useEffect
    useEffect(() => {
        if (currentUser) {
            if (currentUser.lotAvaiable) {
                if (currentUser.lotAvaiable.length > 0) {
                    setLotDate(currentUser.lotAvaiable[0].fromLotDate)
                }
            }

            if (currentUser.accInfo) {
                setCusName(currentUser.accInfo.cusName + ' ' + currentUser.accInfo.cusSurname)
            }
        }
        if (dataBuySuccess) {
            if (dataBuySuccess.lstSuccess) {
                if (dataBuySuccess.lstSuccess.length > 0) {
                    setListArray(dataBuySuccess.lstSuccess)
                }
            }
        }
        //console.log('-----checkBillStatusById-----', checkBillStatusById)
        if (checkBillStatusById.length === 0) {
            if (dataBuySuccess && tokenInfo) {
                if (dataBuySuccess.billId && dataBuySuccess.lotId) {
                    const token = tokenInfo.access_token
                    const lotId = dataBuySuccess.lotId
                    const txn = dataBuySuccess.billId
                    reqcheckBillStatusById(token, lotId, txn, dispatch)
                } else {
                    //console.log('Not have dataBuySuccess.billId && dataBuySuccess.lotId')
                }
            } else {
                //console.log('Not have dataBuySuccess tokenInfo')
            }
        } else {
            if (checkBillStatusById.saleItem) {
                //console.log('checkBillStatusById.saleItem:', checkBillStatusById.saleItem)
                if (checkBillStatusById.saleItem.payStatus === 0) {
                    const token = tokenInfo.access_token
                    const lotId = dataBuySuccess.lotId
                    const txn = dataBuySuccess.billId
                    //console.log(':checkBillStatusById.saleItem.payStatus:', checkBillStatusById.saleItem.payStatus)
                    // reqcheckBillStatusById(token, lotId, txn, dispatch)
                    setTimeout(
                        () => onSetTimeout(token, lotId, txn),
                        10000
                    );
                } else if (checkBillStatusById.saleItem.payStatus === 1) {
                    dispatch({ type: 'CHECK_BILL_STATUS', payload: [] });
                    dispatch({ type: 'GET_HISTORY_BUY_LOTO_BY_LOT_ID', payload: [] });
                    dispatch({ type: 'GET_HISTORY_BUY_LOTO', payload: [] });
                    navigate('/PaymentSuccess')

                } else {
                    //console.log('Not have checkBillStatusById.saleItem.payStatus 1')
                }
            } else {
                //console.log('Not have checkBillStatusById.saleItem.payStatus 2')
            }
        }
    })

    const onSetTimeout = (token, lotId, txn) => {
        //console.log('---token--', token)
        //console.log('---lotId--', lotId)
        //console.log('---txn--', txn)
        reqcheckBillStatusById(token, lotId, txn, dispatch)
    }

    const onClickGoto = (a) => {
        //console.log(a)
        // dispatch({ type: 'CLOSE_PAYMENT' });
        dispatch({ type: 'GET_HISTORY_BUY_LOTO_BY_LOT_ID', payload: [] });
        dispatch({ type: 'GET_HISTORY_BUY_LOTO', payload: [] });
        dispatch({ type: 'LIST_LOTO_BUY_SUCCESS', payload: [] });
        if (a === 'HOME') {
            navigate('/')
        } else if (a === 'HISTORY') {
            //console.log('llllllll')
            navigate('/')
            dispatch({ type: 'UPDATE_SECTION', payload: 2 })
        }
    }


    const handleClickTakeScreenShot = () => {
        const { cropPositionTop, cropPositionLeft, cropWidth, cropHeigth } = {
            cropPositionTop: 50,
            cropPositionLeft: 50,
            cropWidth: 1400,
            cropHeigth: 1400
        };

        html2canvas(ref.current).then(canvas => {
            const dateNow = moment(new Date()).format("YYMMDDHHmmss")
            let croppedCanvas = document.createElement("canvas");
            let croppedCanvasContext = croppedCanvas.getContext("2d");

            croppedCanvas.width = cropWidth;
            croppedCanvas.height = cropHeigth;

            croppedCanvasContext.drawImage(canvas, cropPositionLeft, cropPositionTop);

            const a = document.createElement("a");
            a.href = croppedCanvas.toDataURL();
            a.download = "img_" + dateNow + "_234Scociety.png";
            a.click();
        });
    };




    return (

        <div className="offcanvas-body" style={{ backgroundColor: '#fafafa' }}>
            <div className="hgroup flex-column mt-4">
                <div className="icons w-48">
                    <img className="w-100" src={img.iconSuccess} alt />
                </div>
                <h2 className="h1">{t('PaymentCompleted')}</h2>
                {
                    dataBuySuccess ? dataBuySuccess.promptPayQR ? (
                        <>
                            <div id="#screenshot" ref={ref} className="imgQR">
                                <img className='sizeImg' src={`data:image/jpeg;base64,${dataBuySuccess.promptPayQR}`} />
                            </div>
                            <div className="d-flex pt-2">
                                <button className="btn-link fw-light mx-auto" onClick={() => handleClickTakeScreenShot()} type="button">
                                    <span className="icons icon-receipt w-18" />
                                    {t('Record')}
                                </button>
                            </div>
                            <p></p>
                        </>
                    ) : null : null
                }
            </div>
            <div className="card-info receipt">
                <p className="m-0 d-flex fs-12">
                    <span>{t('TransactionCode')}</span>
                    <span className="ms-auto">{dataBuySuccess ? dataBuySuccess.billId : null}</span>
                </p>
                <hr className="dashed my-2" />
                <table className="table-total receipt">
                    <tbody><tr>
                        <td>{t('RecipientName')}</td>
                        <td>{cusName}</td>
                    </tr>
                        <tr>
                            <td>{t('installmentDate')}</td>
                            <td>{lotDate}</td>
                        </tr>
                        <tr>
                            <td>{t('period')}</td>
                            <td>{dataBuySuccess ? dataBuySuccess.lotId : null}</td>
                        </tr>
                        <tr>
                            <td>{t('BuyData')}</td>
                            <td>{dataBuySuccess ? dataBuySuccess.saleTime : null}</td>
                        </tr>

                    </tbody></table>
            </div>{/*card-info*/}
            <div className="card-info receipt">
                <h5 className="mb-2">{t('items')}</h5>
                <table className="table-total receipt">
                    <tbody>
                        {
                            listArray ? listArray.map((row) => (
                                <tr key={row.lottoNumber}>
                                    <td>{row.lottoNumber}</td>
                                    <td className="text-center">x {row.qty}</td>
                                    <td>{row.total}  {t('bat')}</td>
                                </tr>
                            )) : null
                        }


                    </tbody></table>
            </div>{/*card-info*/}
            {
                listArray ?
                    <div className="card-info receipt">
                        <h5>{t('PriceSummary')}</h5>
                        <table className="table-total receipt">
                            <tbody>
                                <tr>
                                    <td colSpan={2}>
                                        <hr className="dashed my-2" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t('TotalMoney')}</td>
                                    <th> {formatNumber(listArray.reduce((a, v) => a = a + parseInt(v.total), 0) + "")} {t('bat')}</th>

                                </tr>
                                <tr>
                                    <td>{t('Support')}</td>
                                    <th>0 {t('bat')}</th>
                                </tr>
                                <tr>
                                    <td>{t('UseTheDropPart')} 0%</td>
                                    <th>0 {t('bat')}</th>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <hr className="dashed my-2" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t('sumTotal')}</td>
                                    <th><h3>{formatNumber(listArray.reduce((a, v) => a = a + parseInt(v.total), 0) + "")} {t('bat')}</h3></th>
                                </tr>
                            </tbody>
                        </table>
                    </div> : null
            }



            {/* navigate('/DetailHistory') */}
            <div className="offcanvas-footer h-142">
                <div className="card-info card-fixed">
                    <a className="btn btn-lg btn-yellow w-100" onClick={() => onClickGoto('HISTORY')}>
                        {t('ViewPurchaseHistory')}
                    </a>
                    <a className="btn-link btn-lg w-100" onClick={() => onClickGoto('HOME')}>
                        <span className="fs-14">{t('BackToMainPage')}</span>
                    </a>
                </div>
            </div>
        </div>


    );
}