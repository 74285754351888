import { useMemo, useState, useEffect } from 'react';
import {
  Box, Drawer, IconButton, styled, Typography, Avatar, Stack,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Link,
  Divider
} from '@mui/material';
import {
  Close, HomeOutlined, PriceChangeOutlined, ArchiveOutlined, Verified, NewspaperOutlined,
  SettingsOutlined, HelpOutlined, ContactSupportOutlined, LogoutOutlined
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useValue } from '../../context/ContextProvider';
import img from '../../themes/image'
import { useTranslation } from 'react-i18next'
import { GetCustomerPoint } from '../../actions/ResetClient'
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));
const Sidebar = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const {
    state: { containerRef, currentUser, dataCustomerPoint, tokenInfo },
    dispatch,
  } = useValue();
  const [cusName, setcusName] = useState('');
  const [cusSurname, setcusSurname] = useState('');
  const [customerStatusType, setCustomerStatusType] = useState(null);
  useEffect(() => {

    if (currentUser) {
      setCustomerStatusType(currentUser.customerStatusType)
      if (currentUser.accInfo) {
        setcusName(currentUser.accInfo.carriedName)
        setcusSurname(currentUser.accInfo.cusSurname)
        // console.log("currentUser.accInfo.cusId:", currentUser.accInfo.cusId)
        if (!dataCustomerPoint && tokenInfo && currentUser) {
          const token = tokenInfo.access_token
          const param = currentUser.accInfo.cusId
          // console.log("1-Point")
          GetCustomerPoint(token, param, dispatch);
        }
      }

    }

  }, [currentUser, dataCustomerPoint]);



  const navigate = useNavigate();
  const [selectedLink, setSelectedLink] = useState('');
  const list = useMemo(
    () => [
      {
        title: 'Home',
        icon: <HomeOutlined />,
        link: '',
      },
      {
        title: t('BuyLabel'),
        icon: <PriceChangeOutlined />,
        link: 'BuyLoto',
      },
      // {
      //   title: t('SafeBox'),
      //   icon: <ArchiveOutlined />,
      //   link: 'SafeBox',
      // },
      // {
      //   title: t('Promotion'),
      //   icon: <Verified />,
      //   link: 'Promotion',
      // },
      // {
      //   title: t('news'),
      //   icon: <NewspaperOutlined />,
      //   link: 'news',
      // },
    ],
    []
  );
  const list2 = useMemo(
    () => [
      {
        title: t('SettingsOutlined'),
        icon: <SettingsOutlined />,
        link: 'Account',
      },
      {
        title: t('Help'),
        icon: <HelpOutlined />,
        link: 'Help',
      },
      // {
      //   title: t('contact'),
      //   icon: <ContactSupportOutlined />,
      //   link: 'Contact',
      // },
    ]
  )
  const list3 = useMemo(
    () => [
      {
        title: t('logout'),
        icon: <LogoutOutlined />,
        link: 'logout',
      }
    ]
  )


  const handleLogout = () => {
    dispatch({ type: 'UPDATE_USER', payload: null });
    dispatch({ type: 'TOKEN_INFO', payload: null });
    dispatch({ type: 'GET_CUSTOMER_INFO_BY_MSISDNNAME', payload: [] });
    dispatch({ type: 'GET_HISTORY_BUY_LOTO', payload: [] });
    dispatch({ type: 'GET_WINNERLIST', payload: [] });
    dispatch({ type: 'GET_HOTNUMBER_BY_LOTO_ID', payload: [] });
    dispatch({ type: 'GET_DASHBOARD', payload: [] });
    dispatch({ type: 'BANNER_APP', payload: [] });
    dispatch({ type: 'RESULT_SEARCH_LOTO', payload: [] })
    dispatch({ type: 'GET_HISTORY_BUY_LOTO_BY_LOT_ID', payload: [] });
    dispatch({ type: 'LIST_LOTO_BUY_SUCCESS', payload: [] });
    dispatch({ type: 'GET_NETWORK_MEMBER', payload: null });
    dispatch({ type: 'GET_CUSTOMER_POINT', payload: null });
    dispatch({type: 'GET_REPORT_OF_PARTNER_TEAM', payload: null});
    navigate('/');
  };

  const onGetEventClick = (value) => {
    if (value === 'Account') {
      dispatch({ type: 'UPDATE_SECTION', payload: 4 })
    } else {
      navigate(value)
    }

  }

  const onGetEventClickList = (value) => {
    if (value === 'BuyLoto') {
      dispatch({ type: 'UPDATE_SECTION', payload: 1 })
    } else {
      navigate(value)
    }
  }

  return (
    <Drawer
      variant="persistent"
      hideBackdrop={true}
      open={isOpen}
      PaperProps={{
        sx: {
          borderTopRightRadius: 20,
          borderBottomRightRadius: 20
        },
      }}
    >
      <DrawerHeader>
        <Typography></Typography>
        <IconButton onClick={() => setIsOpen(false)}>
          <Close />
        </IconButton>
      </DrawerHeader>
      <Box sx={{ width: 240, p: 1 }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <Stack direction='column' sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          
            <Avatar sx={{ width: 65, height: 65 }} alt="Cindy Baker" src={localStorage.getItem("profileImage") ? localStorage.getItem("profileImage") : img.ic_avatar} />
            <Typography color='error' sx={{ fontWeight: 600 }}>
              {cusName && cusSurname ? cusName + cusSurname : null}
            </Typography>
            <Link>{customerStatusType? customerStatusType : t('customer')}</Link>
            <Typography sx={{ fontSize: 12 }}>{t('accumulatedPoints')}: {dataCustomerPoint ? dataCustomerPoint : 0}</Typography>
          </Stack>
        </Box>

        <Box ref={containerRef}></Box>
      </Box>


      <List>
        {list.map((item) => (
          <ListItem key={item.title} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                px: 2.5,
              }}
              onClick={() => onGetEventClickList(item.link)}
              selected={selectedLink === item.link}
            >

              <Stack direction="row" spacing={1}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: 'center',
                  }}
                >
                  <Tooltip title={t(item.title)}>
                    {item.icon}
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={t(item.title)} />
              </Stack>

            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {list2.map((item) => (
          <ListItem key={item.title} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                px: 2.5,
              }}
              onClick={() => onGetEventClick(item.link)}
              selected={selectedLink === item.link}
            >

              <Stack direction="row" spacing={1}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: 'center',
                  }}
                >
                  <Tooltip title={t(item.title)}>
                    {item.icon}
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={t(item.title)} />
              </Stack>

            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {list3.map((item) => (
          <ListItem key={item.title} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                px: 2.5,
              }}
              onClick={() => handleLogout()}
              selected={selectedLink === item.link}
            >

              <Stack direction="row" spacing={1}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: 'center',
                  }}
                >
                  <Tooltip title={t(item.title)}>
                    {item.icon}
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={t(item.title)} />
              </Stack>

            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
