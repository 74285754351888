import React, { useState, useEffect } from "react";
import {
  Chip,
  Box,
  Paper,
  Link,
  TextField,
  styled,
  Grid,
  Typography,
  MenuItem,
  Alert,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { HeaderCompoment } from "../../compoment";
import { useValue } from "../../context/ContextProvider";
import {
  GetNetworkMember,
  GetManageLottery,
  GetCheckMemberOfPartner,
} from "../../actions/ResetClient";
import { Sync, Face, Storefront, Search } from "@mui/icons-material";
import moment from "moment";
import Colors from "../../themes/Colors";
import Image from "../../themes/image";
import { WARNING, SUCCESS } from "../../utils/Constant";
import Swal from "sweetalert2";
import ShowListMember from "./ShowListMember";
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "80%",
  maxHeight: "80%",
});

export default function SalesSummary() {
  const { t } = useTranslation();
  const {
    state: {
      currentUser,
      tokenInfo,
      dataNetworkMember,
      dataListManageLottery,
      dataMemberOfPartner,
    },
    dispatch,
  } = useValue();
  const [agentName, setAgentName] = useState(null);
  const [totalMember, setTotalMember] = useState(0);
  const [dateUpdate, setdateUpdate] = useState(null);
  const [open, setOpen] = useState(false);
  const [lotId, setlotId] = useState("");
  const [cusName, setcusName] = useState("");
  const [cusSurname, setcusSurname] = useState("");
  const toDate = moment(new Date()).format("YYYY-MM-DD");
  useEffect(() => {
    // console.log("currentUser:", currentUser);
    // console.log("dataMemberOfPartner:", dataMemberOfPartner)
    if (currentUser && tokenInfo) {
      if (currentUser.accInfo && tokenInfo.access_token) {
        const msisdn = currentUser.accInfo.msisdn;
        const lotId = currentUser.lotAvaiable
          ? currentUser.lotAvaiable[0].lotId
          :0;
        const token = tokenInfo.access_token;
        setcusName(currentUser.accInfo.carriedName);
        setcusSurname(currentUser.accInfo.cusSurname);
        if (!dataNetworkMember) {
          // console.log("dataNetworkMember:", dataNetworkMember);
          GetNetworkMember(token, msisdn, lotId, dispatch);
        }
        if (dataListManageLottery && dataListManageLottery.length === 0) {
          // console.log("2");
          GetManageLottery(token, dispatch);
        }
      }
    }
  }, [dataNetworkMember, dataListManageLottery]);

  const onlotId = (event) => {
    const value = event.target.value;
    setlotId(value);
    if (currentUser && tokenInfo && value) {
      if (currentUser.accInfo && tokenInfo.access_token) {
        const msisdn = currentUser.accInfo.msisdn;
        const lotId = value;
        const token = tokenInfo.access_token;
        GetNetworkMember(token, msisdn, lotId, dispatch);
      }
    }
  };

  // const onReload = () => {
  //   if (currentUser && tokenInfo) {
  //     if (currentUser.accInfo && tokenInfo.access_token) {
  //       // const msisdn = currentUser.accInfo.msisdn
  //       const msisdn = currentUser.accInfo.msisdn;
  //       const lotId = currentUser.lotAvaiable
  //         ? currentUser.lotAvaiable[0].lotId
  //         : "0";
  //       const token = tokenInfo.access_token;
  //       GetNetworkMember(token, msisdn, lotId, dispatch);
  //     }
  //   }
  // };

  const handleClose = () => {
    setOpen(false);
  };

  function renderMenuDasbord(listItem) {
    const dataList = [
      {
        id: 1,
        img: Image.ic_buy,
        name: "totalByThemself",
        total: listItem.totalByThemself || 0,
        colors:
          "linear-gradient(90deg, rgba(217,18,18,1) 0%, rgba(210,0,11,1) 0%, rgba(255,120,120,1) 100%)",
        boxShadow: "0 3px 5px 2px",
      },
      {
        id: 2,
        img: Image.ic_dataAnalytics,
        name: "totalRevenueBuyThemSelf",
        total: listItem.totalRevenueBuyThemSelf || 0,
        colors:
          "linear-gradient(90deg, rgba(217,18,18,1) 0%, rgba(210,0,11,1) 0%, rgba(255,120,120,1) 100%)",
        boxShadow: "0 3px 5px 2px",
      },
      {
        id: 3,
        img: Image.ic_group,
        name: "totalRevenueBuyOfB",
        total: listItem.totalRevenueBuyOfB || 0,
        colors: "linear-gradient(90deg, rgba(217,18,18,1) 0%, rgba(0,122,16,1) 0%, rgba(3,223,32,1) 100%)",
        boxShadow: "0 3px 5px 2px",
      },
      {
        id: 4,
        img: Image.ic_profits,
        name: "totalRevenueBuyOfC",
        total: listItem.totalRevenueBuyOfC || 0,
        colors:
          "linear-gradient(90deg, rgba(217,18,18,1) 0%, rgba(1,136,147,1) 0%, rgba(4,198,249,1) 100%)",
        boxShadow: "0 3px 5px 2px",
      },
      {
        id: 5,
        img: Image.ic_profits,
        name: "Sum",
        total:
          listItem.totalRevenueBuyThemSelf +
            listItem.totalRevenueBuyOfB +
            listItem.totalRevenueBuyOfC || 0,
        colors:
          "linear-gradient(90deg, rgba(217,18,18,1) 0%, rgba(6,2,138,1) 0%, rgba(85,144,250,1) 100%)",
        boxShadow: "0 3px 5px 2px",
      },
    ];

    return (
      <>
        <Box
          sx={{
            display: { xs: "grid", md: "grid", marginBottom: 10 },
            gridTemplateColumns: "repeat(2,1fr)",
            gridAutoRows: "minmax(50px, auto)",
            gap: 1,
            textAlign: "center",
            flexDirection: "column",
          }}
        >
          {dataList.slice(0, 4).map((listBox) => (
            <div
              key={listBox.id}
              style={{
                background: listBox.colors,
                border: 0,
                borderRadius: 5,
                boxShadow: listBox.boxShadow,
                color: "white",
                height: null,
                padding: "0 10px",
              }}
            >
              <Grid container>
                <Grid item xs={3} container>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Img alt="complex" src={listBox.img} />
                  </Box>
                </Grid>
                <Grid item xs={9} container>
                  <Grid item xs container direction="column">
                    <Grid item xs>
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        component="div"
                        sx={{
                          color: Colors.White,
                          fontSize: 12,
                        }}
                      >
                        {t(listBox.name)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        sx={{
                          color: Colors.White,
                          fontSize: 25,
                          fontWeight: 600,
                        }}
                      >
                        {listBox.total}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          ))}
        </Box>
        <Box
          sx={{
            display: { xs: "grid", md: "grid" },
            gridTemplateColumns: "repeat(1,1fr)",
            gridAutoRows: "minmax(50px, auto)",
            gap: 1,
            textAlign: "center",
            flexDirection: "column",
          }}
        >
          {dataList.slice(4, 5).map((listBox) => (
            <div
              key={listBox.id}
              style={{
                background: listBox.colors,
                border: 0,
                borderRadius: 5,
                boxShadow: listBox.boxShadow,
                color: "white",
                height: null,
                padding: "0 10px",
              }}
            >
              <Grid container>
                <Grid item xs={3} container>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Img alt="complex" src={listBox.img} />
                  </Box>
                </Grid>
                <Grid item xs={9} container>
                  <Grid item xs container direction="column">
                    <Grid item xs>
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        component="div"
                        sx={{
                          color: Colors.White,
                          fontSize: 12,
                        }}
                      >
                        {t(listBox.name)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        sx={{
                          color: Colors.White,
                          fontSize: 25,
                          fontWeight: 600,
                        }}
                      >
                        {listBox.total}
                      </Typography>
                      <Typography
                        sx={{
                          color: Colors.White,
                          fontSize: 12,
                          fontWeight: 600,
                        }}
                      >
                        {t("updateMember")} : {dateUpdate ? dateUpdate : toDate}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          ))}
        </Box>
      </>
    );
  }

  const infomationCus = (name, totalMember, date) => {
    if (name && totalMember) {
      setAgentName(name);
      setTotalMember(totalMember);
      setdateUpdate(moment(date).format("YYYY-MM-DD HH:mm:ss"));
    }
  };

  const oniconSearch = () => {
    setOpen(true);
  };

  const onDisplayMemer = (tyle, number) => {
    if (number > 0) {
      if (currentUser && tokenInfo) {
        if (currentUser.accInfo && tokenInfo.access_token) {
          const msisdn = currentUser.accInfo.msisdn;
          const agentInvite = currentUser.accInfo.agentInvite;
          const cusId = currentUser.accInfo.cusId;

          // const msisdn = "99588891";
          // 891 = ລະຫັດຂອງ partner
          // 32 = ແມ່ນລະລູກຄ້າ
          const token = tokenInfo.access_token;
          const pathAgent = `/admin/checkMemberOfPartner/${msisdn}`;
          const pathSeller = `/admin/checkMemberOfSeller/${agentInvite}/${cusId}`; // Agent when to show my seller
          const pathClassC = `/admin/partnerGetSeller/${msisdn}`; // This partner when to show member of Agent

          if (tyle) {
            switch (tyle) {
              case "AGENT":
                // console.log("AGENT")
                GetCheckMemberOfPartner(token, pathAgent, dispatch);
                break;
              
              case "SELLER_FOR_PARTNRT":
                GetCheckMemberOfPartner(token, pathClassC, dispatch);
                break;
              case "SELLER_OF_AGENT":
                // console.log("SELLER")
                GetCheckMemberOfPartner(token, pathSeller, dispatch);
                break;
              case "CLASS_C":
                GetCheckMemberOfPartner(token, pathClassC, dispatch);
                break;

              default:
                break;
            }
          }
        }
      } else {
        Swal.fire({
          icon: WARNING,
          title: t("alert"),
          text: t("woningCurrentUserNull"),
        });
      }
    } else {
      Swal.fire({
        icon: WARNING,
        title: t("alert"),
        text: t("cantDisplay"),
      });
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1 }}>
      <HeaderCompoment
        txtDateUpdate={dateUpdate}
        txtCusSurname={
          agentName
            ? agentName
            : cusName && cusSurname
            ? cusName + " " + cusSurname
            : ""
        }
        txttotalMember={totalMember}
        height={100}
        icon={true}
        iconRige={false}
        showHeaderReport={true}
        textHeader="SalesSummary"
        iconSearch={true}
        oniconSearch={() => oniconSearch()}
      />
      <br />
      <br />
      <br />
      <br />
      <br />

      <Box sx={{ p: 2, overflow: "scroll", height: "100%", width: "100%" }}>
        {dataNetworkMember && dataNetworkMember.typeName ? (
          dataNetworkMember.typeName === "A" ? (
            <>
              {renderMenuDasbord(dataNetworkMember)}
              {!agentName
                ? infomationCus(
                    cusName && cusSurname ? cusName + cusSurname : null,
                    dataNetworkMember.totalOfMemeber +
                      dataNetworkMember.totalOfParent,
                    dataNetworkMember.timestamp
                  )
                : null}
              <p></p>
              <Paper elevation={12} sx={{ p: 2 }}>
                <p>{t("AllReport")}</p>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("cusTypeId")}</TableCell>
                        <TableCell align="center">{t("Member")}</TableCell>
                        <TableCell align="right">{t("option")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataNetworkMember ? (
                        <>
                        <TableRow
                            key={1}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Chip
                                size="small"
                                color="primary"
                                icon={<Face />}
                                label="Partner"
                              />
                            </TableCell>
                            <TableCell align="center">
                             
                            </TableCell>
                            <TableCell align="right">
                              
                            </TableCell>
                          </TableRow>
                          <TableRow
                            key={1}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Chip
                                size="small"
                                color="colorAgent"
                                icon={<Face />}
                                label="Agent"
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Chip
                                color="colorAgent"
                                label={dataNetworkMember.totalOfMemeber}
                                size="small"
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Link
                                component="button"
                                variant="body2"
                                onClick={() =>
                                  onDisplayMemer(
                                    "AGENT",
                                    dataNetworkMember.totalOfMemeber
                                  )
                                }
                              >
                                <Chip
                                  color="colorAgent"
                                  label={t("display")}
                                  size="small"
                                />
                              </Link>
                            </TableCell>
                          </TableRow>

                          <TableRow
                            key={2}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Chip
                                size="small"
                                color="colorSeller"
                                icon={<Storefront />}
                                label="Seller"
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Chip
                                color="colorSeller"
                                label={dataNetworkMember.totalOfParent}
                                size="small"
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Link
                                component="button"
                                variant="body2"
                                onClick={() =>
                                  onDisplayMemer(
                                    "SELLER_FOR_PARTNRT",
                                    dataNetworkMember.totalOfParent
                                  )
                                }
                              >
                                <Chip
                                  color="colorSeller"
                                  label={t("display")}
                                  size="small"
                                />
                              </Link>
                            </TableCell>
                          </TableRow>
                        </>
                      ) : null}
                    </TableBody>
                  </Table>
                </TableContainer>

                <p></p>

                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("cusTypeId")}</TableCell>
                        <TableCell align="right">{t("NumberOfBill")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataNetworkMember ? (
                        <>
                          {/* Start patner */}
                          <TableRow
                            key={3}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Chip
                                size="small"
                                color="primary"
                                icon={<Face />}
                                label="Partner"
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Chip
                                color="primary"
                                label={dataNetworkMember.totalByThemself}
                                size="small"
                              />
                            </TableCell>
                          </TableRow>
                          {/* end patner */}
                          <TableRow
                            key={3}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Chip
                                size="small"
                                color="colorAgent"
                                icon={<Face />}
                                label="Agent"
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Chip
                                color="colorAgent"
                                label={dataNetworkMember.totalBillOfB}
                                size="small"
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow
                            key={4}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Chip
                                size="small"
                                color="colorSeller"
                                icon={<Storefront />}
                                label="Seller"
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Chip
                                color="colorSeller"
                                label={dataNetworkMember.totalBillOfC}
                                size="small"
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      ) : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              <p></p>
              {/* {dataNetworkMember || dataNetworkMember.billDetailOfB.length > 0 || dataNetworkMember.billDetailOfC.length > 0 ? <p>{t('Details')}</p> : null} */}
              {dataNetworkMember &&
              dataNetworkMember.billDetailOfB.length > 0 ? (
                <Paper elevation={12} sx={{ p: 2 }}>
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>{t("AgentDetails")}</TableCell>
                          <TableCell align="right">
                            {t("NumberOfBill")}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataNetworkMember &&
                        dataNetworkMember.billDetailOfB.length > 0
                          ? dataNetworkMember.billDetailOfB.map((item) => (
                              <TableRow
                                key={3}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  <Chip
                                    size="small"
                                    color="colorAgent"
                                    icon={<Face />}
                                    label={item.cusIdName}
                                  />
                                </TableCell>
                                <TableCell align="right">
                                  <Chip
                                    color="colorAgent"
                                    label={item.totalBill}
                                    size="small"
                                  />
                                </TableCell>
                              </TableRow>
                            ))
                          : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              ) : null}
              <p></p>
              {dataNetworkMember.billDetailOfC.length > 0 ? (
                <Paper elevation={12} sx={{ p: 2 }}>
                  {" "}
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>{t("AgentDetails")}</TableCell>
                          <TableCell align="right">
                            {t("NumberOfBill")}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataNetworkMember &&
                        dataNetworkMember.billDetailOfC.length > 0
                          ? dataNetworkMember.billDetailOfC.map((item) => (
                              <TableRow
                                key={3}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  <Chip
                                    size="small"
                                    color="colorSeller"
                                    icon={<Face />}
                                    label={item.cusIdName}
                                  />
                                </TableCell>
                                <TableCell align="right">
                                  <Chip
                                    color="colorSeller"
                                    label={item.totalBill}
                                    size="small"
                                  />
                                </TableCell>
                              </TableRow>
                            ))
                          : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              ) : null}
            </>
          ) : dataNetworkMember.typeName === "B" ? (
            <>
              {renderMenuDasbord(dataNetworkMember)}
              {!agentName
                ? infomationCus(
                    cusName && cusSurname ? cusName + cusSurname : null,
                    dataNetworkMember.totalOfParent,
                    dataNetworkMember.timestamp
                  )
                : null}
              <p></p>
              <p>{t("AllReport")}</p>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Seller</TableCell>
                      <TableCell align="center">{t("NumberOfBill")}</TableCell>
                      <TableCell align="right">{t("option")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataNetworkMember ? (
                      <>
                        <TableRow
                          key={5}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <Chip
                              size="small"
                              color="primary"
                              icon={<Face />}
                              label={dataNetworkMember.totalOfParent}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <Chip
                              color="primary"
                              label={dataNetworkMember.totalBillOfC}
                              size="small"
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Link
                              component="button"
                              variant="body2"
                              onClick={() =>
                                onDisplayMemer(
                                  "SELLER_OF_AGENT",
                                  dataNetworkMember.totalBillOfC
                                )
                              }
                            >
                              <Chip
                                color="primary"
                                label={t("display")}
                                size="small"
                              />
                            </Link>
                          </TableCell>
                        </TableRow>
                      </>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>

              <p></p>
              {(dataNetworkMember &&
                dataNetworkMember.billDetailOfB.length > 0) ||
              dataNetworkMember.billDetailOfC.length > 0 ? (
                <p>{t("Details")}</p>
              ) : null}
              <p></p>
              {dataNetworkMember.billDetailOfC.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("AgentDetails")}</TableCell>
                        <TableCell align="right">{t("NumberOfBill")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataNetworkMember &&
                      dataNetworkMember.billDetailOfC.length > 0
                        ? dataNetworkMember.billDetailOfC.map((item) => (
                            <TableRow
                              key={3}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                <Chip
                                  size="small"
                                  color="colorSeller"
                                  icon={<Face />}
                                  label={item.cusIdName}
                                />
                              </TableCell>
                              <TableCell align="right">
                                <Chip
                                  color="colorSeller"
                                  label={item.totalBill}
                                  size="small"
                                />
                              </TableCell>
                            </TableRow>
                          ))
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : null}
            </>
          ) : dataNetworkMember.typeName === "C" ? (
            <>
              {renderMenuDasbord(dataNetworkMember)}
              {!agentName
                ? infomationCus(
                    cusName && cusSurname ? cusName + cusSurname : null,
                    0,
                    dataNetworkMember.timestamp
                  )
                : null}
              <p></p>
              <p>{t("AllReport")}</p>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Seller</TableCell>
                      <TableCell align="center">{t("NumberOfBill")}</TableCell>
                      <TableCell align="right">{t("option")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataNetworkMember ? (
                      <>
                        <TableRow
                          key={6}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <Chip
                              size="small"
                              color="primary"
                              icon={<Face />}
                              label={t("BillTotal")}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Chip
                              color="primary"
                              label={dataNetworkMember.totalBillOfC}
                              size="small"
                            />
                          </TableCell>

                          <TableCell align="right">
                            <Link
                              component="button"
                              variant="body2"
                              onClick={() =>
                                onDisplayMemer(
                                  "CLASS_C",
                                  dataNetworkMember.totalBillOfC
                                )
                              }
                            >
                              <Chip
                                color="primary"
                                label={t("display")}
                                size="small"
                              />
                            </Link>
                          </TableCell>
                        </TableRow>
                      </>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>

              <p></p>
              {dataNetworkMember &&
              dataNetworkMember.billDetailOfB.length > 0 &&
              dataNetworkMember.billDetailOfC.length > 0 ? (
                <p>{t("Details")}</p>
              ) : null}
              <p></p>
              {dataNetworkMember.billDetailOfC.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("AgentDetails")}</TableCell>
                        <TableCell align="right">{t("NumberOfBill")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataNetworkMember &&
                      dataNetworkMember.billDetailOfC.length > 0
                        ? dataNetworkMember.billDetailOfC.map((item) => (
                            <TableRow
                              key={3}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                <Chip
                                  size="small"
                                  color="colorSeller"
                                  icon={<Face />}
                                  label={item.cusIdName}
                                />
                              </TableCell>
                              <TableCell align="right">
                                <Chip
                                  color="colorSeller"
                                  label={item.totalBill}
                                  size="small"
                                />
                              </TableCell>
                            </TableRow>
                          ))
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : null}
            </>
          ) : (
            <Grid container right spacing={2}>
              <Grid item xs={4}>
                <p>{t("lotId")}</p>
              </Grid>
              <Grid container xs={8}>
                <TextField
                  select
                  label={t("lotId")}
                  value={`${lotId}`}
                  size="small"
                  fullWidth
                  requiredStatus={false}
                  margin="normal"
                  onChange={onlotId}
                >
                  {dataListManageLottery.map((item) => (
                    <MenuItem key={item.manageId} value={item.lotId}>
                      {t("period") +
                        ":" +
                        item.lotId +
                        " - " +
                        t("installmentDate") +
                        ":" +
                        item.fromLotDate}
                    </MenuItem>
                  ))}
                </TextField>
                <Alert severity="warning">{t("Nodata")}</Alert>
              </Grid>
            </Grid>
          )
        ) : null}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t("searchLot")}</DialogTitle>
          <DialogContent>
            <TextField
              select
              label={t("lotId")}
              value={`${lotId}`}
              size="small"
              fullWidth
              requiredStatus={false}
              margin="normal"
              onChange={onlotId}
            >
              {dataListManageLottery.map((item) => (
                <MenuItem key={item.manageId} value={item.lotId}>
                  {t("period") +
                    ":" +
                    item.lotId +
                    " - " +
                    t("installmentDate") +
                    ":" +
                    item.fromLotDate}
                </MenuItem>
              ))}
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t("Cancel")}</Button>
          </DialogActions>
        </Dialog>
        <ShowListMember />
      </Box>
      <br />
      <br />
      <br />
    </Box>
  );
}
