import { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { HeaderCompoment } from "../../compoment";
import { useValue } from "../../context/ContextProvider";
import { DrawerBottom } from "../../screen";
import Button from "@mui/material/Button";
import { useTranslation } from 'react-i18next'
const Invite = () => {
  const {
    state: { currentUser },
    dispatch,
  } = useValue();
  const { t } = useTranslation();
  const [agentState, setagentState] = useState(null);
  const [agentId, setagentId] = useState(null);
  const [cusId, setcusId] = useState(null);
  useEffect(() => {
    if (currentUser) {
      setagentId(currentUser.agentId);
      setagentState(currentUser.agentState);
      if (currentUser.accInfo) {
        setcusId(currentUser.accInfo.cusId);
        dispatch({ type: "OPEN_CART_DIALOG", payload: null });
      }
    }
  }, []);
  const onClick = () => {
    if (currentUser) {
      setagentId(currentUser.agentId);
      setagentState(currentUser.agentState);
      if (currentUser.accInfo) {
        setcusId(currentUser.accInfo.cusId);
        dispatch({ type: "OPEN_CART_DIALOG", payload: null });
      }
    }
  };
  return (
    <Box component="main" sx={{ flexGrow: 1 }}>
      <HeaderCompoment textHeader="inviteFriend" />
      <br />
      <br />
      <br />
      <br />
      <Box component="main" sx={{ flexGrow: 1, p: 2, textAlign:'center', justifyItems:'center' }}>
        <Button variant="contained" disableElevation onClick={() => onClick()}>
          {t("inviteFriend")}
        </Button>
      </Box>
      <DrawerBottom
        tyleBar="INVITE"
        agentId={agentId}
        agentState={agentState}
        cusId={cusId}
      />
    </Box>
  );
};
export default Invite;
