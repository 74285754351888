import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Stack, Alert, styled } from '@mui/material';
import { HeaderCompoment } from '../../compoment'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { GetWinnerLotteryByCusId } from '../../actions/ResetClient'
import { formatNumber } from '../../utils/Formater'
import { useValue } from '../../context/ContextProvider'
import image from '../../themes/image'
import { green, red, orange, grey } from '@mui/material/colors';
import { DrawerBottom } from '../../screen'
import moment from 'moment';


const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '50%',
  maxHeight: '50%'
});

const HistoryWinner = () => {

  const { t } = useTranslation();
  const [dataGroupHis, setDataGroupHis] = useState([])

  const navigate = useNavigate();
  const {
    state: { tokenInfo, dataWinner, currentUser },
    dispatch,
  } = useValue();

  useEffect(() => {
    if (currentUser && tokenInfo) {
      if (dataWinner.length === 0) {
        const token = tokenInfo.access_token
        const param = currentUser.accInfo.cusId
        // const param = 9
        GetWinnerLotteryByCusId(token, param, dispatch);
      }
      if (dataWinner.length > 0) {

        groupData(dataWinner)
        // console.log('dataWinner0000000000:', dataWinner);

      }
    } else {
      return dispatch({
        type: 'UPDATE_ALERT',
        payload: {
          open: true,
          severity: 'warning',
          message: 'Current user or token Info null pls check',
        },
      });
    }

  }, [dataWinner]);

  const groupData = (data) => {
    const listHis = data.reduce((hisSoFar, { lotId, billId, saleDate, cusId, cusName, cusSurname, gender, msisdn, lottoNumber, amount, discount, totalAmount, payStatus, timePay, luckyType, prizeValue, prizeStatus }) => {
      if (!hisSoFar[billId + '|' + saleDate + '|' + cusName + '|' + data.reduce((a, v) => a = a + parseInt(v.prizeValue), 0) + '|' + payStatus + '|' + prizeStatus + '|' + luckyType +'|'+ lotId]) 
      hisSoFar[billId + '|' + saleDate + '|' + cusName + '|' + data.reduce((a, v) => a = a + parseInt(v.prizeValue), 0) + '|' + payStatus + '|' + prizeStatus + '|' + luckyType+'|'+ lotId] = [];
      hisSoFar[billId + '|' + saleDate + '|' + cusName + '|' + data.reduce((a, v) => a = a + parseInt(v.prizeValue), 0) + '|' + payStatus + '|' + prizeStatus + '|' + luckyType+'|'+ lotId].push({
        "lotId": lotId,
        "billId": billId,
        "saleDate": saleDate,
        "cusId": cusId,
        "cusName": cusName,
        "cusSurname": cusSurname,
        "gender": gender,
        "msisdn": msisdn,
        "lottoNumber": lottoNumber,
        "amount": amount,
        "discount": discount,
        "totalAmount": totalAmount,
        "payStatus": payStatus,
        "timePay": timePay,
        "luckyType": luckyType,
        "prizeValue": prizeValue,
        "prizeStatus": prizeStatus
      });
      return hisSoFar;
    }, {});

    const groupArrays = Object.keys(listHis).map((billId) => {
      return {
        bull: billId,
        data: JSON.stringify(listHis[billId])
      };
    });
    setDataGroupHis(groupArrays)
    // console.log('------dat group---:', groupArrays)

  }



  const valuesData = (startDate, EndDate) => {

    //console.log('startDate:', startDate)
    //console.log('EndDate:', EndDate)
    let time = moment(new Date()).format("HHmmss")
    let s = moment(startDate).format("YYYYMMDD") + time
    let e = moment(EndDate).format("YYYYMMDD") + time
    let now = moment(new Date()).format("YYYYMMDDHHmmss")
    //console.log('--------s:', s)
    //console.log('--------e:', e)
    if (s <= e) {
      if (e <= now) {

        // const token = tokenInfo.access_token
        // const cusId = currentUser.accInfo.cusId
        // const firstResult = 0;
        // const maxResult = 50
        // let fromDate = startDate
        // let toDate = EndDate
        // let dataReq = reqBodyGetHistoryBuyLoto(cusId, fromDate, toDate, firstResult, maxResult)
        // reqGetHistoryBuyLoto(token, dataReq, dispatch);


      } else {
        return dispatch({
          type: 'UPDATE_ALERT',
          payload: {
            open: true,
            severity: 'warning',
            message: t('fromEndDateNow'),
          },
        });
      }
    } else {
      return dispatch({
        type: 'UPDATE_ALERT',
        payload: {
          open: true,
          severity: 'warning',
          message: t('formatData'),
        },
      });
    }

  }







  function LoopInfoHistory(item) {
    return Object.entries(item).map(([key, value]) => (
      <a key={key} onClick={() => onNavigateDetailHistoryWinner(value)}>
        <Paper elevation={12} sx={{ padding: 2, marginBottom: 1.3 }}>
          <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: 12, alignItems: 'flex-start' }}>
              {t('ReferenceNumber') + ': ' + value.bull.split('|')[0]}
            </Typography>
            <Typography sx={{ fontSize: 12, color: value.bull.split('|')[4] === '1' ? green[500] : value.bull.split('|')[4] === '0' ? orange[500] : value.bull.split('|')[4] === '-1' ? red[500] : grey[500], alignItems: 'flex-end' }}>
              {value.bull.split('|')[4] === '1' ? t('Paid') : value.bull.split('|')[4] === '0' ? t('NotPaid') : value.bull.split('|')[4] === '-1' ? t('cancel') : null}
              {/* //{t('successful')} */}
            </Typography>
          </Stack>
          <hr style={{ border: '0.7px dashed #DADADA', opacity: 0.5 }} />

          <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: 12 }}>
              {t('winnerType')}:
              {
                value.bull.split('|')[6] === '1st_prize' ? t('1stPrize')
                  : value.bull.split('|')[6] === '2nd_prize' ? t('2ndPrize')
                    : value.bull.split('|')[6] === '3rd_prize' ? t('3rdPrize')
                      : value.bull.split('|')[6] === '4th_prize' ? t('4thPrize')
                        : value.bull.split('|')[6] === '5th_prize' ? t('5thPrize')
                          : value.bull.split('|')[6] === 'nearly_prize' ? t('nearly_prize')
                            : value.bull.split('|')[6] === 'front_3_digit' ? t('3FrontPrizes')
                              : value.bull.split('|')[6] === 'back_3_digit' ? t('PrizeAfter3Animals')
                                : value.bull.split('|')[6] === 'back_2_digit' ? t('PrizeAfter2Animals') : null

              }
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: 12 }}>
              {value.bull.split('|')[2]}
              {/* {t('LastNumberAward')} (208335) */}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: 12 }}>
              {t('period')}
            </Typography>
            <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
              {value.bull.split('|')[7]}
              {/* {formatNumber(value.bull.split('|')[7])} */}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: 12, color: '#bfbfbf' }}>
              {value.bull.split('|')[1]}
            </Typography>
          </Stack>
        </Paper>
      </a>
    ))
  }


  const onNavigateDetailHistoryWinner = (value) => {
    navigate('/DetailHistoryWinner')
    dispatch({ type: 'DETAIL_LIST_WINNER', payload: value });

  }

  const onDatePicker = () => {
    dispatch({ type: 'OPEN_CART_DIALOG', payload: null });
}

  return (
    <Box component="main" sx={{ flexGrow: 1 }}>
      <HeaderCompoment icon={true} icondat={true} textHeader='WinningHistory' onDatePicker={() => onDatePicker()} />

      <br />
      <br />
      <br />
      <br />

      <Box sx={{ m: 2 }}>
        {
          dataGroupHis.length > 0 ? LoopInfoHistory(dataGroupHis) :
            <Box sx={{ m: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <Img src={image.iconNoData} alt='IconNodata' />
              <Alert severity="warning">{t('Nodata')}</Alert>
            </Box>
        }

      </Box>
      <DrawerBottom tyleBar='DATA_HISTOLY' valuesData={(startDate, EndDate) => valuesData(startDate, EndDate)} />

    </Box>

  )
}
export default HistoryWinner
