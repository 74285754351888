import React from 'react'
import { Route, Routes } from 'react-router-dom';
import {
    MainScreen, HistoryWinner, NotFound, DetailHistory, CongratLotto, HistoryBuy, Point,
    GiveMoney, BankAccount, Help, Consection, Policy, Cart, OrderDetail,
    Profile, About, RefundPolicy, GeneralQuestions, BuyLoto, ChangePassword,
    UpdateCodeAgent, Contact, Account, DetailHistoryWinner, ComingSoon, MylotoHistory, HistoryByLotId,
    BuyCongestion, PaymentCompleted, PendingPayment,PaymentSuccess, RegisterAccount, SalesSummary, ReportOfTeam,
    Invite
} from '../screen'
import { Loading, Notification } from '../compoment'

export default function router() {
    const list = [
        {
            title: 'DashboardScreen',
            link: '',
            component: <MainScreen />,
        },
        {
            title: 'History',
            link: 'HistoryWinner',
            component: <HistoryWinner />,
        },
        {
            title: 'DetailHistory',
            link: 'DetailHistory',
            component: <DetailHistory />,
        },
        {
            title: 'CongratLotto',
            link: 'CongratLotto',
            component: <CongratLotto />,
        },
        {
            title: 'HistoryBuy',
            link: 'HistoryBuy',
            component: <HistoryBuy />,
        },
        {
            title: 'Point',
            link: 'Point',
            component: <Point />,
        },
        {
            title: 'GiveMoney',
            link: 'GiveMoney',
            component: <GiveMoney />,
        },
        {
            title: 'BankAccount',
            link: 'BankAccount',
            component: <BankAccount />,
        },
        {
            title: 'Help',
            link: 'Help',
            component: <Help />,
        },
        {
            title: 'Consection',
            link: 'Consection',
            component: <Consection />,
        },

        {
            title: 'Policy',
            link: 'Policy',
            component: <Policy />,
        },
        {
            title: 'Cart',
            link: 'Cart',
            component: <Cart />,
        },
        {
            title: 'OrderDetail',
            link: 'OrderDetail',
            component: <OrderDetail />,
        },
        {
            title: 'Profile',
            link: 'Profile',
            component: <Profile />,
        },
        {
            title: 'About',
            link: 'About',
            component: <About />,
        },

        {
            title: 'RefundPolicy',
            link: 'RefundPolicy',
            component: <RefundPolicy />,
        },
        {
            title: 'GeneralQuestions',
            link: 'GeneralQuestions',
            component: <GeneralQuestions />,
        },

        {
            title: 'BuyLoto',
            link: 'BuyLoto',
            component: <BuyLoto />,
        },

        {
            title: 'ChangePassword',
            link: 'ChangePassword',
            component: <ChangePassword />,
        },
        {
            title: 'UpdateCodeAgent',
            link: 'UpdateCodeAgent',
            component: <UpdateCodeAgent />,
        },
        {
            title: 'Contact',
            link: 'Contact',
            component: <Contact />,
        },
        {
            title: 'Account',
            link: 'Account',
            component: <Account />,
        },
        {
            title: 'DetailHistoryWinner',
            link: 'DetailHistoryWinner',
            component: <DetailHistoryWinner />,
        },
        {
            title: 'MylotoHistory',
            link: 'MylotoHistory',
            component: <MylotoHistory />,
        },
        {
            title: 'HistoryByLotId',
            link: 'HistoryByLotId',
            component: <HistoryByLotId />,
        },
        {
            title: 'ComingSoon',
            link: 'ComingSoon',
            component: <ComingSoon />,
        },
        {
            title: 'PaymentCompleted',
            link: 'PaymentCompleted',
            component: <PaymentCompleted />,
        },
        {
            title: 'PendingPayment',
            link: 'PendingPayment',
            component: <PendingPayment />,
        },
        {
            title: 'SalesSummary',
            link: 'SalesSummary',
            component: <SalesSummary />,
        },
        {
            title: 'BuyCongestion',
            link: 'BuyCongestion',
            component: <BuyCongestion />,
        },
        {
            title: 'PaymentSuccess',
            link: 'PaymentSuccess',
            component: <PaymentSuccess />,
        },
        {
            title: 'RegisterAccount',
            link: 'RegisterAccount',
            component: <RegisterAccount />,
        },
   
        {
            title: 'ReportOfTeam',
            link: 'ReportOfTeam',
            component: <ReportOfTeam />,
        },
        {
            title: 'NotFound',
            link: '/*',
            component: <NotFound />,
        },
        {
            title: 'Invite',
            link: 'Invite',
            component: <Invite />,
        },
    ]
    return (
        <>
            <Loading />
            <Notification />
            <Routes basename=''>
                {list.map((item) => (
                    <Route key={item.title} path={item.link} element={item.component} />
                ))}
            </Routes>
        </>
    )
}
