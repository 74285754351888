import img from '../../themes/image'
export const iconBank = [
    {
        id: '1',
        name: 'BBL',
        img: img.thaiBank01BBL,
        fullName: 'ธนาคารกรุงเทพ'
    },
    {
        id: '2',
        name: 'UOBT',
        img: img.thaiBank02UOBT,
        fullName: 'ธนาคารยูโอบี'
    },
    {
        id: '3',
        name: 'TTB',
        img: img.thaiBank03TTB,
        fullName: 'ธนาคารทหารไทยธนชาต'
    },
    {
        id: '4',
        name: 'BAY',
        img: img.thaiBank04BAY,
        fullName: 'ธนาคารกรุงศรีอยุธยา'
    },
    {
        id: '5',
        name: 'KBANK',
        img: img.thaiBank05KBANK,
        fullName: 'ธนาคารกสิกรไทย'
    },
    {
        id: '6',
        name: 'KTB',
        img: img.thaiBank06KTB,
        fullName: 'ธนาคารกรุงไทย'
    },
    {
        id: '7',
        name: 'SCB',
        img: img.thaiBank07SCB,
        fullName: 'ธนาคารไทยพาณิชย์'
    }
    // {
    //     id: '1',
    //     name: 'BCEL',
    //     img: img.logo_bcel,
    //     fullName: 'ທະນາຄານ ການຄ້າຕ່າງປະເທດລາວ ມະຫາຊົນ'
    // },
    // {
    //     id: '2',
    //     name: 'JDB',
    //     img: img.logo_jdbbank,
    //     fullName: 'ທະນາຄານ ຮ່ວມພັດທະນາ'
    // },
    // {
    //     id: '3',
    //     name: 'LDB',
    //     img: img.logo_ldbbank,
    //     fullName: 'ທະນາຄານ ພັດທະນາລາວ'
    // },
    // {
    //     id: '4',
    //     name: 'LVB',
    //     img: img.logo_laovietbank,
    //     fullName: 'ທະນາຄານ ລາວຫວຽດ'
    // },
    // {
    //     id: '6',
    //     name: 'MJBL',
    //     img: img.logo_maruhanbank,
    //     fullName: 'ທະນາຄານ ມາຣູຮານ'
    // }
];




