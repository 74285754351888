import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { GetAppContentByType } from '../../actions/ResetClient'
import { useValue } from '../../context/ContextProvider'

const Messages = () => {
  const { t, i18n } = useTranslation();

  const {
    state: { tokenInfo, getAppContentByType },
    dispatch,
  } = useValue();

  useEffect(() => {
    //console.log('About------------:', getAppContentByType)
    if (tokenInfo) {
      if (getAppContentByType.length === 0) {
        const param = '6'
        const token = tokenInfo.access_token
        //console.log('param:', param)
        //console.log('token:', token)
        const displayAlert = true
        GetAppContentByType(token, param, displayAlert, dispatch);
      }

    }
  },[getAppContentByType]);

  // t('txtAnnountced')
  return (
    <div className="section_1 section-inform wow fadeIn" style={{ visibility: 'visible', animationName: 'fadeIn' }}>
      <h3>{t('announced')}</h3>
      <div className="inform-box marquee">
        {

          // getAppContentByType.length > 0 ? <marquee direction="left">dddd</marquee> : <marquee direction="left">wwwww</marquee>

          getAppContentByType
            ? getAppContentByType.appStatus === 1
              ? i18n.language === 'en'
                ? <marquee direction="left">{getAppContentByType.appContentEn + getAppContentByType.appDetailEn}</marquee> : <marquee direction="left">{getAppContentByType.appContentLa + getAppContentByType.appDetailLa}</marquee>
              : <marquee direction="left">{t('txtAnnountced')}</marquee>
            : <marquee direction="left">{t('txtAnnountced')}</marquee>
        }
      </div>
    </div>
  )
}
export default Messages
