import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { useValue } from '../../context/ContextProvider'
import { GetHotNumberByLotId } from '../../actions/ResetClient'
import {
    Alert, AlertTitle
} from '@mui/material';
const NumberLucky = () => {
    const { t } = useTranslation();
    const {
        state: { tokenInfo, dataHotNumberByLotId, currentUser },
        dispatch,
    } = useValue();
    useEffect(() => {
        //console.log('dataHotNumberByLotId:', dataHotNumberByLotId)
        //console.log('currentUser.lotAvaiable:', currentUser.lotAvaiable)
        if (dataHotNumberByLotId.length === 0) {
            if (tokenInfo && currentUser) {
                if (currentUser.lotAvaiable) {
                    const token = tokenInfo.access_token
                    const param = currentUser.lotAvaiable[0].lotId
                    GetHotNumberByLotId(token, param, dispatch)
                }
            }
        }

    }, [dataHotNumberByLotId]);

    return (
        <>
            <div className="card-info flex-column text-center mt-2">
                <h1 className="red">{t('luckyNamber')}</h1>
                <div className="lotto-number highlight">
                    {
                        currentUser ? currentUser.lotAvaiable ? currentUser.lotAvaiable.length > 0 ? dataHotNumberByLotId ? dataHotNumberByLotId.length > 0 ?
                            dataHotNumberByLotId.map((i) => (
                                i.status === 1 ?
                                    <input key={i.hotId} className="form-control" defaultValue={i.lottoNumber} />
                                    : null
                            )) : <Alert severity="error">
                                <AlertTitle>{t('alert')}</AlertTitle>
                                {t('alrtLuckyNumber', { lotId: currentUser.lotAvaiable ? currentUser.lotAvaiable.length > 0 ? currentUser.lotAvaiable[0].lotId : 0 : 0 })}
                            </Alert> : null : null : null : null
                    }
                </div>
                {
                    currentUser ? currentUser.lotAvaiable ?  currentUser.lotAvaiable.length > 0 ? null :
                        <Alert severity="error">
                            <AlertTitle>{t('alert')}</AlertTitle>
                            {t('alrtLuckyNumber',{lotId: null })}
                        </Alert> : null : null
                }
            </div>
            <br />
        </>
    )
}
export default NumberLucky
