import React, { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import image from '../../themes/image'
import { Alert, Box, List, ListItem, Divider, ListItemText, ListItemAvatar, Avatar, Paper, styled } from '@mui/material';

import { TransitionProps } from '@mui/material/transitions';
import { useValue } from '../../context/ContextProvider';
import { useTranslation } from 'react-i18next'
// import { useNavigate } from 'react-router-dom';
import { Call, Man, Woman } from '@mui/icons-material';
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '50%',
    maxHeight: '50%'
});
export default function PaymentCompleted() {
    const { t } = useTranslation();
    const {
        state: { openDialogPayment, dataMemberOfPartner },
        dispatch,
    } = useValue();
    const [data, setData] = useState([]);

    const handleClose = () => {
        dispatch({ type: 'CLOSE_PAYMENT' });
        dispatch({ type: 'GET_MEMBEROFPARTNER', payload: [] });
    };
    useEffect(() => {

        // console.log("form dataMemberOfPartner", dataMemberOfPartner)
        if (dataMemberOfPartner && dataMemberOfPartner.length > 0) {
            setData(dataMemberOfPartner)
        }

    }, [dataMemberOfPartner])

    function stringToColor(string: string) {
        let hash = 0;
        let i;
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        return color;
    }

    function stringAvatar(name: string) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.substring(0, 1)}`,
        };
    }


    return (
        <div>
            <Dialog
                fullScreen
                open={openDialogPayment}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {t("detailsMember")}
                        </Typography>
                    </Toolbar>
                </AppBar>


                <Paper elevation={12} sx={{ m: 1, p: 1 }}>
                    {
                        data && data.length > 0 ?
                            data.map((row) => (
                                <List key={row.cusId}>
                                    <ListItem
                                        alignItems="flex-start"
                                        secondaryAction={
                                            <>
                                                {row.gender ? row.gender === 'M' ?
                                                    <IconButton>
                                                        <Man />
                                                    </IconButton> :
                                                    <IconButton>
                                                        <Woman />
                                                    </IconButton> : null}
                                               
                                                <IconButton href={'tel:' + row.msisdn} aria-label="Call">
                                                    <Call />
                                                </IconButton>

                                            </>
                                        }>
                                        <ListItemAvatar>
                                            <Avatar {...stringAvatar(row.cusName)} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={row.cusName + ' ' + row.cusSurname}
                                            secondary={
                                                <React.Fragment>
                                                    {
                                                        row.email ?
                                                            <Typography
                                                                sx={{ display: 'inline' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                {row.email}
                                                            </Typography> : null
                                                    }

                                                    {
                                                        row.msisdn ? <Typography>
                                                            {row.msisdn}
                                                        </Typography> : null
                                                    }

                                                    {
                                                        row.inviteStatus ?
                                                            <Typography>
                                                                {row.inviteStatus === 1 ? "Agent" : row.inviteStatus === 2 ? "Seller" : null}
                                                            </Typography> : null
                                                    }

                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </List>
                            )) : <Box sx={{ m: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <Img src={image.iconNoData} alt='IconNodata' />
                                <Alert severity="warning">{t('Nodata')}</Alert>
                            </Box>
                    }

                </Paper>



            </Dialog>
        </div>
    );
}