import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import reducer from './reducer';

const initialState = {
  currentUser: null,
  tokenInfo: null,
  openLogin: false,
  loading: false,
  openAddEmp: false,
  openUpdateEmp: false,
  openAlertDelete: false,
  openPayments: false,
  openDialog: false,
  alert: { open: false, severity: 'info', message: '' },
  employee: [],
  dep: [],
  dataGetInfo: [],
  section: 0,
  openPopupOTP: false,
  resultSearchLoto: [],
  addCart: null,
  openDialogCart: false,
  getAppContentByType: [],
  getAppImageByCategory: [],
  openDialogPayment: false,
  openBankList: false,
  dataAddCart: [],
  getHistoryBuyLoto: [],
  detailInfoBuyLoto: null,
  questionnaireItem: null,
  getContact: null,
  dataHotNumberByLotId: [],
  dataCustomerQuestionByCusId: null,
  dataWinner: [],
  detailListWinner: [],
  dataCustomerInfoByMsisdnName: [],
  dataBuySuccess: [],
  dataGetDashboard: [],
  dataRemember: [],
  getHistoryBuyLotoByLotId:[],
  checkBillStatusById:[],
  dataNetworkMember:null,
  dataListManageLottery:[],
  dataCustomerPoint: null,
  dataMemberOfPartner:[],
  dataPartnerGetReportOfPartnerTeam: null,
  dataReth:[]
};

const Context = createContext(initialState);

export const useValue = () => {
  return useContext(Context);
};

const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const mapRef = useRef();
  const containerRef = useRef();
  useEffect(() => {

    const currentUser = localStorage.getItem('currentUser') === 'null' ? null : JSON.parse(localStorage.getItem('currentUser'));
    const tokenInfo = JSON.parse(localStorage.getItem('tokenInfo'));

    const StorImgBanner = JSON.parse(localStorage.getItem('StorImgBanner'));
    if (StorImgBanner) {
      dispatch({ type: 'BANNER_APP', payload: StorImgBanner });
    }
    
    const StorDashboard = JSON.parse(localStorage.getItem('StorDashboard'));
    if (StorDashboard) {
      dispatch({ type: 'GET_DASHBOARD', payload: StorDashboard });
    }

    
    const infoRemember = JSON.parse(localStorage.getItem('infoRemember'));
    if (infoRemember) {
      dispatch({ type: 'INFO_REMEMBER', payload: infoRemember });
    }


    if (currentUser && tokenInfo) {
      dispatch({ type: 'UPDATE_USER', payload: currentUser });
      dispatch({ type: 'TOKEN_INFO', payload: tokenInfo });
    }
  }, []);
  return (
    <Context.Provider value={{ state, dispatch, mapRef, containerRef }}>
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;
