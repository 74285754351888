const images = {
  ic_WarningAmber: require("../image/ic_WarningAmber.png"),
  ic_Safety: require("../image/ic_Safety.png"),
  addCart: require("../image/addCart.png"),
  moneyLoss: require("../image/moneyLoss2.png"),
  moneyGrowth: require("../image/money-growth.png"),
  UserAdd: require("../image/UserAdd.png"),
  warning: require("../image/warning.png"),
  ic_room: require("../image/ic_room.png"),
  ic_woning: require("../image/ic_woning.png"),
  productView: require("../image/product_view.png"),
  img_room: require("../image/img_room.jpeg"),
  ScreenHome: require("../image/ScreenHome.jpeg"),
  ic_loto: require("../image/ic_loto.png"),
  ic_member: require("../image/ic_member.png"),
  ic_help: require("../image/ic_help.png"),
  ic_promotion: require("../image/ic_promotion.png"),
  ic_avatar: require("../image/ic_avatar.png"),
  bgHeader: require("../image/bgHeader.jpeg"),
  bannerDiscount: require("../image/bannerDiscount.png"),
  ic_login: require("../image/ic_login.png"),
  banerFooter: require("../image/bannerFoodter.jpeg"),
  photoCongran: require("../image/photoCongran.png"),
  iconStar: require("../image/iconStar.png"),
  icon_scb: require("../image/icon_scb.png"),
  logoapp: require("../image/logoapp.png"),
  iconBankgroup: require("../image/iconBankgroup.png"),
  iconCreditcard: require("../image/iconCreditcard.png"),
  iconSuccess: require("../image/iconSuccess.png"),

  logo_bcel: require("../image/logo_bcel.png"),
  logo_jdbbank: require("../image/logo_jdbbank.png"),
  logo_laovietbank: require("../image/logo_laovietbank.png"),
  logo_ldbbank: require("../image/logo_ldbbank.png"),
  logo_maruhanbank: require("../image/logo_maruhanbank.png"),
  ic_mt: require("../image/ic_mt.png"),
  ic_bankS: require("../image/ic_bank.png"),
  ic_acleda: require("../image/ic_acleda.png"),
  ic_bic: require("../image/ic_bicBank.png"),
  ic_mayBank: require("../image/ic_mayBank.png"),
  ic_apb: require("../image/ic_apb.png"),
  logoHeader: require("../image/logo.png"),
  imageFooter: require("../image/imageFooter.png"),
  ic_englishLang: require("../image/ic_englishLang.png"),
  ic_laosLang: require("../image/ic_laosLang.png"),
  iconNoData: require("../image/iconNoData.png"),
  logiHeader: require("../image/logiHeader.png"),

  thaiBank01BBL: require("../image/thaiBank01BBL.png"),
  thaiBank02UOBT: require("../image/thaiBank02UOBT.png"),
  thaiBank03TTB: require("../image/thaiBank03TTB.png"),
  thaiBank04BAY: require("../image/thaiBank04BAY.png"),
  thaiBank05KBANK: require("../image/thaiBank05KBANK.png"),
  thaiBank06KTB: require("../image/thaiBank06KTB.png"),
  thaiBank07SCB: require("../image/thaiBank07SCB.png"),

  ic_buy: require("../image/buy.png"),
  ic_dataAnalytics: require("../image/dataAnalytics.png"),
  ic_group: require("../image/group.png"),
  ic_profits: require("../image/profits.png"),
  icon_business: require("../image/business.png"),
  ic_sum: require("../image/sum.png"),
  imgSimpleLoto: require("../image/imgSimpleLoto.jpg"),
  iconu_money: require("../image/iconu_money.png"),
  icon_oneplay: require("../image/icon_oneplay.png"),
  bill: require("../image/bill.png"),
  img_umQR: require("../image/img_umQR.jpeg"),
  flowPayQR: require("../image/flowPayQR.PNG"),
};
export default images;
