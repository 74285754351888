import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Stack } from '@mui/material';
import { HeaderCompoment } from '../../compoment'
import './style.css';
import { useTranslation } from 'react-i18next'
import { useValue } from '../../context/ContextProvider'
import { Player } from '@lottiefiles/react-lottie-player';
import IconNodata from '../../lottiefiles/25943-nodata.json'
import { formatNumber } from '../../utils/Formater'

export default function DetailHistory() {
    const { t } = useTranslation();
    const [list, setList] = useState(2);

    const {
        state: { detailInfoBuyLoto }
    } = useValue();

    useEffect(() => {
    });

    return (
        <Box component="main" sx={{ flexGrow: 1 }}>
            <HeaderCompoment icon={true} icondat={false} pathBack={'HistoryBuy'} textHeader='details' />
            <br />
            <br />
            <br />
            <br />
            {
                detailInfoBuyLoto ?
                    <>
                        <Paper elevation={16} sx={{ padding: 2, marginBottom: 1.3, borderRadius: 2, m: 2 }}>
                            <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
                                <Typography sx={{ fontSize: 14, alignItems: 'flex-start' }}>
                                    {t('ReferenceNumber') + ': ' + detailInfoBuyLoto.bull.split('|')[0]}
                                </Typography>
                                <Typography sx={{ fontSize: 14, color: '#00cf21', alignItems: 'flex-end' }}>
                                    {t('succec')}
                                </Typography>
                            </Stack>
                            <hr style={{ border: '0.8px dashed #DADADA', opacity: 0.5 }} />
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <th style={{ textAlign: 'start', width: '50%', fontSize: 14, fontWeight: 400 }}>{t('userBuy')}</th>
                                    <td style={{ width: '50%', textAlign: 'end', fontSize: 14, fontWeight: 400 }}>{detailInfoBuyLoto.bull.split('|')[2]}</td>
                                </tr>
                                <tr>
                                    <th style={{ textAlign: 'start', width: '50%', fontSize: 14, fontWeight: 400 }}>{t('installmentDate')}</th>
                                    <td style={{ width: '50%', textAlign: 'end', fontSize: 14, fontWeight: 400 }}>1 มิถุนายน 2564</td>
                                </tr>
                                <tr>
                                    <th style={{ textAlign: 'start', width: '50%', fontSize: 14, fontWeight: 400 }}>{t('BuyData')}</th>
                                    <td style={{ width: '50%', textAlign: 'end', fontSize: 14, fontWeight: 400 }}>{detailInfoBuyLoto.bull.split('|')[1]}</td>
                                </tr>
                                <tr>
                                    <th style={{ textAlign: 'start', width: '50%', fontSize: 14, fontWeight: 400 }}>{t('PaymentChannel')}</th>
                                    <td style={{ width: '50%', textAlign: 'end', fontSize: 14, fontWeight: 400 }}>BCEL One</td>
                                </tr>
                            </table>
                        </Paper>


                        <Paper elevation={16} sx={{ padding: 2, marginBottom: 1.3, borderRadius: 2, m: 2 }}>
                            <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
                                <Typography h5 sx={{ fontSize: 14, fontWeight: 800, alignItems: 'flex-start' }}>
                                    {t('item')}
                                </Typography>
                            </Stack>
                            <div className="lotto-lists pt-2">
                                {
                                    JSON.parse(detailInfoBuyLoto.data).slice(0, list).map((row) => (
                                        <div key={row.id} class="card-lotto">
                                            <div class="card-row row-1">
                                                <div class="set s-2">
                                                    <span>{t('period')} {row.lotId}</span>
                                                   
                                                </div>
                                                <div class="logo">
                                                    <span class="icons"></span>
                                                    <span>{t('GovernmentLottery')}</span>
                                                </div>
                                                {/* <a class="link-grey" data-fancybox="" href="#">{t('viewLoto')}</a> */}
                                            </div>
                                            <div class="card-row row-2">
                                                <div class="number-lists textgroup">
                                                    <div style={{ left: 3 }}>
                                                        <span style={{ letterSpacing: '0.5cm' }}>{row.lottoNumber}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-row row-3">
                                                <div class="textgroup">
                                                    <span>{t('period')} {row.lotId}</span>
                                                    <span>{t('set')} 1</span>
                                                    <span className="viewer">{t('totalAndService')}</span>
                                                    {/* <span class="viewer"><span class="icons"></span>{t('Watching')} 0 {t('people')}</span> */}
                                                </div>
                                                <span class="price"><strong>{row.price}</strong> {t('bat')}</span>
                                            </div>
                                        </div>
                                    ))
                                }


                                {
                                    JSON.parse(detailInfoBuyLoto.data).length > 2 ? list != 1000 ?
                                        <div className="d-flex py-2">
                                            <button onClick={() => setList(1000)} className="btn btn-xs viewmore mx-auto">{t('SeeAll')}</button>
                                        </div> : null : null
                                }

                                {
                                    list === 1000 ?
                                        <div className="d-flex py-2">
                                            <button onClick={() => setList(2)} className="btn btn-xs viewmore mx-auto">{t('ShowLess')}</button>
                                        </div> : null
                                }



                            </div>
                        </Paper>

                        <Paper elevation={16} sx={{ padding: 2, marginBottom: 1.3, borderRadius: 2, m: 2 }}>
                            <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
                                <Typography h5 sx={{ fontSize: 14, fontWeight: 800, alignItems: 'flex-start' }}>
                                    {t('PriceSummary')}
                                </Typography>
                            </Stack>
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <th style={{ textAlign: 'start', width: '50%', fontSize: 14, fontWeight: 400 }}>{t('NumberItems')}</th>
                                    <td style={{ width: '50%', textAlign: 'end', fontSize: 14, fontWeight: 400 }}>
                                        {JSON.parse(detailInfoBuyLoto.data).length} {t('item')}
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ textAlign: 'start', width: '50%', fontSize: 14, fontWeight: 400 }}>{t('SumMoney')}</th>
                                    <td style={{ width: '50%', textAlign: 'end', fontSize: 14, fontWeight: 400 }}>
                                        {formatNumber(JSON.parse(detailInfoBuyLoto.data).reduce((a, v) => a = a + parseInt(v.price), 0) + "")} {t('bat')}
                                    </td>
                                </tr>

                                <tr>
                                    <th style={{ textAlign: 'start', width: '50%', fontSize: 14, fontWeight: 400 }}>{t('discount')}</th>
                                    <td style={{ width: '50%', textAlign: 'end', fontSize: 14, fontWeight: 400 }}>
                                        {formatNumber(JSON.parse(detailInfoBuyLoto.data).reduce((a, v) => a = a + parseInt(v.discount), 0) + "")} {t('bat')}
                                    </td>
                                </tr>

                                <tr>
                                    <th style={{ textAlign: 'start', width: '50%', fontSize: 14, fontWeight: 400 }}>{t('Tax')}</th>
                                    <td style={{ width: '50%', textAlign: 'end', fontSize: 14, fontWeight: 400 }}>
                                        {formatNumber(JSON.parse(detailInfoBuyLoto.data).reduce((a, v) => a = a + parseInt(v.tax), 0) + "")} {t('bat')}
                                    </td>
                                </tr>

                                <tr>
                                    <th style={{ textAlign: 'start', width: '50%', fontSize: 14, fontWeight: 400 }}>{t('BalanceMustPaid')}</th>
                                    <td style={{ width: '50%', textAlign: 'end', fontSize: 16, fontWeight: 600 }}>
                                        {formatNumber(JSON.parse(detailInfoBuyLoto.data).reduce((a, v) => a = a + parseInt(v.total), 0) + "")} {t('bat')}
                                    </td>
                                </tr>
                            </table>
                        </Paper>
                    </>
                    : <div>
                        <Player
                            src={IconNodata}
                            className="player"
                            loop
                            autoplay
                        />
                    </div>
            }

        </Box>
    )
}
