
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
import { requestHMACSHA256 } from '../body/bodyRequest'
var base64 = require('base-64');
var utf8 = require('utf8')


export function encodeBase64(input) {
    var bytes = utf8.encode(input)
    return base64.encode(bytes)
}

export function encodeHmacSHA256(second_key, token) {
    let keyHmac = requestHMACSHA256(second_key, token)
    const key = Base64.stringify(hmacSHA256(keyHmac, token));
    return key
}