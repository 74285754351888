import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Button,
  Paper,
  Box,
  Alert,
  AlertTitle,
  Link,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { reqBodyFindLotteryNumber } from "../../body/bodyRequest";
import { useValue } from "../../context/ContextProvider";
import { requestFindLotteryNumber } from "../../actions/ResetClient";
// import { AertAddCart } from '../../compoment'
import { useNavigate } from "react-router-dom";
import { DrawerBottom } from "../../screen";

LotoScreen.propTypes = {
  hien: PropTypes.bool,
};
export default function LotoScreen({ hien }) {
  const inputReferenceOne = useRef(null);
  const inputReferenceTwo = useRef(null);
  const inputReferenceThree = useRef(null);
  const inputReferenceFour = useRef(null);
  const inputReferenceFive = useRef(null);
  const inputReferencesix = useRef(null);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [listDataBuy, setListDataBuy] = useState([]);

  const {
    state: { currentUser, tokenInfo, resultSearchLoto },
    dispatch,
  } = useValue();

  const [sixActive, setSixActive] = useState("");
  const [six, setSix] = useState("");
  const [fiveActive, setfiveActive] = useState("");
  const [five, setFive] = useState("");
  const [fourActive, setfourActive] = useState("");
  const [four, setFour] = useState("");
  const [threeActive, setthreeActive] = useState("");
  const [three, setThree] = useState("");
  const [twoActive, settwoActive] = useState("");
  const [two, setTwo] = useState("");
  const [oneActive, setOneActive] = useState("");
  const [one, setOne] = useState("");

  const [loader, setLoader] = useState(false);

  // const [localStor, setLocalStor] = useState([])

  useEffect(() => {
    // inputReferencesix.current.focus();
    // console.log('mmmmmmmmm:', currentUser.lotAvaiable)
    //console.log('listDataBuy:', listDataBuy)
    if (listDataBuy.length > 0) {
      localStorage.setItem("addCart", JSON.stringify(listDataBuy));
      dispatch({ type: "ADD_TO_CART", payload: listDataBuy });
      //console.log('addCart-loto:', JSON.parse(localStorage.getItem('addCart')))
    }
    if (JSON.parse(localStorage.getItem("addCart")) === null) {
      localStorage.setItem("addCart", JSON.stringify(listDataBuy));
    }

    if (loader) {
      //console.log('-----loader------------')
      navigate("Cart");
    }
  }, [listDataBuy]);

  const onSixValeu = (e) => {
    const value = e.target.value;
    const Sixfocus =
      value.length > 0 ? inputReferenceFive.current.focus() : null;
    setSix(value);
    setSixActive(value ? "has-value" : "");
  };
  const onFiveValeu = (e) => {
    const value = e.target.value;
    const Fivefocus =
      value.length > 0 ? inputReferenceFour.current.focus() : null;
    setFive(value);
    setfiveActive(value ? "has-value" : "");
  };
  const onFourValeu = (e) => {
    const value = e.target.value;
    const Fourfocus =
      value.length > 0 ? inputReferenceThree.current.focus() : null;
    setFour(value);
    setfourActive(value ? "has-value" : "");
  };
  const onThreeValeu = (e) => {
    const value = e.target.value;
    const Threefocus =
      value.length > 0 ? inputReferenceTwo.current.focus() : null;
    setThree(value);
    setthreeActive(value ? "has-value" : "");
  };
  const onTwoValeu = (e) => {
    const value = e.target.value;
    const Twofocus =
      value.length > 0 ? inputReferenceOne.current.focus() : null;
    setTwo(value);
    settwoActive(value ? "has-value" : "");
  };

  const onOneValeu = (e) => {
    const value = e.target.value;
    // const Onefocus = value.length > 0 ? inputReferenceTwo.current.focus() : null
    setOne(value);
    setOneActive(value ? "has-value" : "");
  };

  const random6DigitNumber = () => {
    var minm = 100000;
    var maxm = 999999;
    let result = Math.floor(Math.random() * (maxm - minm + 1)) + minm;
    const Random6 = result.toString();

    if (currentUser) {
      if (currentUser.lotAvaiable) {
        if (currentUser.lotAvaiable.length > 0) {
          const token = tokenInfo.access_token;
          const lotId = currentUser.lotAvaiable[0].lotId;
          const dataReq = {
            lotId: lotId,
            number: Random6,
            digit: null,
            lottType: null,
            limit: 10,
          };
          //console.log('digitBack2:', dataReq)
          requestFindLotteryNumber(dataReq, token, dispatch);
        }
      }
    }

    // onOneValeu(Random6.substring(5, 6))
    // onTwoValeu(Random6.substring(4, 5))
    // onThreeValeu(Random6.substring(3, 4))
    // onFourValeu(Random6.substring(2, 3))
    // onFiveValeu(Random6.substring(1, 2))
    // onSixValeu(Random6.substring(0, 1))
    //console.log('first:', Random6.substring(0, 6))
  };
  const random3DigitNumber = () => {
    var minm = 100;
    var maxm = 999;
    let result = Math.floor(Math.random() * (maxm - minm + 1)) + minm;
    const Random3 = result.toString();

    if (currentUser) {
      if (currentUser.lotAvaiable) {
        if (currentUser.lotAvaiable.length > 0) {
          const token = tokenInfo.access_token;
          const lotId = currentUser.lotAvaiable[0].lotId;
          const dataReq = {
            lotId: lotId,
            number: Random3,
            digit: 3,
            lottType: "back",
            limit: 10,
          };
          //console.log('digitBack3:', dataReq)
          requestFindLotteryNumber(dataReq, token, dispatch);
        }
      }
    }

    // onOneValeu(Random3.substring(2, 3))
    // onTwoValeu(Random3.substring(1, 2))
    // onThreeValeu(Random3.substring(0, 1))
    // onFourValeu('')
    // onFiveValeu('')
    // onSixValeu('')
    // setfourActive('')
    // setfiveActive('')
    // setSixActive('')
    //console.log('first:', Random3.substring(0, 3))
  };
  const random2DigitNumber = () => {
    var minm = 10;
    var maxm = 99;
    let result = Math.floor(Math.random() * (maxm - minm + 1)) + minm;
    const Random2 = result.toString();

    if (currentUser) {
      if (currentUser.lotAvaiable) {
        if (currentUser.lotAvaiable.length > 0) {
          const token = tokenInfo.access_token;
          const lotId = currentUser.lotAvaiable[0].lotId;
          const dataReq = {
            lotId: lotId,
            number: Random2,
            digit: 2,
            lottType: "back",
            limit: 10,
          };
          //console.log('digitBack2:', dataReq)
          requestFindLotteryNumber(dataReq, token, dispatch);
        }
      }
    }

    // onOneValeu(Random2.substring(1, 2))
    // onTwoValeu(Random2.substring(0, 1))
    // onThreeValeu('')
    // onFourValeu('')
    // onFiveValeu('')
    // onSixValeu('')
    // setthreeActive('')
    // setfourActive('')
    // setfiveActive('')
    // setSixActive('')
    //console.log('first:', Random2.substring(0, 2))
  };
  const onSearch = () => {
    if (currentUser) {
      if (currentUser.lotAvaiable) {
        if (currentUser.lotAvaiable.length > 0) {
          let dataReq;
          const token = tokenInfo.access_token;
          const lotId = currentUser.lotAvaiable[0].lotId;
          let number;
          const digit = null;
          const lottType = null;
          const limit = 10;
          const numberSelect =
            six + "-" + five + "-" + four + "-" + three + "-" + two + "-" + one;

          //console.log('numberSelect:', numberSelect)
          const str = numberSelect ? numberSelect.split("-") : null;
          const sixLo = str[0];
          const fiveLo = str[1];
          const fourLo = str[2];
          const threeLo = str[3];
          const twoLo = str[4];
          const oneLo = str[5];

          if (!sixLo && !fiveLo && !fourLo && !threeLo && !twoLo && !oneLo) {
            //console.log('No click')
            dataReq = reqBodyFindLotteryNumber(
              lotId,
              number,
              digit,
              lottType,
              limit
            );
            //console.log('dataReq:', dataReq)
            requestFindLotteryNumber(dataReq, token, dispatch);
          } else {
            if (sixLo && fiveLo && fourLo && threeLo && twoLo && oneLo) {
              //console.log('--6-:', sixLo + fiveLo + fourLo + threeLo + twoLo + oneLo)
              number = sixLo + fiveLo + fourLo + threeLo + twoLo + oneLo;
              dataReq = {
                lotId: lotId,
                number: number,
                digit: 6,
                lottType: null,
                limit: 10,
              };
              //console.log('dataReq--6--:', dataReq)
              requestFindLotteryNumber(dataReq, token, dispatch);
            } else {
              if (fiveLo && fourLo && threeLo && twoLo && oneLo) {
                //console.log('--5-:', fiveLo + fourLo + threeLo + twoLo + oneLo)
                number = fiveLo + fourLo + threeLo + twoLo + oneLo;
                dataReq = {
                  lotId: lotId,
                  number: number,
                  digit: 5,
                  lottType: null,
                  limit: 10,
                };
                //console.log('dataReq--5--:', dataReq)
                requestFindLotteryNumber(dataReq, token, dispatch);
              } else {
                if (fourLo && threeLo && twoLo && oneLo) {
                  //console.log('--4-:', fourLo + threeLo + twoLo + oneLo)
                  number = fiveLo + fourLo + threeLo + twoLo + oneLo;
                  dataReq = {
                    lotId: lotId,
                    number: number,
                    digit: 4,
                    lottType: null,
                    limit: 10,
                  };
                  //console.log('dataReq-4:', dataReq)
                  requestFindLotteryNumber(dataReq, token, dispatch);
                } else {
                  if (oneLo && twoLo && threeLo) {
                    //console.log('--3-back-:', threeLo + twoLo + oneLo)
                    dataReq = {
                      lotId: lotId,
                      number: threeLo + twoLo + oneLo,
                      digit: 3,
                      lottType: "back",
                      limit: 10,
                    };
                    //console.log('digitBack-3:', dataReq)
                    requestFindLotteryNumber(dataReq, token, dispatch);
                  } else {
                    if (sixLo && fiveLo && fourLo) {
                      //console.log('--3-front-:', sixLo + fiveLo + fourLo)
                      dataReq = {
                        lotId: lotId,
                        number: sixLo + fiveLo + fourLo,
                        digit: 3,
                        lottType: "front",
                        limit: 10,
                      };
                      //console.log('digitFront-3:', dataReq)
                      requestFindLotteryNumber(dataReq, token, dispatch);
                    } else {
                      if (oneLo && twoLo) {
                        //console.log('--2-back-:', oneLo + twoLo)
                        dataReq = {
                          lotId: lotId,
                          number: twoLo + oneLo,
                          digit: 2,
                          lottType: "back",
                          limit: 10,
                        };
                        //console.log('digitBack2:', dataReq)
                        requestFindLotteryNumber(dataReq, token, dispatch);
                      } else {
                        if (sixLo && fiveLo) {
                          //console.log('--2-front-:', sixLo + fiveLo)
                          dataReq = {
                            lotId: lotId,
                            number: sixLo + fiveLo,
                            digit: 2,
                            lottType: "front",
                            limit: 10,
                          };
                          //console.log('digitFront2:', dataReq)
                          requestFindLotteryNumber(dataReq, token, dispatch);
                        } else {
                          if (sixLo) {
                            return dispatch({
                              type: "UPDATE_ALERT",
                              payload: {
                                open: true,
                                severity: "warning",
                                message: t("InfoIncorrect"),
                              },
                            });
                          } else {
                            if (oneLo) {
                              return dispatch({
                                type: "UPDATE_ALERT",
                                payload: {
                                  open: true,
                                  severity: "warning",
                                  message: t("InfoIncorrect"),
                                },
                              });
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        } else {
          alert("currentUser is null");
        }
      }
    } else {
      alert("currentUser is null");
    }
  };

  const onMessengeAddCart = () => {
    dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "success",
        message: t("success"),
      },
    });
    setLoader(true);
  };

  const onComfirm = (e) => {
    dispatch({ type: "CLOSE_CART_DIALOG", payload: null });
    if (e) {
      // console.log("roo info:", localStorage.getItem("doubleLuck"));
      //console.log('...listDataBuy:', ...listDataBuy)
      const localStor = JSON.parse(localStorage.getItem("addCart"));
      //console.log('---localStor---', localStor)
      if (e) {
        if (localStor.length > 0) {
          localStor.map((value) => {
            if (value.lottoNumber === e.lottoNumber) {
              return dispatch({
                type: "UPDATE_ALERT",
                payload: {
                  open: true,
                  severity: "warning",
                  message: t("haveLotoNamber"),
                },
              });
            } else {
              let itm = localStor.filter(
                (data) => data.lottoNumber === e.lottoNumber
              );
              if (itm.length > 0) {
                return dispatch({
                  type: "UPDATE_ALERT",
                  payload: {
                    open: true,
                    severity: "warning",
                    message: t("haveLotoNamber"),
                  },
                });
              } else {
                if (localStorage.getItem("doubleLuck") === "2") {
                  setListDataBuy([
                    ...localStor,
                    {
                      id_loto: localStor.length + 1,
                      id: e.id,
                      lotId: e.lotId,
                      lotDate: e.lotDate,
                      numberStatus: e.numberStatus,
                      winnerStatus: e.winnerStatus,
                      lottoNumber: e.lottoNumber,
                      lottoPrice: parseInt(e.lottoPrice, 10) + 20,
                      costPrice: e.costPrice,
                      qty: e.qty,
                    },
                  ]);
                } else {
                  setListDataBuy([
                    ...localStor,
                    {
                      id_loto: localStor.length + 1,
                      id: e.id,
                      lotId: e.lotId,
                      lotDate: e.lotDate,
                      numberStatus: e.numberStatus,
                      winnerStatus: e.winnerStatus,
                      lottoNumber: e.lottoNumber,
                      lottoPrice: e.lottoPrice,
                      costPrice: e.costPrice,
                      qty: e.qty,
                    },
                  ]);
                }

                onMessengeAddCart();
              }
            }
          });
        } else {
          //console.log('11', listDataBuy.length)
          if (listDataBuy.length > 0) {
            listDataBuy.map((value) => {
              if (value.lottoNumber === e.lottoNumber) {
                return dispatch({
                  type: "UPDATE_ALERT",
                  payload: {
                    open: true,
                    severity: "warning",
                    message: t("haveLotoNamber"),
                  },
                });
              } else {
                let itm = listDataBuy.filter(
                  (data) => data.lottoNumber === e.lottoNumber
                );
                if (itm.length > 0) {
                  return dispatch({
                    type: "UPDATE_ALERT",
                    payload: {
                      open: true,
                      severity: "warning",
                      message: t("haveLotoNamber"),
                    },
                  });
                } else {
                  if (localStorage.getItem("doubleLuck") === "2") {
                    setListDataBuy([
                      ...listDataBuy,
                      {
                        id_loto: listDataBuy.length + 1,
                        id: e.id,
                        lotId: e.lotId,
                        lotDate: e.lotDate,
                        numberStatus: e.numberStatus,
                        winnerStatus: e.winnerStatus,
                        lottoNumber: e.lottoNumber,
                        lottoPrice: parseInt(e.lottoPrice, 10) + 20,
                        costPrice: e.costPrice,
                        qty: e.qty,
                      },
                    ]);
                  } else {
                    setListDataBuy([
                      ...listDataBuy,
                      {
                        id_loto: listDataBuy.length + 1,
                        id: e.id,
                        lotId: e.lotId,
                        lotDate: e.lotDate,
                        numberStatus: e.numberStatus,
                        winnerStatus: e.winnerStatus,
                        lottoNumber: e.lottoNumber,
                        lottoPrice: e.lottoPrice,
                        costPrice: e.costPrice,
                        qty: e.qty,
                      },
                    ]);
                  }
                  onMessengeAddCart();
                }
              }
            });
          } else {
            if (localStorage.getItem("doubleLuck") === "2") {
              setListDataBuy([
                ...listDataBuy,
                {
                  id_loto: listDataBuy.length + 1,
                  id: e.id,
                  lotId: e.lotId,
                  lotDate: e.lotDate,
                  numberStatus: e.numberStatus,
                  winnerStatus: e.winnerStatus,
                  lottoNumber: e.lottoNumber,
                  lottoPrice: parseInt(e.lottoPrice, 10) + 20,
                  costPrice: e.costPrice,
                  qty: e.qty,
                },
              ]);
            } else {
              setListDataBuy([
                ...listDataBuy,
                {
                  id_loto: listDataBuy.length + 1,
                  id: e.id,
                  lotId: e.lotId,
                  lotDate: e.lotDate,
                  numberStatus: e.numberStatus,
                  winnerStatus: e.winnerStatus,
                  lottoNumber: e.lottoNumber,
                  lottoPrice: e.lottoPrice,
                  costPrice: e.costPrice,
                  qty: e.qty,
                },
              ]);
            }
            onMessengeAddCart();
          }
        }
      }
    } else {
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "warning",
          message: t("CanNotGetDataLoto"),
        },
      });
    }
  };
  const onClear = () => {
    if (inputReferencesix.current) {
      setSix("");
      inputReferencesix.current.value = "";
    }
    if (inputReferenceFive.current) {
      setFive("");
      inputReferenceFive.current.value = "";
    }
    if (inputReferenceFour.current) {
      setFour("");
      inputReferenceFour.current.value = "";
    }
    if (inputReferenceThree.current) {
      setThree("");
      inputReferenceThree.current.value = "";
    }
    if (inputReferenceTwo.current) {
      setTwo("");
      inputReferenceTwo.current.value = "";
    }
    if (inputReferenceOne.current) {
      setOne("");
      inputReferenceOne.current.value = "";
    }
  };

  const myLotteryOffcanvas = (e) => {
    dispatch({ type: "OPEN_CART_DIALOG", payload: e });
  };

  return (
    <>
      <div className="card-info flex-column">
        {hien ? (
          <h1 className="text-center py-2">ຈັບສະຫຼາກວັນທີ 16 ທັນວາ 2022</h1>
        ) : null}

        <h5 className="mt-2 d-flex">
          {t("LotterySearch")}
          {!hien ? (
            <span className="ms-auto clear">
              <Link
                component="button"
                variant="body2"
                onClick={() => onClear()}
              >
                {t("Clear")}
              </Link>
            </span>
          ) : null}
        </h5>

        <div className="lotto-number check">
          <input
            ref={inputReferencesix}
            className={`form-control ${sixActive}`}
            type="text"
            onChange={onSixValeu}
            defaultValue={six}
            maxlength="1"
            pattern="[0-9.]+"
            inputmode="numeric"
          />
          <input
            ref={inputReferenceFive}
            className={`form-control ${fiveActive}`}
            type="text"
            onChange={onFiveValeu}
            defaultValue={five}
            maxlength="1"
            pattern="[0-9.]+"
            inputmode="numeric"
          />
          <input
            ref={inputReferenceFour}
            className={`form-control ${fourActive}`}
            type="text"
            onChange={onFourValeu}
            defaultValue={four}
            maxlength="1"
            pattern="[0-9.]+"
            inputmode="numeric"
          />
          <input
            ref={inputReferenceThree}
            className={`form-control ${threeActive}`}
            type="text"
            onChange={onThreeValeu}
            defaultValue={three}
            maxlength="1"
            pattern="[0-9.]+"
            inputmode="numeric"
          />
          <input
            ref={inputReferenceTwo}
            className={`form-control ${twoActive}`}
            type="text"
            onChange={onTwoValeu}
            defaultValue={two}
            maxlength="1"
            pattern="[0-9.]+"
            inputmode="numeric"
          />
          <input
            ref={inputReferenceOne}
            className={`form-control ${oneActive}`}
            type="text"
            onChange={onOneValeu}
            defaultValue={one}
            maxlength="1"
            pattern="[0-9.]+"
            inputmode="numeric"
          />
        </div>
        {currentUser ? (
          currentUser.lotAvaiable ? (
            currentUser.lotAvaiable[0].lotStatus !== 6 ? (
              <>
                <Grid item xs={12} sm={12} sx={{ marginBottom: 2 }}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => onSearch()}
                  >
                    {t("Search")}
                  </Button>
                </Grid>
                <div className="d-flex fs-12 gap-2 align-items-center justify-content-center">
                  <span style={{ color: "#8F8F8F" }}>
                    {t("randomLuckyNumbers")}
                  </span>
                  <ul className="nav lucky-number">
                    <li>
                      <button
                        onClick={() => random2DigitNumber()}
                        className="link-item"
                      >
                        {t("2DigitNumber")}
                      </button>
                    </li>
                    <li>
                      <button
                        className="link-item"
                        onClick={() => random3DigitNumber()}
                      >
                        {t("3DigitNumber")}
                      </button>
                    </li>
                    <li>
                      <button
                        className="link-item"
                        onClick={() => random6DigitNumber()}
                      >
                        {t("6DigitNumber")}
                      </button>
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              <Alert severity="error">
                <AlertTitle>{t("alert")}</AlertTitle>
                {t("statusLoto")}
              </Alert>
            )
          ) : (
            <Alert severity="error">
              <AlertTitle>{t("alert")}</AlertTitle>
              {t("statusLoto")}
            </Alert>
          )
        ) : (
          <Alert severity="error">
            <AlertTitle>{t("alert")}</AlertTitle>
            {t("statusLoto")}
          </Alert>
        )}
      </div>
      <br />

      {resultSearchLoto.length > 0 ? (
        <Paper elevation={12} sx={{ mb: 2 }}>
          <Box sx={{ padding: 2 }}>
            <h5 className={`d-flex gap-2 mb-2`}>
              {t("TheLatestNumber")}
              <span className="icons icon-fire w-18"></span>
            </h5>
          </Box>

          {resultSearchLoto.map((row) => (
            <div key={row.id} className="card-info">
              <div className="tab-content mt-0">
                <div id="set2" className="tab-pane show active" role="tabpanel">
                  <div className="lotto-lists limit-show">
                    {currentUser.viewImageLottery ? (
                      <Link onClick={() => myLotteryOffcanvas(row)}>
                        <img
                          src={
                            process.env.REACT_APP_IMAGE +
                            row.lotId +
                            "/" +
                            row.lottoNumber +
                            ".png"
                          }
                          alt="Snow"
                          className="img-hover-shadow"
                          style={{ width: "100%" }}
                        />
                      </Link>
                    ) : (
                      <div className="card-lotto shown">
                        <div className="card-row row-1">
                          <div className="set s-2">
                            <span>{row.lotDate}</span>
                          </div>
                          <div className="logo">
                            <span className="icons"></span>
                            {t("governmentLottery")}
                          </div>
                        </div>
                        <div className="card-row row-2">
                          <div className="number-lists textgroup">
                            <span style={{ letterSpacing: "0.5cm" }}>
                              {row.lottoNumber}
                            </span>
                          </div>

                          <button
                            className="btn select"
                            data-bs-toggle="offcanvas"
                            onClick={() => myLotteryOffcanvas(row)}
                            type="button"
                          >
                            {t("selected")}
                          </button>
                        </div>
                        <div className="card-row row-3">
                          <div className="textgroup">
                            <span>
                              {t("period")} {row.lotId}
                            </span>
                            <span>{t("set")} 1</span>
                            <span className="viewer">
                              {t("totalAndService")}
                            </span>
                          </div>
                          <span className="price">
                            <strong>{row.lottoPrice}</strong> {t("bat")}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Paper>
      ) : null}
      <DrawerBottom tyleBar="ADD_CART" onComfirm={(e) => onComfirm(e)} />
    </>
  );
}
