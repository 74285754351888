
import fetchData from '../utils/fetchData';
import fetchLogin from '../utils/fetchLogin'
import { encodeHmacSHA256 } from '../utils/utilsCommon'
import { requestGetToken, requestLoginCustomer, requestBodyOTP, requestBodyResetPassword, requestLoginCustomerReset } from '../body/bodyRequest'
import { encodeBase64 } from '../utils/utilsCommon'

const url = process.env.REACT_APP_SERVER_URL;
const second_key = process.env.REACT_APP_SECOND_KEY;
const user_agent = process.env.REACT_APP_USER_AGENT;

const username = process.env.REACT_APP_USERNAME;
const password = process.env.REACT_APP_PASSWORD;
const secret_key = process.env.REACT_APP_SECRET_KEY;

export const getToket = async (user_cus, pass_cus, valueRemamber,lang, dispatch) => {
  if (username && password && secret_key) {
    const key = encodeBase64(username + ':' + password + ':' + secret_key)
    const data = requestGetToken(username, password, secret_key)
    //console.log('data body:', data)
    dispatch({ type: 'START_LOADING' });
    if (key && data) {
      dispatch({ type: 'START_LOADING' });
      const result = await fetchData(
        {
          url: url + '/generate_token',
          method: 'POST',
          key: key,
          body: data
        },
        dispatch
      );
      if (result) {
        //console.log('-----result-----', result)
        if (result.responseCode === '00000') {
          dispatch({ type: 'TOKEN_INFO', payload: result });
          const dataLogin = requestLoginCustomer(user_cus, pass_cus, lang)
          const token = result.access_token
          const Signature = encodeHmacSHA256(second_key, token)
          // console.log('token:', token)
          // console.log('Signature:', Signature)
          // console.log('dataLogin:', dataLogin)

          const result_login = await fetchLogin(
            {
              url: url + '/service/loginCustomer',
              method: 'POST',
              Signature: Signature,
              Authorization: token,
              ClientId: user_agent,
              body: dataLogin
            },
            dispatch
          );
          if (result_login.responseCode === "00000") {
            // console.log("result_login:", result_login)
            if (valueRemamber) {
              let info = [{ 'username': result_login.accInfo.username, 'password': result_login.accInfo.password }]
              dispatch({ type: 'INFO_REMEMBER', payload: info });
            } else {
              dispatch({ type: 'INFO_REMEMBER', payload: [] });
            }
            dispatch({ type: 'UPDATE_USER', payload: result_login });


            dispatch({
              type: 'UPDATE_ALERT',
              payload: {
                open: true,
                severity: 'success',
                message: result_login.message,
              },
            });
          } else {
            dispatch({ type: 'END_LOADING' });
            return dispatch({
              type: 'UPDATE_ALERT',
              payload: {
                open: true,
                severity: 'error',
                message: result_login.message,
              },
            });
          }


        } else {
          dispatch({ type: 'END_LOADING' });
          return dispatch({
            type: 'UPDATE_ALERT',
            payload: {
              open: true,
              severity: 'error',
              message: 'Error can not get token',
            },
          });
        }

      }
      dispatch({ type: 'END_LOADING' });
    } else {
      dispatch({ type: 'END_LOADING' });
      return dispatch({
        type: 'UPDATE_ALERT',
        payload: {
          open: true,
          severity: 'error',
          message: 'Error can not get data and key',
        },
      });
    }

  } else {
    dispatch({ type: 'END_LOADING' });
    return dispatch({
      type: 'UPDATE_ALERT',
      payload: {
        open: true,
        severity: 'error',
        message: 'Error can not get user and pass',
      },
    });
  }

};


export const requestOTP = async (userName, password_cus, confirmPassword, msisdn, content, dispatch) => {
  if (username && password && secret_key) {

    const key = encodeBase64(username + ':' + password + ':' + secret_key)
    const data = requestGetToken(username, password, secret_key)
    //console.log('data body:', data)
    dispatch({ type: 'START_LOADING' });


    if (key && data) {
      dispatch({ type: 'START_LOADING' });
      const result = await fetchData(
        {
          url: url + '/generate_token',
          method: 'POST',
          key: key,
          body: data
        },
        dispatch
      );
      if (result) {
        //console.log('-----result-----', result)
        if (result.responseCode === '00000') {
          dispatch({ type: 'TOKEN_INFO', payload: result });
          const dataReqOtp = requestBodyOTP(msisdn, content)
          const token = result.access_token
          const Signature = encodeHmacSHA256(second_key, token)
          //console.log('token:', token)
          //console.log('Signature:', Signature)
          //console.log('dataReqOtp:', dataReqOtp)

          const result_reqOTP = await fetchLogin(
            {
              url: url + '/service/requestOTP',
              method: 'POST',
              Signature: Signature,
              Authorization: token,
              ClientId: user_agent,
              body: dataReqOtp
            },
            dispatch
          );

          if (result_reqOTP.responseCode === "00000") {
            //console.log('result_reqOTP:', result_reqOTP)
            dispatch({ type: 'OPEN_POPUP_OTP' });
            dispatch({ type: 'END_LOADING' });
          } else {
            dispatch({ type: 'END_LOADING' });
            return dispatch({
              type: 'UPDATE_ALERT',
              payload: {
                open: true,
                severity: 'error',
                message: result_reqOTP.message,
              },
            });
          }

        }
      }
      dispatch({ type: 'END_LOADING' });
    } else {
      dispatch({ type: 'END_LOADING' });
      return dispatch({
        type: 'UPDATE_ALERT',
        payload: {
          open: true,
          severity: 'error',
          message: 'Error can not get data and key',
        },
      });
    }



  } else {
    dispatch({ type: 'END_LOADING' });
    return dispatch({
      type: 'UPDATE_ALERT',
      payload: {
        open: true,
        severity: 'error',
        message: 'Error can not get user and pass',
      },
    });
  }
}


export const requestResetPassword = async (userName, msisdn, password, confirmPassword, otpValue, content, tokenInfo, dispatch) => {
  try {
    if (userName && msisdn && password && confirmPassword && otpValue && tokenInfo) {
      const dataReqOtp = requestBodyResetPassword(msisdn, content, userName, password, confirmPassword, otpValue)
      const token = tokenInfo.access_token
      const Signature = encodeHmacSHA256(second_key, token)
      //console.log('token:', token)
      //console.log('Signature:', Signature)
      //console.log('dataReqOtp:', dataReqOtp)
      //console.log('dataReqOtp:', dataReqOtp)

      const resultResetPassword = await fetchLogin(
        {
          url: url + '/service/resetPassword',
          method: 'POST',
          Signature: Signature,
          Authorization: token,
          ClientId: user_agent,
          body: dataReqOtp
        },
        dispatch
      );

      if (resultResetPassword.responseCode === "00000") {
        //console.log('resultResetPassword:', resultResetPassword)

        dispatch({ type: 'OPEN_DIALOG' });
        dispatch({ type: 'END_LOADING' });
        dispatch({ type: 'CLOSE_POPUP_OTP' });

        /////////login reset
        const dataLoginReset = requestLoginCustomerReset(userName, password)
        const tokenReset = tokenInfo.access_token
        const SignatureReset = encodeHmacSHA256(second_key, tokenReset)
        //console.log('token9999:', tokenReset)
        //console.log('Signature9999:', SignatureReset)
        //console.log('dataLogin9999:', dataLoginReset)

        // const result_login_reset = await fetchLogin(
        //   {
        //     url: url + '/service/loginCustomer',
        //     method: 'POST',
        //     Signature: SignatureReset,
        //     Authorization: tokenReset,
        //     ClientId: user_agent,
        //     body: dataLoginReset
        //   },
        //   dispatch
        // );
        // if (result_login_reset.responseCode === "00000") {
      
        //   dispatch({ type: 'UPDATE_USER', payload: result_login_reset });
        //   dispatch({ type: 'OPEN_DIALOG' });
        //   dispatch({ type: 'END_LOADING' });
        //   dispatch({ type: 'CLOSE_POPUP_OTP' });
        //   dispatch({
        //     type: 'UPDATE_ALERT',
        //     payload: {
        //       open: true,
        //       severity: 'success',
        //       message: result_login_reset.message,
        //     },
        //   });
        // } else {
        //   return dispatch({
        //     type: 'UPDATE_ALERT',
        //     payload: {
        //       open: true,
        //       severity: 'error',
        //       message: result_login_reset.message,
        //     },
        //   });
        // }

        ////end

      } else {
        dispatch({ type: 'END_LOADING' });
        return dispatch({
          type: 'UPDATE_ALERT',
          payload: {
            open: true,
            severity: 'error',
            message: resultResetPassword.message,
          },
        });
      }

    } else {
      return dispatch({
        type: 'UPDATE_ALERT',
        payload: {
          open: true,
          severity: 'error',
          message: 'Data input not null',
        },
      });
    }

  } catch (error) {
    dispatch({ type: 'END_LOADING' });
    return dispatch({
      type: 'UPDATE_ALERT',
      payload: {
        open: true,
        severity: 'error',
        message: error,
      },
    });
  }
}