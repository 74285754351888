import React, { useEffect } from 'react';
import { Box } from '@mui/material';
// import { HeaderCompoment } from '../../compoment'
import { HistoryByLotId } from '../../screen'
// import { Player } from '@lottiefiles/react-lottie-player';
// import IconNodata from '../../lottiefiles/25943-nodata.json'
// import Imgae from '../../themes/image'
const Flash = () => {


  useEffect(() => {


  });

  return (
    <Box component="main" sx={{ flexGrow: 1 }}>
      <HistoryByLotId iconLeft={false} txtHeadder='Flash' />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {/* <Paper elevation={16} sx={{ mb: 1, borderRadius: 2, p: 1 }}>
        <div className="card-userinfo">
          <div className="avatar" style={{ backgroundImage: "url(" + Imgae.logoapp + ")" }} />
          <div className="infos">
            <h4>Liux prew</h4>
            <p><a className="link-red" href="profile.html">จัดการโปรไฟล์</a></p>
          </div>
          <div className="score">

            1,257 Pt
          </div>
        </div>
      </Paper> */}
      {/* <div>
        <Player
          src={IconNodata}
          className="player"
          loop
          autoplay
        />
      </div> */}
    </Box>

  )
}
export default Flash
