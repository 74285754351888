import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { HeaderCompoment } from '../../compoment'
import {  LotoScreen } from '../../screen'
import './style.css';

export default function BuyLoto() {
  useEffect(() => {


  });
  return (
    <Box component="main" sx={{ flexGrow: 1 }}>
      <HeaderCompoment iconRige={true} runCart={true} textHeader='myPurchase' />
      <br />
      <br />
      <br />
      <br />
      <Box sx={{m:2}}>
      <LotoScreen hien={false} />
      </Box>
      <br />
      <br />
      <br />
      <br />
      
    </Box >
  )
}

