import React, { useState, useEffect, useRef } from "react";
import { TextinputComponent, DialogCompoment } from "../../compoment";
import img from "../../themes/image";
import { useTranslation } from "react-i18next";
import { Button, MenuItem, TextField, Typography } from "@mui/material";
import { useValue } from "../../context/ContextProvider";
import { requestBodyUpdateAccount } from "../../body/bodyRequest";
import { requestUpdateAccount } from "../../actions/ResetClient";
import { useNavigate } from "react-router-dom";
import { GetCustomerInfoByMsisdnName } from "../../actions/ResetClient";
import { iconBank } from "../BankList/BankInfo";
import Color from "../../themes/Colors";
import { WARNING, SUCCESS } from "../../utils/Constant";
import Swal from "sweetalert2";
export default function Profile() {
  const { t } = useTranslation();
  const {
    state: { currentUser, tokenInfo, dataCustomerInfoByMsisdnName },
    dispatch,
  } = useValue();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [cusNameError, setcusNameError] = useState(null);
  const [cusName, setcusName] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [cusSurnameError, setcusSurnameError] = useState(null);
  const [cusSurname, setcusSurname] = useState("");

  const [emailError, setemailError] = useState(null);
  const [email, setemail] = useState("");

  const [agentInviteError, setagentInviteError] = useState(null);
  const [agentInvite, setagentInvite] = useState("");

  const [bankAccError, setbankAccError] = useState(null);
  const [bankAcc, setbankAcc] = useState("");

  // const [bankNameError, setbankNameError] = useState(null);
  const [bankName, setbankName] = useState("");

  const [checkStatusUpdaetAgentCode, setCheckStatusUpdaetAgentCode] =
    useState(false);

  // const [usernameError, setusernameError] = useState(null);
  // const [username, setusername] = useState('');

  // const [passwordError, setpasswordError] = useState(null);
  // const [password, setpassword] = useState('');

  const [msisdnError, setmsisdnError] = useState(null);
  const [msisdn, setmsisdn] = useState("");

  const [gender, setgender] = useState("");
  // const [cusTypeId, setcusTypeId] = useState('');

  const onmsisdn = (event) => {
    const value = event.target.value;
    const msisdnError = !value || value.length < 3 ? t("errorUsername") : null;
    setmsisdn(value);
    setmsisdnError(msisdnError);
  };

  const oncusName = (event) => {
    const value = event.target.value;
    const cusNameError = !value || value.length < 3 ? t("errorUsername") : null;
    setcusName(value);
    setcusNameError(cusNameError);
  };

  const oncusSurname = (event) => {
    const value = event.target.value;
    const cusSurnameError =
      !value || value.length < 3 ? t("errorUsername") : null;
    setcusSurname(value);
    setcusSurnameError(cusSurnameError);
  };
  const onemail = (event) => {
    const value = event.target.value;
    const emailError = !value || value.length < 3 ? t("errorUsername") : null;
    setemail(value);
    setemailError(emailError);
  };

  const onagentInvite = (event) => {
    const value = event.target.value;
    const agentInviteError =
      !value || value.length < 3 ? t("errorUsername") : null;
    setagentInvite(value);
    setagentInviteError(agentInviteError);
  };

  const onbankAcc = (event) => {
    const value = event.target.value;
    const bankAccError = !value || value.length < 3 ? t("errorUsername") : null;
    setbankAcc(value);
    setbankAccError(bankAccError);
  };

  const onbankName = (event) => {
    const value = event.target.value;
    setbankName(value);
  };

  // const onusername = (event) => {
  //     const value = event.target.value;
  //     const usernameError =
  //         !value || value.length < 3 ? t("errorUsername") : null;
  //     setusername(value);
  //     setusernameError(usernameError);
  // }

  // const onpassword = (event) => {
  //     const value = event.target.value;
  //     const passwordError =
  //         !value || value.length < 3 ? t("errorUsername") : null;
  //     setpassword(value);
  //     setpasswordError(passwordError);
  // }

  const onGenderChange = (event) => {
    const value = event.target.value;
    setgender(value);
  };
  // const onCusTypeIdChange = (event) => {
  //     const value = event.target.value;
  //     setcusTypeId(value);
  // };

  const onUpdateAccount = (event) => {
    event.preventDefault();
    // username && password &&
    if (cusName && cusSurname && currentUser.accInfo && tokenInfo) {
      if (currentUser.accInfo) {
        const cusId = currentUser.accInfo.cusId;
        const token = tokenInfo.access_token;
        const carriedCode = currentUser.accInfo.cusId;
        const carriedName = currentUser.accInfo.username;
        const username = currentUser.accInfo.username;
        const password = currentUser.accInfo.password;
        const cusTypeId = "1";
        let dataReq = requestBodyUpdateAccount(
          cusId,
          cusName,
          cusSurname,
          gender,
          msisdn,
          email,
          agentInvite,
          cusTypeId,
          bankAcc,
          bankName,
          username,
          password,
          carriedCode,
          carriedName
        );
        //console.log('-----requestBodyUpdateAccount----:', dataReq)
        requestUpdateAccount(dataReq, token, dispatch);
      } else {
        return dispatch({
          type: "UPDATE_ALERT",
          payload: {
            open: true,
            severity: "error",
            message: t("ccInfoisNull"),
          },
        });
      }
    } else {
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: t("PleaseEnterUserNamePassword"),
        },
      });
    }
  };

  useEffect(() => {
    //console.log('---------dataCustomerInfoByMsisdnName----:', dataCustomerInfoByMsisdnName)

    if (dataCustomerInfoByMsisdnName.length === 0) {
      if (currentUser && tokenInfo) {
        if (currentUser.accInfo && tokenInfo.access_token) {
          const token = tokenInfo.access_token;
          const param = currentUser.accInfo.msisdn;
          GetCustomerInfoByMsisdnName(token, param, dispatch);
        }
      }
    } else {
      setmsisdn(dataCustomerInfoByMsisdnName[0].msisdn);
      setcusName(dataCustomerInfoByMsisdnName[0].cusName);
      setcusSurname(dataCustomerInfoByMsisdnName[0].cusSurname);
      setgender(dataCustomerInfoByMsisdnName[0].gender);
      setemail(dataCustomerInfoByMsisdnName[0].email);
      setagentInvite(dataCustomerInfoByMsisdnName[0].agentInvite);
      setbankAcc(dataCustomerInfoByMsisdnName[0].bankAcc);
      setbankName(dataCustomerInfoByMsisdnName[0].bankName);
    }

    if (currentUser.agent) {
      setCheckStatusUpdaetAgentCode(true);
    }
  }, [dataCustomerInfoByMsisdnName]);

  const onBacktoSetting = () => {
    dispatch({ type: "GET_CUSTOMER_INFO_BY_MSISDNNAME", payload: [] });
    navigate("/");
  };

  // const onRefresh = () => {
  //     if (currentUser && tokenInfo) {
  //         if (currentUser.accInfo && tokenInfo.access_token) {
  //             const token = tokenInfo.access_token
  //             const param = currentUser.accInfo.msisdn
  //             GetCustomerInfoByMsisdnName(token, param, dispatch)
  //         }
  //     }
  // }

  const handleSelectImage = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = (event) => {
    let fileTypes = ["image/jpeg", "image/png"];
    const selectedFile = event.target.files[0];
    if (selectedFile && fileTypes.includes(selectedFile.type)) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setImageUrl(reader.result);
          localStorage.setItem("profileImage", reader.result);

          Swal.fire({
            icon: SUCCESS,
            title: t("alert"),
            text: t("uploadImageSuccess"),
          });

        };
        reader.readAsDataURL(file);
      } else {
        Swal.fire({
          icon: WARNING,
          title: t("alert"),
          text: t("woningImageFiletype"),
        });
      }
    } else {
      Swal.fire({
        icon: WARNING,
        title: t("alert"),
        text: t("woningImageFiletype"),
      });
    }
  };

  return (
    <>
      <header className="header lg">
        <div className="container">
          <a className="page-icon goback t-0" onClick={() => onBacktoSetting()}>
            <span className="icons" />
          </a>
          <div className="item-center">
            <h2 className="page-title pt-1">{t("EditProfile")}</h2>
          </div>
          <div className="page-icon" />
          <a onClick={() => handleSelectImage()}>
            <div
              className="avatar in-profile"
              style={{
                backgroundImage: `url(${
                  localStorage.getItem("profileImage")
                    ? localStorage.getItem("profileImage")
                    : imageUrl
                    ? imageUrl
                    : img.ic_avatar
                }`,
              }}
            >
              <span className="edit">
                <span className="icons" />
              </span>
            </div>
          </a>
          <input
            id="image"
            name="image"
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleImageUpload}
          />
        </div>
      </header>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="section">
        <div className="form form-profile">
          <TextinputComponent
            error={msisdnError ? true : false}
            margin="normal"
            requiredStatus={true}
            fullWidth
            label={t("msisdn")}
            value={`${msisdn}`}
            id="msisdn"
            name="msisdn"
            autoComplete="msisdn"
            onChange={onmsisdn}
            autoFocus
            inputMode="numeric"
            helperText={msisdnError}
          />
          <TextinputComponent
            error={cusNameError ? true : false}
            margin="normal"
            requiredStatus={true}
            fullWidth
            label={t("cusName")}
            value={`${cusName}`}
            id="cusName"
            name="cusName"
            autoComplete="cusName"
            onChange={oncusName}
            autoFocus
            helperText={cusNameError}
          />
          <TextinputComponent
            error={cusSurnameError ? true : false}
            margin="normal"
            requiredStatus={true}
            fullWidth
            label={t("cusSurname")}
            value={`${cusSurname}`}
            id="cusSurname"
            name="cusSurname"
            autoComplete="cusSurname"
            onChange={oncusSurname}
            autoFocus
            helperText={cusSurnameError}
          />
          <TextField
            select
            label={t("Gender")}
            value={`${gender}`}
            size="small"
            fullWidth
            required
            margin="normal"
            onChange={onGenderChange}
          >
            <MenuItem value="M">{t("male")}</MenuItem>
            <MenuItem value="F">{t("female")}</MenuItem>
          </TextField>
          {/* <TextField
                        select
                        label={t("cusTypeId")}
                        value={`${cusTypeId}`}
                        size="small"
                        fullWidth
                        required
                        margin="normal"
                        onChange={onCusTypeIdChange}
                    >
                        <MenuItem value="1">{t("General")}</MenuItem>
                    </TextField> */}
          <TextinputComponent
            error={emailError ? true : false}
            margin="normal"
            required
            fullWidth
            label={t("IdLine")}
            value={`${email}`}
            id="email"
            name="email"
            autoComplete="email"
            onChange={onemail}
            autoFocus
            helperText={emailError}
          />

          {checkStatusUpdaetAgentCode ? (
            <>
              <TextinputComponent
                error={agentInviteError ? true : false}
                margin="normal"
                required
                fullWidth
                label={t("agentInvite")}
                value={`${agentInvite}`}
                id="agentInvite"
                name="agentInvite"
                autoComplete="agentInvite"
                onChange={onagentInvite}
                autoFocus
                disabled={checkStatusUpdaetAgentCode ? true : false}
                inputMode="numeric"
                helperText={agentInviteError}
              />
              <Typography sx={{ color: Color.error, fontSize: 12 }}>
                {t("woningEditAgentInvite")}
              </Typography>
            </>
          ) : null}

          <TextField
            select
            label={t("bankName")}
            value={`${bankName}`}
            size="small"
            fullWidth
            requiredStatus={true}
            margin="normal"
            onChange={onbankName}
          >
            {iconBank.map((item) => (
              <MenuItem key={item.id} value={item.name + "-" + item.fullName}>
                {item.name + "-" + item.fullName}
              </MenuItem>
            ))}
          </TextField>
          <TextinputComponent
            error={bankAccError ? true : false}
            margin="normal"
            requiredStatus={false}
            fullWidth
            label={t("bankAcc")}
            value={`${bankAcc}`}
            id="bankAcc"
            name="bankAcc"
            autoComplete="bankAcc"
            onChange={onbankAcc}
            autoFocus
            inputMode="numeric"
            helperText={bankAccError}
          />

          {/* <TextinputComponent
                        error={usernameError ? true : false}
                        margin="normal"
                        required
                        fullWidth
                        label={t("username")}
                        value={`${username}`}
                        id="username"
                        name="username"
                        autoComplete="username"
                        onChange={onusername}
                        autoFocus
                        helperText={usernameError}
                    />
                    <TextinputComponent
                        error={passwordError ? true : false}
                        margin="normal"
                        required
                        fullWidth
                        label={t("password")}
                        value={`${password}`}
                        id="password"
                        name="password"
                        autoComplete="password"
                        onChange={onpassword}
                        autoFocus
                        inputMode="numeric"
                        helperText={passwordError}
                    /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={(e) => onUpdateAccount(e)}
            // !username || !password ||  usernameError ||  passwordError ||
            disabled={
              msisdnError ||
              cusNameError ||
              !cusSurname ||
              cusSurnameError ||
              !cusName ||
              !gender ||
              !msisdn
                ? true
                : false
            }
          >
            {t("Update")}
          </Button>
        </div>
      </div>
      <DialogCompoment
        onComfirm={() => onBacktoSetting()}
        Title={t("info")}
        ContentText={t("ProfileInformationUpdatedSuccessfully")}
        Actions={t("Confirm")}
        Icon="SUCCESS"
        type="UPDATE_PROFILE"
      />
    </>
  );
}
