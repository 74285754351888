import { Box, Button, Container, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link as RouterLink } from "react-router-dom";
import { Player } from '@lottiefiles/react-lottie-player';
import page404 from '../../lottiefiles/icCommingSoon.json'
import { HeaderCompoment } from '../../compoment'
import { useTranslation } from 'react-i18next'

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <>
      <Box component="main" sx={{ width: '100%' }}>
        <HeaderCompoment icon={true} icondat={false} textHeader='ComingSoon' />
        <br />
        <br />
        <br />
        <br />
      
        <Container maxWidth="md">
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
           

            <Box sx={{ textAlign: "center" }}>
              <Player
                src={page404}
                className="player"
                loop
                autoplay
              />
            </Box>
            <Button
              to="/"
              component={RouterLink}
              startIcon={<ArrowBackIcon fontSize="small" />}
              sx={{ mt: 3 }}
              variant="contained"
            >
              {t('BackToMainPage')}
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  )
}