import React, { useEffect } from 'react';
import { Box, Paper, Alert, Typography, Avatar, ListItem, ListItemAvatar, ListItemText, IconButton } from '@mui/material';
import { HeaderCompoment } from '../../compoment'
import { useValue } from '../../context/ContextProvider'
import { useTranslation } from "react-i18next";
import { reqBodCcustomerQuestion } from '../../body/bodyRequest'
import { Send } from '@mui/icons-material';
import { deepOrange, blue, red, green, grey } from '@mui/material/colors';


import { reqCustomerQuestion, GetQuestionnaireItem, GetCustomerQuestionByCusId } from '../../actions/ResetClient'
export default function GeneralQuestions() {
    const { i18n, t } = useTranslation();
    const {
        state: { tokenInfo, questionnaireItem, currentUser, dataCustomerQuestionByCusId },
        dispatch,
    } = useValue();

    useEffect(() => {
        //console.log('questionnaireItem:', questionnaireItem)
        if (tokenInfo) {
            const token = tokenInfo.access_token
            GetQuestionnaireItem(token, dispatch);
        }
        callGetCustomerQuestionByCusId()
        //console.log('11')

    }, []);

    const callGetCustomerQuestionByCusId = () => {
        if (tokenInfo) {
            if (currentUser) {
                if (currentUser.accInfo) {
                    const param = currentUser.accInfo.cusId
                    const token = tokenInfo.access_token
                    GetCustomerQuestionByCusId(token, param, dispatch);
                }
            }
        }
    }

    const eventAnser = (row) => {
        //console.log('row:', row)
        if (currentUser && tokenInfo && row) {
            if (currentUser.accInfo) {

                const token = tokenInfo.access_token
                const cusId = currentUser.accInfo.cusId
                const cusName = currentUser.accInfo.cusName
                const msisdn = currentUser.accInfo.msisdn
                const questId = row.questId
                const questionContent = i18n.language === 'en' ? row.questionContentEn : row.questionContentLa
                const carriedCode = currentUser.accInfo.carriedCode
                const carriedName = currentUser.accInfo.carriedName
                const language = i18n.language === 'en' ? 'en' : 'lo'
                let dataReq = reqBodCcustomerQuestion(cusId, cusName, msisdn, questId, questionContent, carriedCode, carriedName, language)
                //console.log('---dataReq----:', dataReq)
                reqCustomerQuestion(token, dataReq,cusId, dispatch)

            }

        }
    }

    return (
        <Box component="main" sx={{ width: '100%' }}>
            <HeaderCompoment icon={true} icondat={false} textHeader='GeneralQuestions' />
            <br />
            <br />
            <br />
            <br />
            <Paper elevation={12} sx={{ m: 1, p: 1 }}>
                {
                    questionnaireItem ?
                        questionnaireItem.map((row) => (
                            row.questionStatus === 1 ?
                                <>
                                    <ListItem key={row.questId}
                                        alignItems="flex-start"
                                        secondaryAction={
                                            <IconButton onClick={() => eventAnser(row)} edge="end" aria-label="comments">
                                                <Send color={grey[500]} />
                                            </IconButton>
                                        }>
                                        <ListItemAvatar>
                                            <Avatar sx={{ bgcolor: deepOrange[500] }}>Q</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={t('Question')}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        color={grey[500]}
                                                    >
                                                        {i18n.language === 'en' ? row.questionContentEn : row.questionContentLa}
                                                    </Typography>

                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                </>
                                : null

                        ))
                        : null
                }
            </Paper>

            {
                dataCustomerQuestionByCusId ?
                    <Paper elevation={12} sx={{ m: 1, p: 1 }}>
                        <Typography>{t('QuestionAndAnswer')}</Typography>
                        {
                            dataCustomerQuestionByCusId.map((item) => (
                                item.status === 1 ?
                                    <>
                                        <ListItem key={item.id}
                                            alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar sx={{ bgcolor: blue[500] }}>A</Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={t('QuestionAnswer')}
                                                secondary={
                                                    <>
                                                        <Typography color={red[500]}>
                                                            {item.questionContent}
                                                        </Typography>
                                                        <Typography color={green[500]}>
                                                            {item.answerContent}
                                                        </Typography>
                                                    </>
                                                }
                                            />
                                        </ListItem>
                                    </>
                                    : null
                            ))
                        }
                    </Paper> : null
            }
        </Box>
    )
}

