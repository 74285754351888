import React, { useEffect, useState } from 'react';
import { Sidebar } from '../../screen'
import { HeaderCompoment } from '../../compoment'

const NavBar = () => {
    const [addCart, setAddCart] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        const addCart = JSON.parse(localStorage.getItem('addCart'))
        if (addCart) {
            setAddCart(addCart)
        }
       
    }, []);
    return (
        <>
            <HeaderCompoment iconNav={true} numCart={addCart} logoHeader={false} onOpenLeft={()=>setIsOpen(true)} />
            <Sidebar {...{ isOpen, setIsOpen }} />
        </>
    );
};

export default NavBar;
