const colors ={
    error: 'rgba(200, 0, 0, 0.8)',
    fire: '#e73536',
    Green:'#10B981',
    DarkGreen:'#006400',
    Paramiry:'#3399ff',
    Magenta:'#FF00FF',
    White:'#FFF',
    DimGray:'#4B5563',
    Gainsboro:'#f0f0f0',

}

export default colors