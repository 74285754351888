import React, { useState, useEffect } from "react";
import { Box, Paper, Button, MenuItem, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  HeaderCompoment,
  TextinputComponent,
  DialogCompoment,
  OutlinedInputPass,
} from "../../compoment";
import { useValue } from "../../context/ContextProvider";
import { useNavigate, useLocation } from "react-router-dom";
import { requestRegisterAccount } from "../../actions/ResetClient";
import { iconBank } from "../BankList/BankInfo";
// import { requestBodyRegiterAccount } from '../../body/bodyRequest'

export default function RegisterAccount() {
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const {
    state: {},
    dispatch,
  } = useValue();
  const navigate = useNavigate();

  const [cusNameError, setcusNameError] = useState(null);
  const [cusName, setcusName] = useState("");

  const [cusSurnameError, setcusSurnameError] = useState(null);
  const [cusSurname, setcusSurname] = useState("");

  const [emailError, setemailError] = useState(null);
  const [email, setemail] = useState("");

  const [agentInviteError, setagentInviteError] = useState(null);
  const [agentInvite, setagentInvite] = useState("");

  const [bankAccError, setbankAccError] = useState(null);
  const [bankAcc, setbankAcc] = useState("");

  // const [bankNameError, setbankNameError] = useState(null);
  const [bankName, setbankName] = useState("");

  const [usernameError, setusernameError] = useState(null);
  const [username, setusername] = useState("");

  const [passwordError, setpasswordError] = useState(null);
  const [password, setpassword] = useState("");

  const [msisdnError, setmsisdnError] = useState(null);
  const [msisdn, setmsisdn] = useState("");

  const [gender, setgender] = useState("");
  // const [cusTypeId, setcusTypeId] = useState(1);

  const [agentId, setagentId] = useState("");
  const [agentState, setagentState] = useState("");
  const [parentId, setparentId] = useState("");
  // cusId
  //   const [lang, setLang] = useState("");

  useEffect(() => {
    setagentId(params.get("agentId"));
    onAgentId(params.get("agentId"));
    setagentState(params.get("agentState"));
    setparentId(params.get("cusId"));
    onChangeLanguage(params.get("lang") || "en");
  
  }, []);
  const onChangeLanguage = (e) => {
    if (e === "en") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("en");
    }
  };

  const onmsisdn = (event) => {
    const value = event.target.value;
    const msisdnError = !value || value.length < 3 ? t("errorUsername") : null;
    setmsisdn(value);
    setmsisdnError(msisdnError);
  };

  const oncusName = (event) => {
    const value = event.target.value;
    const cusNameError = !value || value.length < 3 ? t("errorUsername") : null;
    setcusName(value);
    setcusNameError(cusNameError);
  };

  const oncusSurname = (event) => {
    const value = event.target.value;
    const cusSurnameError =
      !value || value.length < 3 ? t("errorUsername") : null;
    setcusSurname(value);
    setcusSurnameError(cusSurnameError);
  };
  const onemail = (event) => {
    const value = event.target.value;
    const emailError = !value || value.length < 3 ? t("errorUsername") : null;
    setemail(value);
    setemailError(emailError);
  };

  const onagentInvite = (event) => {
    const value = event.target.value;
    // const agentInviteError =
    //     !value || value.length < 0 ? t("errorUsername") : null;
    setagentInvite(value);
    // setagentInviteError(agentInviteError);
  };

  const onAgentId = (data) => {
    setagentInvite(data);
  };

  const onbankAcc = (event) => {
    const value = event.target.value;
    const bankAccError = !value || value.length < 3 ? t("errorUsername") : null;
    setbankAcc(value);
    setbankAccError(bankAccError);
  };
  // const onbankName = (event) => {
  //     const value = event.target.value;
  //     const bankNameError =
  //         !value || value.length < 3 ? t("errorUsername") : null;
  //     setbankName(value);
  //     setbankNameError(bankNameError);
  // }

  const onusername = (event) => {
    const value = event.target.value;
    const usernameError =
      !value || value.length < 3 ? t("errorUsername") : null;
    setusername(value);
    setusernameError(usernameError);
  };

  const onpassword = (event) => {
    const value = event.target.value;
    const passwordError =
      !value || value.length < 3 ? t("errorUsername") : null;
    setpassword(value);
    setpasswordError(passwordError);
  };

  const onGenderChange = (event) => {
    const value = event.target.value;
    setgender(value);
  };
  // const onCusTypeIdChange = (event) => {
  //     const value = event.target.value;
  //     setcusTypeId(value);
  // };

  const onbankName = (event) => {
    const value = event.target.value;
    setbankName(value);
  };

  const onRegiterAccount = (event) => {
    event.preventDefault();
    if (username && password && msisdn && gender) {
      const carriedCode = msisdn;
      const carriedName = cusName;
      const cusTypeId = "1";
      const inviteStatus = agentState ? agentState : null;
      // let data = requestBodyRegiterAccount(cusName, cusSurname, gender, msisdn, email, agentInvite, cusTypeId, bankAcc, bankName, username, password, carriedCode, carriedName, inviteStatus, parentId)
      // console.log('requestBodyRegiterAccount:', data)
      requestRegisterAccount(
        cusName,
        cusSurname,
        gender,
        msisdn,
        email,
        agentInvite,
        cusTypeId,
        bankAcc,
        bankName,
        username,
        password,
        carriedCode,
        carriedName,
        inviteStatus,
        parentId,
        dispatch
      );
    } else {
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: t("PleaseEnterUserNamePassword"),
        },
      });
    }
  };
  return (
    <Box component="main" sx={{ flexGrow: 1 }}>
      <HeaderCompoment
        icon={true}
        iconRige={false}
        textHeader="RegisterAccount"
      />
      <br />
      <br />
      <br />
      <Box sx={{ p: 2, overflow: "scroll", height: "100%", width: "100%" }}>
        <Paper elevation={12} sx={{ p: 1 }}>
          <TextinputComponent
            error={msisdnError ? true : false}
            margin="normal"
            requiredStatus={true}
            fullWidth
            label={t("msisdn")}
            value={`${msisdn}`}
            id="msisdn"
            name="msisdn"
            autoComplete="msisdn"
            onChange={onmsisdn}
            autoFocus
            inputMode="numeric"
            helperText={msisdnError}
            numLength={20}
          />
          <TextinputComponent
            error={cusNameError ? true : false}
            margin="normal"
            requiredStatus={true}
            fullWidth
            label={t("cusName")}
            value={`${cusName}`}
            id="cusName"
            name="cusName"
            autoComplete="cusName"
            onChange={oncusName}
            autoFocus
            helperText={cusNameError}
            numLength={30}
          />
          <TextinputComponent
            error={cusSurnameError ? true : false}
            margin="normal"
            requiredStatus={true}
            fullWidth
            label={t("cusSurname")}
            value={`${cusSurname}`}
            id="cusSurname"
            name="cusSurname"
            autoComplete="cusSurname"
            onChange={oncusSurname}
            autoFocus
            helperText={cusSurnameError}
            numLength={30}
          />
          <TextField
            select
            label={t("Gender")}
            value={`${gender}`}
            size="small"
            fullWidth
            required
            margin="normal"
            onChange={onGenderChange}
            numLength={10}
          >
            <MenuItem value="M">{t("male")}</MenuItem>
            <MenuItem value="F">{t("female")}</MenuItem>
          </TextField>

          {/* <TextField
                        select
                        label={t("cusTypeId")}
                        value={`${cusTypeId}`}
                        size="small"
                        fullWidth
                        required
                        margin="normal"
                        onChange={onCusTypeIdChange}
                    >
                        <MenuItem value="1">{t("General")}</MenuItem>
                    </TextField> */}

          <TextinputComponent
            error={emailError ? true : false}
            margin="normal"
            requiredStatus={false}
            fullWidth
            label={t("IdLine")}
            value={`${email}`}
            id="email"
            name="email"
            autoComplete="email"
            onChange={onemail}
            numLength={30}
            helperText={emailError}
          />
          <TextinputComponent
            error={agentInviteError ? true : false}
            margin="normal"
            requiredStatus={false}
            fullWidth
            label={t("agentInvite")}
            value={agentInvite}
            id="agentInvite"
            name="agentInvite"
            autoComplete="agentInvite"
            onChange={agentId ? onAgentId : onagentInvite}
            autoFocus
            disabled={agentId ? true : false}
            inputMode="numeric"
            numLength={15}
            helperText={agentInviteError}
          />
          <TextField
            select
            label={t("bankName")}
            value={`${bankName}`}
            size="small"
            fullWidth
            requiredStatus={false}
            margin="normal"
            onChange={onbankName}
          >
            {iconBank.map((item) => (
              <MenuItem key={item.id} value={item.name + "-" + item.fullName}>
                {item.name + "-" + item.fullName}
              </MenuItem>
            ))}
          </TextField>

          <TextinputComponent
            error={bankAccError ? true : false}
            margin="normal"
            requiredStatus={false}
            fullWidth
            label={t("bankAcc")}
            value={`${bankAcc}`}
            id="bankAcc"
            name="bankAcc"
            numLength={30}
            autoComplete="bankAcc"
            onChange={onbankAcc}
            autoFocus
            inputMode="numeric"
            helperText={bankAccError}
          />
          {/* <TextinputComponent
                        error={bankNameError ? true : false}
                        margin="normal"
                        required
                        fullWidth
                        label={t("bankName")}
                        value={`${bankName}`}
                        id="bankName"
                        name="bankName"
                        numLength={30}
                        autoComplete="bankName"
                        onChange={onbankName}
                        autoFocus
                        helperText={bankNameError}
                    /> */}

          <TextinputComponent
            error={usernameError ? true : false}
            margin="normal"
            requiredStatus={true}
            fullWidth
            label={t("txtUserName")}
            value={`${username}`}
            id="username"
            name="username"
            numLength={20}
            autoComplete="username"
            onChange={onusername}
            autoFocus
            helperText={usernameError}
          />
          {/* <TextinputComponent
                        error={passwordError ? true : false}
                        margin="normal"
                        required
                        fullWidth
                        numLength={8}
                        label={t("password")}
                        value={`${password}`}
                        id="password"
                        name="password"
                        autoComplete="password"
                        onChange={onpassword}
                        autoFocus
                        helperText={passwordError}
                    /> */}

          <OutlinedInputPass
            label={t("txtPassword")}
            value={`${password}`}
            name="password"
            error={passwordError ? true : false}
            onChange={onpassword}
            autoComplete="current-password"
            numLength={10}
            inputMode="numeric"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 1 }}
            onClick={(e) => onRegiterAccount(e)}
            disabled={
              usernameError ||
              passwordError ||
              msisdnError ||
              !gender ||
              !username ||
              !password ||
              !msisdn ||
              !cusName ||
              cusNameError ||
              !cusSurname ||
              cusSurnameError
                ? true
                : false
            }
          >
            {t("Regiter")}
          </Button>
        </Paper>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Box>

      <DialogCompoment
        onComfirm={() => navigate("/")}
        Title={t("info")}
        ContentText={t("NewAccountRegistrationCompleted")}
        Actions={t("Confirm")}
        Icon="SUCCESS"
      />
    </Box>
  );
}
