import { useState, useEffect } from "react";
import {
  Typography,
  Stack,
  Box,
  Grid,
  Paper,
  styled,
  List,
  ListItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Link,
} from "@mui/material";
import img from "../../themes/image";
import {
  ArrowForwardIos,
  PriceChangeOutlined,
  History,
  TaskOutlined,
  Password,
  TranslateOutlined,
  HelpOutlineOutlined,
  CreditCardOutlined,
  Ballot,
  FaceRetouchingNatural,
  Diversity3,
  MonetizationOn,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useValue } from "../../context/ContextProvider";
import { DrawerBottom } from "../../screen";
import {
  GetDataCusDashboard,
  GetCustomerPoint,
} from "../../actions/ResetClient";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
  borderRadius: 10,
});
export default function Account() {
  const { t } = useTranslation();
  const {
    state: { currentUser, dataGetDashboard, tokenInfo, dataCustomerPoint },
    dispatch,
  } = useValue();
  const navigate = useNavigate();
  const [cusName, setcusName] = useState("");
  const [cusSurname, setcusSurname] = useState("");
  const [statusPopup, setStatusPopup] = useState("");
  const [customerStatusType, setCustomerStatusType] = useState(null);

  const [agentState, setagentState] = useState(null);
  const [agentId, setagentId] = useState(null);
  const [cusId, setcusId] = useState(null);

  useEffect(() => {
    dispatch({ type: "START_LOADING" });
    setTimeout(() => {
      dispatch({ type: "END_LOADING" });
    }, 1000);

    if (currentUser) {
      setagentId(currentUser.agentId);
      setagentState(currentUser.agentState);
      setCustomerStatusType(currentUser.customerStatusType);

      if (currentUser.accInfo) {
        // console.log("currentUser.accInfo.cusId:", currentUser.accInfo)
        setcusId(currentUser.accInfo.cusId);
        setcusName(currentUser.accInfo.cusName);
        setcusSurname(currentUser.accInfo.cusSurname);
      }
      if (currentUser.lotAvaiable) {
        if (currentUser.lotAvaiable.length > 0) {
          if (dataGetDashboard.length === 0) {
            const token = tokenInfo.access_token;
            const lotiId = currentUser.lotAvaiable[0].lotId;
            const cusId = currentUser.accInfo.cusId;
            GetDataCusDashboard(token, lotiId, cusId, dispatch);
          }
        }
      }
      // console.log("dataCustomerPoint:", dataCustomerPoint)
      if (!dataCustomerPoint) {
        // console.log("2222")
        const token = tokenInfo.access_token;
        const param = currentUser.accInfo.cusId;
        GetCustomerPoint(token, param, dispatch);
      }
    }
  }, []);

  const ListBox = [
    {
      id: 1,
      name: "buyTinstallment",
      Number:
        dataGetDashboard.length > 0 ? dataGetDashboard[0].totalCurrent : 0,
      bgColor: "#FFF0CD",
      txtColor: "#FFB432",
    },
    {
      id: 2,
      name: "buyAll",
      Number: dataGetDashboard.length > 0 ? dataGetDashboard[0].total : 0,
      bgColor: "#FFE8E8",
      txtColor: "#d90404",
    },
    {
      id: 3,
      name: "won",
      Number: dataGetDashboard.length > 0 ? dataGetDashboard[0].totalWinner : 0,
      bgColor: "rgba(200, 241, 207, 0.3)",
      txtColor: "#00cf21",
    },
  ];
  const ListMenu1 = [
    // {
    //     id: 7,
    //     MenuName: 'SalesSummary',
    //     icon: <MonetizationOn sx={{ color: '#d90404' }} />,
    //     link: 'SalesSummary'
    // },
    // {
    //     id: 6,
    //     MenuName: 'inviteFriend',
    //     icon: <Diversity3 sx={{ color: '#FFD700' }} />,
    //     link: 'InviteFriend'
    // },

    {
      id: 2,
      MenuName: "myPurchase",
      icon: <PriceChangeOutlined />,
      link: "BuyLoto",
    },
    {
      id: 1,
      MenuName: "myLottery",
      icon: <History />,
      link: "MylotoHistory",
    },
    {
      id: 3,
      MenuName: "WinningHistory",
      icon: <TaskOutlined />,
      link: "HistoryWinner",
    },
    {
      id: 4,
      MenuName: "ChangePassword",
      icon: <Password />,
      link: "ChangePassword",
    },
    {
      id: 5,
      MenuName: "UpdateCodeAgent",
      icon: <FaceRetouchingNatural />,
      link: "UpdateCodeAgent",
    },
  ];

  const ListMenu2 = [
    {
      id: 1,
      MenuName: "bankAccount",
      link: "BankAccount",
      icon: <CreditCardOutlined />,
    },
    {
      id: 2,
      MenuName: "helpCenter",
      link: "Help",
      icon: <HelpOutlineOutlined />,
    },
    {
      id: 3,
      MenuName: "changeLanguage",
      link: "changeLanguage",
      icon: <TranslateOutlined />,
    },
    // {
    //     id: 4,
    //     MenuName: 'RefundPolicy',
    //     link: 'RefundPolicy',
    //     icon: <PolicyOutlined />
    // },
    {
      id: 4,
      MenuName: "GeneralQuestions",
      link: "GeneralQuestions",
      icon: <Ballot />,
    },
  ];

  const ListMenu3 = [
    // {
    //     id: 1,
    //     MenuName: 'TermsConditions',
    //     link: 'Consection',
    //     icon: <InfoOutlined />
    // },
    // {
    //     id: 2,
    //     MenuName: 'PrivacyPolicy',
    //     link: 'Policy',
    //     icon: <PolicyOutlined />
    // },
    // {
    //     id: 4,
    //     MenuName: 'GeneralQuestions',
    //     link: 'GeneralQuestions',
    //     icon: <Ballot />
    // },
    // {
    //     id: 3,
    //     MenuName: 'aboutUs',
    //     link: 'About',
    //     icon: <Engineering />
    // }
  ];

  const onEvenNavigate = (item) => {
    console.log("item:", item);
    if (item === "changeLanguage") {
      setStatusPopup("LANGE");
      dispatch({ type: "OPEN_CART_DIALOG", payload: null });
    } else {
      navigate(item);
    }
  };

  const onGetEventClick = (value) => {
    if (value === "BuyLoto") {
      dispatch({ type: "UPDATE_SECTION", payload: 1 });
    } else if (value === "InviteFriend") {
      setStatusPopup("INVITE");
      dispatch({ type: "OPEN_CART_DIALOG", payload: null });
    } else {
      navigate(value);
    }
  };

  return (
    <Stack spacing={1} sx={{ p: 2 }}>
      <div className="card-userinfo">
        <div
          className="avatar"
          style={{
            backgroundImage: `url(${
              localStorage.getItem("profileImage")
                ? localStorage.getItem("profileImage")
                : img.ic_avatar
            })`,
          }}
        />
        <div className="infos">
          <h4>{cusName && cusSurname ? cusName + " " + cusSurname : null}</h4>
          <Link>{customerStatusType ? customerStatusType : t("customer")}</Link>
          <p>
            <a onClick={() => navigate("/Profile")} className="link-red">
              {t("manageProfile")}
            </a>
          </p>
        </div>
        <div className="score">
          <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
            {dataCustomerPoint ? dataCustomerPoint : 0}:{t("txtPoint")}
          </Typography>
        </div>
      </div>
      <Stack direction="row">
        <Grid container>
          {ListBox.map((item) => (
            <>
              <Grid
                key={item.id}
                item
                xs={3.8}
                sx={{
                  backgroundColor: item.bgColor,
                  padding: 2,
                  display: "flex",
                  borderRadius: 2,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Stack direction="column" alignItems="center">
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: 14,
                        color: item.txtColor,
                      }}
                    >
                      {t(item.name)}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: 14,
                        color: item.txtColor,
                      }}
                    >
                      {item.Number}
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={0.2} />
            </>
          ))}
        </Grid>
      </Stack>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Paper elevation={20} sx={{ mb: 2, borderRadius: 2 }}>
          <List>
            {ListMenu1.map((index) => (
              <ListItem key={index.id} disablePadding sx={{ display: "block" }}>
                <ListItemButton onClick={() => onGetEventClick(index.link)}>
                  <ListItem
                    secondaryAction={
                      <IconButton edge="end" aria-label="delete">
                        <ArrowForwardIos
                          sx={{ width: 12, height: 12, color: "#333" }}
                        />
                      </IconButton>
                    }
                  >
                    <ListItemIcon>{index.icon}</ListItemIcon>
                    <ListItemText
                      primary={t(index.MenuName)}
                      secondary={null}
                    />
                  </ListItem>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Paper>

        <Paper elevation={20} sx={{ mb: 2, borderRadius: 2 }}>
          <List>
            {ListMenu2.map((index) => (
              <ListItem key={index.id} disablePadding sx={{ display: "block" }}>
                <ListItemButton onClick={() => onEvenNavigate(index.link)}>
                  <ListItem
                    secondaryAction={
                      <IconButton edge="end" aria-label="delete">
                        <ArrowForwardIos
                          sx={{ width: 12, height: 12, color: "#333" }}
                        />
                      </IconButton>
                    }
                  >
                    <ListItemIcon>{index.icon}</ListItemIcon>
                    <ListItemText
                      primary={t(index.MenuName)}
                      secondary={null}
                    />
                  </ListItem>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Paper>
        {ListMenu3.length > 0 ? (
          <Paper elevation={20} sx={{ mb: 2, borderRadius: 2 }}>
            <List>
              {ListMenu3.map((index) => (
                <ListItem
                  key={index.id}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton onClick={() => navigate(index.link)}>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete">
                          <ArrowForwardIos
                            sx={{ width: 12, height: 12, color: "#333" }}
                          />
                        </IconButton>
                      }
                    >
                      <ListItemIcon>{index.icon}</ListItemIcon>
                      <ListItemText
                        primary={t(index.MenuName)}
                        secondary={null}
                      />
                    </ListItem>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Paper>
        ) : null}
        <Box sx={{ marginBottom: 10 }}>
          <Img alt="Banner discount" src={img.bannerDiscount} />
        </Box>
      </Box>
      <DrawerBottom
        tyleBar={statusPopup}
        agentId={agentId}
        agentState={agentState}
        cusId={cusId}
      />
    </Stack>
  );
}
