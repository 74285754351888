import React, { useMemo, useState, useEffect } from 'react';
import { Drawer, Box, ListItem, ListItemButton, ListItemAvatar, Avatar, ListItemText, List, IconButton, ButtonGroup, Button, Chip, Alert, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider, MobileDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { useValue } from '../../context/ContextProvider'
import dayjs from 'dayjs';
import moment from 'moment';
import { useTranslation } from 'react-i18next'
import Image from '../../themes/image'
import { CheckCircle, ContentCopy } from '@mui/icons-material';

import { green, grey } from '@mui/material/colors';

import {
  EmailShareButton,
  FacebookShareButton,
  LineShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LineIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookMessengerShareButton
} from "react-share";

// import { DateRange } from '@mui/x-date-pickers-pro';

DrawerBottom.propTypes = {
  tyleBar: PropTypes.string,
  onComfirm: PropTypes.func,
  valuesData: PropTypes.func,
  agentState: PropTypes.string,
  agentId: PropTypes.string,
  cusId: PropTypes.string,
}

var date = new Date();
date.setDate(date.getDate() - 30);
let fromDate = date.toISOString().split('T')[0];
let toDate = moment(new Date()).format("YYYY-MM-DD")
const urlInvite = process.env.REACT_APP_INVITE_LINK;

export default function DrawerBottom({ tyleBar, onComfirm, valuesData, agentId, agentState, cusId }) {
  const { t, i18n } = useTranslation();
  const {
    state: { openDialogCart, dataAddCart },
    dispatch,
  } = useValue();

  const urlLink = `${urlInvite}/RegisterAccount?agentId=${agentId}&agentState=${agentState}&cusId=${cusId}&lang=en`;
  const titleTxt = ' '
  const ListLangue = useMemo(
    () => [
      {
        id: 1,
        name: 'ລາວ',
        imgae: Image.ic_laosLang,
        key: 'lo'
      },
      {
        id: 2,
        name: 'ไทย',
        imgae: Image.ic_englishLang,
        key: 'en'
      }
    ], [])

  const [value, setValue] = useState([
    dayjs(fromDate),
    dayjs(toDate),
  ]);

  const [startDate, setStartDate] = useState(null)
  const [EndDate, setEndDate] = useState(null)

  const [counter, setCounter] = useState(1)

  const [isCopied, setIsCopied] = useState(false);




  const closeDrawer = () => {
    dispatch({ type: 'CLOSE_CART_DIALOG', payload: null });
  }

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }


  const handleCopyClick = () => {

    copyTextToClipboard(urlLink)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    //console.log('-----startDate----:', startDate)
    //console.log('-----EndDate----:', EndDate)
  })
  const onSelectDate = (event) => {
    if (event) {
      if (event[0] && event[1]) {
        setStartDate(moment(event[0].$d).format("YYYY-MM-DD"))
        setEndDate(moment(event[1].$d).format("YYYY-MM-DD"))
      }
    }
  }

  const onEventChaengLang = () => {
    if (i18n.language === 'en') {
      i18n.changeLanguage('lo');
    } else {
      i18n.changeLanguage('en');
    }
  }

  const onAddtoCart = () => {
    onComfirm(dataAddCart)
  }

  const handleIncrement = () => {
    setCounter(counter + 1)
  }
  const handleDecrement = () => {
    setCounter(counter - 1)
  };


  const onComfirmDate = () => {
    if (startDate && EndDate) {
      valuesData(startDate, EndDate)
      dispatch({ type: 'CLOSE_CART_DIALOG', payload: null });
    } else {
      //console.log('-----:', value)
      if (value) {
        if (value[0] && value[1]) {
          let s = moment(value[0].$d).format("YYYY-MM-DD")
          let e = moment(value[1].$d).format("YYYY-MM-DD")
          valuesData(s, e)
          dispatch({ type: 'CLOSE_CART_DIALOG', payload: null });
          //setStartDate(moment(value[0].$d).format("YYYY-MM-DD"))
          //setEndDate(moment(value[1].$d).format("YYYY-MM-DD"))
        }
      }
    }

  }

  return (
    <div>
      <Drawer
        anchor={"bottom"}
        open={openDialogCart}
        onClose={closeDrawer}
        PaperProps={{
          style: {
            overflow: "hidden"
          }
        }}
      >
        {
          tyleBar === 'DATA_HISTOLY' ? <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 3,
            flexDirection: 'column'
          }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['MobileDateRangePicker']}>
                <DemoItem label={t('selectDateSearch')} component="MobileDateRangePicker">
                  <MobileDateRangePicker
                    value={value}
                    onChange={(newValue) => onSelectDate(newValue)}
                    format="YYYY-MM-DD"
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>

            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => onComfirmDate()}
              disabled={!value ? true : false}
            >
              {t('Search')}
            </Button>
            {/* <a onClick={() => onComfirmDate()} className="btn btn-yellow h-48 w-100">{t('Search')}</a> */}


          </Box>
            : tyleBar === 'ADD_CART' ?
              <div className="offcanvas-body">
                <h5 style={{ textAlign: 'center', fontSize: 16 }}>
                  {t('YouƯanToAddToCart', { lottoNumber: dataAddCart ? dataAddCart.lottoNumber : null })}
                </h5>
                <p></p>
                <div className="myLottery-total">
                  <p>{t('Unit')}</p>
                  <ButtonGroup size="small" aria-label="small outlined button group">
                    <Button disabled onClick={() => handleIncrement()}>+</Button>
                    <Button disabled>{counter}</Button>
                    <Button disabled onClick={() => handleDecrement()}>-</Button>
                  </ButtonGroup>
                  <p className="fs-16">{t('item')}</p>
                </div>
                <a onClick={() => onAddtoCart()} className="btn btn-yellow h-48 w-100"><p>{t('comfirm')}</p></a>
              </div>
              : tyleBar === 'LANGE' ?
                <div style={{ padding: 10 }}>
                  <h6 style={{ textAlign: 'center', fontSize: 16 }}>{t('selectLanguage')}</h6>
                  <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {ListLangue.map((value) => {
                      const labelId = `checkbox-list-secondary-label-${value}`;
                      return (
                        <ListItem
                          key={value}
                          secondaryAction={
                            <IconButton edge="end" aria-label="comments" onClick={() => onEventChaengLang()}>
                              {
                                i18n.language === value.key ? <CheckCircle sx={{ color: green[500] }} /> : <CheckCircle sx={{ color: grey[500] }} />
                              }

                            </IconButton>
                          }
                          disablePadding
                        >
                          <ListItemButton onClick={() => onEventChaengLang()}>
                            <ListItemAvatar>
                              <Avatar
                                alt={`Avatar n°${value.name}`}
                                src={value.imgae}
                              />
                            </ListItemAvatar>
                            <ListItemText id={labelId} primary={value.name} />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
                : tyleBar === 'INVITE' ?
                  <div style={{ padding: 10 }}>
                    <h5 style={{ textAlign: 'center', fontSize: 16 }}>{t('inviteFriend')}</h5>
                    <div style={{ textAlign: 'center', padding: 15, alignContent: 'center' }}>

                      <Chip
                        label={urlLink}
                        variant="outlined"
                        onClick={handleCopyClick}
                        icon={<ContentCopy />}
                      />
                      {isCopied ? <Chip label={t('success')} color="success" variant="outlined" /> : null}

                    </div>

                    <div style={{ textAlign: 'center', padding: 5 }}>
                      <FacebookShareButton
                        url={urlLink}
                        quote={t('linkinviteFriend')}
                        hashtag={titleTxt}>
                        <FacebookIcon size={40} round={true} />
                      </FacebookShareButton>

                      <EmailShareButton
                        url={urlLink}
                        quote={t('linkinviteFriend')}
                        hashtag={titleTxt}>
                        <EmailIcon size={40} round={true} />
                      </EmailShareButton>

                      <TwitterShareButton
                        url={urlLink}
                        title={t('linkinviteFriend')}
                        hashtag={titleTxt}>
                        <TwitterIcon size={40} round={true} />
                      </TwitterShareButton>

                      <LineShareButton
                        url={urlLink}
                        title={t('linkinviteFriend')}
                        hashtag={titleTxt}>
                        <LineIcon size={40} round={true} />
                      </LineShareButton>
                      <TelegramShareButton
                        url={urlLink}
                        title={t('linkinviteFriend')}
                        hashtag={titleTxt}>
                        <TelegramIcon size={40} round={true} />
                      </TelegramShareButton>
                      <WhatsappShareButton
                        url={urlLink}
                        title={t('linkinviteFriend')}
                        separator={titleTxt}>
                        <WhatsappIcon size={40} round={true} />
                      </WhatsappShareButton>

                      <FacebookMessengerShareButton
                        redirectUri={urlLink}
                        appId='bopby.k8cntt'
                        to={t('linkinviteFriend')}>
                        <FacebookMessengerIcon size={40} round={true} />
                      </FacebookMessengerShareButton>




                    </div>

                  </div>
                  : null
        }

      </Drawer>
    </div>
  )
}
