import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Stack, Grid } from '@mui/material';
import { HeaderCompoment } from '../../compoment'
import './style.css';
import { useTranslation } from 'react-i18next'
import { useValue } from '../../context/ContextProvider'
import { formatNumber } from "../../utils/Formater";

export default function DetailHistoryWinner() {
    const { t } = useTranslation();
    const [list, setList] = useState(2);
    const [values, setvalues] = useState(null);


    const {
        state: { detailListWinner, currentUser }
    } = useValue();


    const data = [
        // {
        //     key: 'รางวัลที่ 1 (208335)',
        //     value: '฿6,000,000',
        //     txtColor: '#000',
        //     fontSize: 12,
        //     fontWeight: 400,
        //     fontWeightKey: 400,
        //     fontSizeKey: 12,
        // },
        // {
        //     key: 'รางวัลเลขท้าย 3 ตัว (123456)',
        //     value: '2,000 บาท',
        //     txtColor: '#000',
        //     fontSize: 12,
        //     fontWeight: 400,
        //     fontWeightKey: 400,
        //     fontSizeKey: 12,
        // },
        // {
        //     key: 'ค่าอากรสแตมป์ (0.5%)',
        //     value: '-228,010 บาท',
        //     txtColor: '#d90404',
        //     fontSize: 12,
        //     fontWeight: 400,
        //     fontWeightKey: 400,
        //     fontSizeKey: 12,
        // },
        // {
        //     key: 'ค่าบริการ (0.1%)',
        //     value: '-2,347 บาท',
        //     txtColor: '#d90404',
        //     fontSize: 12,
        //     fontWeight: 400,
        //     fontWeightKey: 400,
        //     fontSizeKey: 12,
        // },
        {
            key: t('prizeValue'),
            value: values ? formatNumber(values[0].prizeValue) : 0,
            txtColor: '#00cf21',
            fontSize: 20,
            fontSizeKey: 12,
            fontWeight: 800,
            fontWeightKey: 400
        },
    ]
    useEffect(() => {
        if (detailListWinner && detailListWinner.bull) {
            // console.log("1111:", JSON.parse(detailListWinner.data))
            // console.log("222:", JSON.parse(detailListWinner.data[0]))
            setvalues(JSON.parse(detailListWinner.data))
        }
        //console.log("detailListWinner:", detailListWinner)

    }, []);
    return (
        <Box component="main" sx={{ flexGrow: 1 }}>
            <HeaderCompoment icon={true} icondat={false} textHeader='details' />
            <br />
            <br />
            <br />
            <br />
            {
                detailListWinner && detailListWinner.bull ?
                    <>
                        <Paper elevation={12} sx={{ padding: 2, marginBottom: 1.3 }}>
                            <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
                                <Typography sx={{ fontSize: 12, alignItems: 'flex-start' }}>
                                    {t('ReferenceNumber') + ': ' + detailListWinner.bull.split('|')[0]}
                                </Typography>
                                <Typography sx={{ fontSize: 12, color: '#00cf21', alignItems: 'flex-end' }}>
                                    {t('succec')}
                                </Typography>
                            </Stack>
                            <hr style={{ border: '0.7px dashed #DADADA', opacity: 0.5 }} />
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <th style={{ textAlign: 'start', width: '50%', fontSize: 14, fontWeight: 400 }}>{t('userBuy')}</th>
                                    <td style={{ width: '50%', textAlign: 'end', fontSize: 14, fontWeight: 400 }}>{detailListWinner.bull.split('|')[2]}</td>
                                </tr>
                                <tr>
                                    <th style={{ textAlign: 'start', width: '50%', fontSize: 12, fontWeight: 400 }}>{t('period')}</th>
                                    <td style={{ width: '50%', textAlign: 'end', fontSize: 12, fontWeight: 400 }}>{values ? values[0].lotId : 0}</td>
                                </tr>
                                <tr>
                                    <th style={{ textAlign: 'start', width: '50%', fontSize: 14, fontWeight: 400 }}>{t('BuyData')}</th>
                                    <td style={{ width: '50%', textAlign: 'end', fontSize: 14, fontWeight: 400 }}>{detailListWinner.bull.split('|')[1]}</td>
                                </tr>
                                <tr>
                                    <th style={{ textAlign: 'start', width: '50%', fontSize: 14, fontWeight: 400 }}>{t('PaymentChannel')}</th>
                                    <td style={{ width: '50%', textAlign: 'end', fontSize: 14, fontWeight: 400 }}>BCEL One</td>
                                </tr>
                            </table>
                        </Paper>
                    </> : null

            }

            <Paper elevation={12} sx={{ padding: 2, marginBottom: 1.3 }}>
                <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
                    <Typography h5 sx={{ fontSize: 14, fontWeight: 800, alignItems: 'flex-start' }}>
                        {t('item')}
                    </Typography>
                </Stack>
                <div className="lotto-lists pt-2">
                    {
                        detailListWinner && detailListWinner.bull ?
                            JSON.parse(detailListWinner.data).slice(0, list).map((row) => (

                                currentUser.viewImageLottery ? (
                                    <img
                                        src={
                                            process.env.REACT_APP_IMAGE +
                                            row.lotId +
                                            "/" +
                                            row.lottoNumber +
                                            ".png"
                                        }
                                        alt="Snow"
                                        className="img-hover-shadow"
                                        style={{ width: "100%" }}
                                    />
                                ) : (

                                    <div key={row.id} class="card-lotto">
                                        <div class="card-row row-1">
                                            <div class="set s-2">
                                                <span>{t('period')} {row.lotId}</span>
                                            </div>
                                            <div class="logo">
                                                <span class="icons"></span>
                                                <span>{t('GovernmentLottery')}</span>
                                            </div>
                                        </div>
                                        <div class="card-row row-2">
                                            <div class="number-lists textgroup">
                                                <div style={{ left: 3 }}>
                                                    <span style={{ letterSpacing: '0.5cm' }}>{row.lottoNumber}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-row row-3">
                                            <div class="textgroup">
                                                <span>{t('period')} {row.lotId}</span>
                                                <span>{t('set')} 1</span>
                                                <span class="viewer"><span class="icons"></span>{t('Watching')} 0 {t('people')}</span>
                                            </div>
                                            <span class="price"><strong>{row.totalAmount}</strong> {t('bat')}</span>
                                        </div>
                                    </div>)

                            ))
                            : null
                    }


                    {
                        detailListWinner && detailListWinner.bull ?
                            JSON.parse(detailListWinner.data).length > 2 ? list != 1000 ?
                                <div className="d-flex py-2">
                                    <button onClick={() => setList(1000)} className="btn btn-xs viewmore mx-auto">{t('SeeAll')}</button>
                                </div> : null : null
                            : null
                    }

                    {
                        list === 1000 ?
                            <div className="d-flex py-2">
                                <button onClick={() => setList(2)} className="btn btn-xs viewmore mx-auto">{t('ShowLess')}</button>
                            </div> : null
                    }



                </div>

            </Paper>
            <Paper elevation={12} sx={{ padding: 2, marginBottom: 1.3 }}>
                <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
                    <Typography h5 sx={{ fontSize: 14, fontWeight: 800, alignItems: 'flex-start' }}>
                        {t('SummaryAward')}
                    </Typography>
                </Stack>
                {detailListWinner && detailListWinner.bull ? <table style={{ width: '100%' }}>
                    {data.map((e) => (
                        <tr key={e.key}>
                            <th style={{ textAlign: 'start', width: '50%', fontSize: e.fontSizeKey, fontWeight: e.fontWeightKey }}>{e.key}</th>
                            <td style={{ width: '50%', textAlign: 'end', fontSize: e.fontSize, color: e.txtColor, fontWeight: e.fontWeight }}>{e.value}</td>
                        </tr>
                    ))
                    }
                </table> : null}

            </Paper>
        </Box>
    )
}
