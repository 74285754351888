import React, { useEffect, useState } from "react";
import {
  Box,
  styled,
  Alert,
  Grid,
  IconButton,
  Stack,
  Button,
} from "@mui/material";
import {
  HeaderCompoment,
  AertYesorNo,
  DialogCompomentFull,
} from "../../compoment";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/Formater";
import { useValue } from "../../context/ContextProvider";
import { useNavigate } from "react-router-dom";
import image from "../../themes/image";
import Color from "../../themes/Colors";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "50%",
  maxHeight: "50%",
});

export default function Cart() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: { currentUser },
    dispatch,
  } = useValue();

  const [addCart, setAddCart] = useState([]);
  const [DoubleLuck, setLotdoubleLuck] = useState("");
  const [AlertStatus, setAlertStatus] = useState(false);
  const [des, setdes] = useState("");

  useEffect(() => {
    // dispatch({ type: 'START_LOADING' });
    const addCart = JSON.parse(localStorage.getItem("addCart"));
    //console.log('----=--:', currentUser)
    if (addCart) {
      setAddCart(addCart);
      // dispatch({ type: 'END_LOADING' });
    }
    setLotdoubleLuck(localStorage.getItem("doubleLuck"));
  }, []);

  const onDelete = (row) => {
    dispatch({ type: "OPEN_ALERT_DELETE", payload: row });
  };
  const onConfrimDelete = (e) => {
    dispatch({ type: "CLOSE_ALERT_DELETE", payload: null });
    if (e === "CEAR_DOBLELUCK") {
      const reAmount = JSON.parse(localStorage.getItem("addCart"));
      if (reAmount.length > 0) {
        const newArray = [];
        for (let i = 0; i < reAmount.length; i++) {
          const item = {};
          item["id_loto"] = reAmount[i].id_loto;
          item["id"] = reAmount[i].id;
          item["lotId"] = reAmount[i].lotId;
          item["lotDate"] = reAmount[i].lotDate;
          item["numberStatus"] = reAmount[i].numberStatus;
          item["winnerStatus"] = reAmount[i].winnerStatus;
          item["lottoNumber"] = reAmount[i].lottoNumber;
          item["lottoPrice"] = 100;
          item["costPrice"] = reAmount[i].costPrice;
          item["qty"] = reAmount[i].qty;
          newArray.push(item);
        }
        localStorage.setItem("addCart", JSON.stringify(newArray));
        localStorage.setItem("doubleLuck", 0);
        Swal.fire({
          title: t("alert"),
          text: t("succec"),
          icon: "success",
          confirmButtonColor: Color.error,
          confirmButtonText: t("comfirm"),
        }).then((result) => {
          if (result.isConfirmed) {
            setLotdoubleLuck(0)
            setAddCart(newArray);
            // window.location.href = "../Cart";
          }
        });
      }
    } else if (e === "JION_DOBLELUCK") {
      const reAmount = JSON.parse(localStorage.getItem("addCart"));
      if (reAmount.length > 0) {
        const newArray = [];
        for (let i = 0; i < reAmount.length; i++) {
          const item = {};
          item["id_loto"] = reAmount[i].id_loto;
          item["id"] = reAmount[i].id;
          item["lotId"] = reAmount[i].lotId;
          item["lotDate"] = reAmount[i].lotDate;
          item["numberStatus"] = reAmount[i].numberStatus;
          item["winnerStatus"] = reAmount[i].winnerStatus;
          item["lottoNumber"] = reAmount[i].lottoNumber;
          item["lottoPrice"] = 120;
          item["costPrice"] = reAmount[i].costPrice;
          item["qty"] = reAmount[i].qty;
          newArray.push(item);
        }
        localStorage.setItem("addCart", JSON.stringify(newArray));
        localStorage.setItem("doubleLuck", 2);
        Swal.fire({
          title: t("alert"),
          text: t("succec"),
          icon: "success",
          confirmButtonColor: Color.error,
          confirmButtonText: t("comfirm"),
        }).then((result) => {
          if (result.isConfirmed) {
            setLotdoubleLuck("2")
            setAddCart(newArray);
            // window.location.href = "../Cart";
          }
        });
      }
    } else {
      let itm = addCart.findIndex((data) => data.lottoNumber === e.lottoNumber);
      if (itm > -1) {
        addCart.splice(itm, 1);
        localStorage.setItem("addCart", JSON.stringify(addCart));
        setAddCart(addCart);
        const lottoNumber = e ? e.lottoNumber : null;
        const unDoubleLuck = JSON.parse(localStorage.getItem("addCart"));
        if (unDoubleLuck.length === 0) {
          localStorage.removeItem("doubleLuck");
        }

        dispatch({
          type: "UPDATE_ALERT",
          payload: {
            open: true,
            severity: "success",
            message: t("removeLotoFormCart", { lottoNumber: lottoNumber }),
          },
        });
      } else {
        dispatch({
          type: "UPDATE_ALERT",
          payload: {
            open: true,
            severity: "error",
            message: t("Nodata"),
          },
        });
      }
    }
  };
  const onNext = () => {
    if (localStorage.getItem("doubleLuck") === "2") {
      if (addCart && addCart.length > 0) {
        const newArray = [];
        for (let i = 0; i < addCart.length; i++) {
          const item = {};
          item["id_loto"] = addCart[i].id_loto;
          item["id"] = addCart[i].id;
          item["lotId"] = addCart[i].lotId;
          item["lotDate"] = addCart[i].lotDate;
          item["numberStatus"] = addCart[i].numberStatus;
          item["winnerStatus"] = addCart[i].winnerStatus;
          item["lottoNumber"] = addCart[i].lottoNumber;
          item["lottoPrice"] =
            parseInt(addCart[i].lottoPrice, 10) === 120
              ? 120
              : parseInt(addCart[i].lottoPrice, 10) + 20;
          item["costPrice"] = addCart[i].costPrice;
          item["qty"] = addCart[i].qty;
          newArray.push(item);
        }
        localStorage.setItem("addCart", JSON.stringify(newArray));
        navigate("/OrderDetail");
      }
    } else if (localStorage.getItem("doubleLuck") === "0") {
      navigate("/OrderDetail");
    } else {
      dispatch({ type: "OPEN_PAYMENT" });
    }
  };
  const onComfirm = () => {
    if (localStorage.getItem("doubleLuck") === "2") {
      if (addCart && addCart.length > 0) {
        const newArray = [];
        for (let i = 0; i < addCart.length; i++) {
          const item = {};

          item["id_loto"] = addCart[i].id_loto;
          item["id"] = addCart[i].id;
          item["lotId"] = addCart[i].lotId;
          item["lotDate"] = addCart[i].lotDate;
          item["numberStatus"] = addCart[i].numberStatus;
          item["winnerStatus"] = addCart[i].winnerStatus;
          item["lottoNumber"] = addCart[i].lottoNumber;
          item["lottoPrice"] =
            parseInt(addCart[i].lottoPrice, 10) === 120
              ? 120
              : parseInt(addCart[i].lottoPrice, 10) + 20;
          item["costPrice"] = addCart[i].costPrice;
          item["qty"] = addCart[i].qty;
          newArray.push(item);
          // console.log("newArray:", newArray);
        }
        localStorage.setItem("addCart", JSON.stringify(newArray));
        dispatch({ type: "CLOSE_PAYMENT" });
        navigate("/OrderDetail");
      }
    } else {
      dispatch({ type: "CLOSE_PAYMENT" });
      navigate("/OrderDetail");
    }
  };
  const onCancelDoubleLuck = () => {
    setAlertStatus(true);
    setdes(t("txtCancelDoubleLuckDetail"));
    dispatch({ type: "OPEN_ALERT_DELETE", payload: "CEAR_DOBLELUCK" });
  };
  const onjoinlDoubleLuck = () => {
    setAlertStatus(true);
    setdes(t("txtAlertDoubleLuck"));
    dispatch({ type: "OPEN_ALERT_DELETE", payload: "JION_DOBLELUCK" });
  };
  return (
    <Box component="main" sx={{ width: "100%" }}>
      <HeaderCompoment icon={true} clearDoudle={true} textHeader="Cart" />
      <br />
      <br />
      <br />
      <br />
      <div
        className="section wow fadeIn"
        style={{ visibility: "visible", animationName: "fadeIn" }}
      >
        <h5 className="my-2 d-flex">
          {t("LotterySelected")}
          <span className="info">
            {t("period")}:
            {currentUser
              ? currentUser.lotAvaiable
                ? currentUser.lotAvaiable[0].fromLotDate
                : null
              : null}
          </span>
        </h5>
        <div className="lotto-lists pt-2">
          {addCart
            ? addCart.length > 0
              ? addCart.map((row) =>
                  currentUser.viewImageLottery ? (
                    <Grid container>
                      <Grid item xs={11} sx={{ justifyItems: "center" }}>
                        <img
                          src={
                            process.env.REACT_APP_IMAGE +
                            row.lotId +
                            "/" +
                            row.lottoNumber +
                            ".png"
                          }
                          alt="Snow"
                          className="img-hover-shadow"
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Stack
                          direction="row"
                          sx={{
                            flexGrow: 1,
                            textAlign: "center",
                            justifyItems: "center",
                            height: "100%",
                          }}
                        >
                          <IconButton
                            onClick={() => onDelete(row)}
                            aria-label="delete"
                            size="large"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Stack>
                      </Grid>
                    </Grid>
                  ) : (
                    <div key={row.id_loto} className="card-lotto in-cart">
                      <button
                        className="card-delete"
                        onClick={() => onDelete(row)}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#warningModal"
                      />
                      <div className="card-row row-1">
                        <div className="set s-2">
                          <span>{row.lotDate}</span>
                        </div>
                        <div className="logo">
                          <span className="icons" />
                          <span>{t("GovernmentLottery")}</span>
                        </div>
                      </div>
                      <div className="card-row row-2">
                        <div className="number-lists textgroup">
                          <span style={{ letterSpacing: "0.5cm" }}>
                            {row.lottoNumber}
                          </span>
                        </div>
                      </div>
                      <div className="card-row row-3">
                        <div className="textgroup">
                          <span>
                            {t("period")} {row.lotId}
                          </span>
                          <span>{t("set")} 1</span>
                          <span className="viewer">{t("totalAndService")}</span>
                        </div>
                        <span className="price">
                          <strong>{row.lottoPrice}</strong> {t("bat")}
                        </span>
                      </div>
                    </div>
                  )
                )
              : null
            : null}
        </div>

        {addCart ? (
          addCart.length > 0 ? null : (
            <Box
              sx={{
                m: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Img src={image.iconNoData} alt="IconNodata" />
              <Alert severity="warning">{t("Nodata")}</Alert>
            </Box>
          )
        ) : null}
        <div className="d-flex pt-4">
          <a className="btn w-200 mx-auto" onClick={() => navigate("/")}>
            <p>
              <span className="icons icon-plus w-18" />
              {t("AddLottery")}
            </p>
          </a>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />

      {addCart && addCart.length > 0 ? (
        <div className="footer-fixed" style={{ height: "220.188px" }}>
          <div className="card-info card-fixed">
            <div className="card-body">
              <table className="table table-borderless table-total">
                <tbody>
                  <tr>
                    <td>
                      <h6>{t("PleaseConfirmWithin")}</h6>
                    </td>
                    <td>
                      <h5 style={{ color: "#FF0000" }}>05 : 00</h5>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td>{t("NumberItems")}</td>
                    <td>
                      {addCart.length} {t("item")}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("SumMoney")}</td>
                    <td>
                      {formatNumber(
                        addCart.reduce(
                          (a, v) => (a = a + parseInt(v.lottoPrice)),
                          0
                        ) + ""
                      )}{" "}
                      {t("bat")}
                    </td>
                  </tr>
                  <tr>
                    <td>{t("BalanceMustPaid")}</td>
                    <td>
                      {formatNumber(
                        addCart.reduce(
                          (a, v) => (a = a + parseInt(v.lottoPrice)),
                          0
                        ) + ""
                      )}{" "}
                      {t("bat")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {DoubleLuck === "2" ? (
              <Alert
                action={
                  <Button
                    onClick={() => onCancelDoubleLuck()}
                    color="inherit"
                    size="small"
                  >
                    {t("txtCancelDoubleLuck")}
                  </Button>
                }
                severity="success"
              >
                {t("DoubleLuck")}
              </Alert>
            ) : (
              <Alert
                action={
                  <Button
                    onClick={() => onjoinlDoubleLuck()}
                    color="inherit"
                    size="small"
                  >
                    {t("detail")}
                  </Button>
                }
                severity="warning"
              >
                {t("txtlDoubleLuck")}
              </Alert>
            )}
            <p></p>
            <a className="btn btn-yellow btn-lg mb-2" onClick={() => onNext()}>
              <p>{t("Continue")}</p>
            </a>
          </div>
        </div>
      ) : null}
      <AertYesorNo
        Title={t("info")}
        ContentText={AlertStatus ? des : t("deleteThisEmployee")}
        Actions={t("Confirm")}
        Icon="WONING"
        cancel={t("cancel")}
        onConfrimDelete={(e) => onConfrimDelete(e)}
      />
      <DialogCompomentFull
        fucName="CART"
        txtTile="txtProvisions"
        onComfirm={() => onComfirm()}
      />
    </Box>
  );
}
