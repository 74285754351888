import React, { useState, useEffect } from "react";
import { Box, Paper, Button } from '@mui/material';
import { HeaderCompoment, TextinputComponent, DialogCompoment, OutlinedInputPass } from '../../compoment'
import { useValue } from '../../context/ContextProvider';
import { useTranslation } from "react-i18next";
import { requestBodyUpdateAccount } from '../../body/bodyRequest'
import { requestUpdateAccount } from '../../actions/ResetClient'
import { useNavigate } from 'react-router-dom';

export default function ChangePassword() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        state: { currentUser, tokenInfo },
        dispatch,
    } = useValue();

    const [OdlPassword, setOdlPassword] = useState('');
    const [OdlPasswordError, setOdlPasswordError] = useState(null);

    const [password, setpassword] = useState('');
    const [passwordError, setpasswordError] = useState(null);

    const [Newpassword, setNewpassword] = useState('');
    const [NewpasswordError, setNewpasswordError] = useState(null);




    useEffect(() => {



    });

    const onPassword = (event) => {
        const value = event.target.value;
        const passwordError =
            !value || value.length < 3 ? t("errorUsername") : null;
        setpassword(value);
        setpasswordError(passwordError);
    }

    const onNewPassword = (event) => {
        const value = event.target.value;
        const NewpasswordError =
            !value || value.length < 3 ? t("errorUsername") : null;
        setNewpassword(value);
        setNewpasswordError(NewpasswordError);
    }

    const onOdlPassword = (event) => {
        const value = event.target.value;
        const OdlPasswordError =
            !value || value.length < 3 ? t("errorUsername") : null;
        setOdlPassword(value);
        setOdlPasswordError(OdlPasswordError);
    }


    const onUpdateAccount = (event) => {
        event.preventDefault();

        if (currentUser && tokenInfo) {
            if (currentUser.accInfo) {
                if (Newpassword && password && OdlPassword) {
                    if (Newpassword === password) {
                        if (currentUser.accInfo.password === OdlPassword) {
                            const token = tokenInfo.access_token
                            const cusId = currentUser.accInfo.cusId
                            const cusName = currentUser.accInfo.cusName
                            const cusSurname = currentUser.accInfo.cusSurname
                            const gender = currentUser.accInfo.gender
                            const msisdn = currentUser.accInfo.msisdn
                            const email = currentUser.accInfo.email
                            const agentInvite = currentUser.agent ? currentUser.agent.agentId : null
                            const cusTypeId = currentUser.accInfo.cusTypeId
                            const bankAcc = currentUser.accInfo.bankAcc
                            const bankName = currentUser.accInfo.bankName
                            const username = currentUser.accInfo.username
                            const password = Newpassword
                            const carriedCode = currentUser.accInfo.carriedCode
                            const carriedName = currentUser.accInfo.carriedName
                            let dataReq = requestBodyUpdateAccount(cusId, cusName, cusSurname, gender, msisdn, email, agentInvite, cusTypeId, bankAcc, bankName, username, password, carriedCode, carriedName)
                            requestUpdateAccount(dataReq, token, dispatch)
                        } else {
                            return dispatch({
                                type: 'UPDATE_ALERT',
                                payload: {
                                    open: true,
                                    severity: 'error',
                                    message: 'Old password are not the same',
                                },
                            });
                        }

                    } else {
                        return dispatch({
                            type: 'UPDATE_ALERT',
                            payload: {
                                open: true,
                                severity: 'error',
                                message: 'Passwords are not the same',
                            },
                        });
                    }
                } else {
                    return dispatch({
                        type: 'UPDATE_ALERT',
                        payload: {
                            open: true,
                            severity: 'error',
                            message: 'Insufficient information',
                        },
                    });
                }
            } else {
                return dispatch({
                    type: 'UPDATE_ALERT',
                    payload: {
                        open: true,
                        severity: 'error',
                        message: 'Current User accI nfo null',
                    },
                });
            }
        } else {
            return dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: 'Current User null',
                },
            });
        }

    }


    return (
        <Box component="main" sx={{ width: '100%' }}>
            <HeaderCompoment icon={true} icondat={false} textHeader='ChangePassword' />
            <br />
            <br />
            <br />
            <br />

            <Paper elevation={16} sx={{ m: 1 }}>



                <Box sx={{ p: 1 }}>
                    {/* <TextinputComponent
                        error={OdlPasswordError ? true : false}
                        margin="normal"
                        required
                        fullWidth
                        label={t("OdlPassword")}
                        value={`${OdlPassword}`}
                        id="OdlPassword"
                        name="OdlPassword"
                        autoComplete="password"
                        onChange={onOdlPassword}
                        autoFocus
                        helperText={OdlPasswordError}
                    /> */}

                    <OutlinedInputPass
                        error={OdlPasswordError ? true : false}
                        margin="normal"
                        required
                        fullWidth
                        label={t("OdlPassword")}
                        value={`${OdlPassword}`}
                        id="OdlPassword"
                        name="OdlPassword"
                        autoComplete="password"
                        onChange={onOdlPassword}
                        autoFocus
                        helperText={OdlPasswordError}
                        numLength={10}
                        inputMode='numeric'
                    />


                    {/* <TextinputComponent
                        error={passwordError ? true : false}
                        margin="normal"
                        required
                        fullWidth
                        label={t("Newpassword")}
                        value={`${password}`}
                        id="password"
                        name="password"
                        autoComplete="password"
                        onChange={onPassword}
                        autoFocus
                        helperText={passwordError}
                    /> */}
                    <OutlinedInputPass
                        error={passwordError ? true : false}
                        margin="normal"
                        required
                        fullWidth
                        label={t("Newpassword")}
                        value={`${password}`}
                        id="password"
                        name="password"
                        autoComplete="password"
                        onChange={onPassword}
                        autoFocus
                        helperText={passwordError}
                        numLength={10}
                        inputMode='numeric'
                    />
                    {/* <TextinputComponent
                        error={NewpasswordError ? true : false}
                        margin="normal"
                        required
                        fullWidth
                        label={t("confirmNewpassword")}
                        value={`${Newpassword}`}
                        id="Newpassword"
                        name="Newpassword"
                        autoComplete="password"
                        onChange={onNewPassword}
                        autoFocus
                        helperText={NewpasswordError}
                    /> */}
                    <OutlinedInputPass
                        error={NewpasswordError ? true : false}
                        margin="normal"
                        required
                        fullWidth
                        label={t("confirmNewpassword")}
                        value={`${Newpassword}`}
                        id="Newpassword"
                        name="Newpassword"
                        autoComplete="password"
                        onChange={onNewPassword}
                        autoFocus
                        helperText={NewpasswordError}
                        numLength={10}
                        inputMode='numeric'
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={(e) => onUpdateAccount(e)}
                        disabled={
                            passwordError || NewpasswordError || OdlPasswordError ||
                                !password || !Newpassword || !OdlPassword
                                ? true
                                : false
                        }
                    >
                        {t('Update')}
                    </Button>
                </Box>

            </Paper>


            <DialogCompoment
                onComfirm={() => navigate('/')}
                Title={t('info')}
                ContentText={t('updatedNewPassword')}
                Actions={t('Confirm')}
                Icon='SUCCESS' />
        </Box>

    )
}
