import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import { useTranslation } from "react-i18next";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useValue } from '../../context/ContextProvider';
import PropTypes from "prop-types";
import { styled, ListItemAvatar } from '@mui/material';
import { iconBank } from './BankInfo'
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

BankList.propTypes = {

    onComfirm: PropTypes.func
};

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '50%',
    maxHeight: '50%',
    borderRadius: 10
});

export default function BankList({ onComfirm }) {
    const { t } = useTranslation();
    const {
        state: { openBankList },
        dispatch,
    } = useValue();
    const handleClose = () => {
        // onComfirm()
        dispatch({ type: 'CLOSE_BANKLIST_DIALOG' });
    };
    const onSelect = (item) => {
        onComfirm(item)
        dispatch({ type: 'CLOSE_BANKLIST_DIALOG' });
    }
    return (
        <div>

            <Dialog
                fullScreen
                open={openBankList}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            ເລືອກທະນາຄານ
                        </Typography>

                    </Toolbar>
                </AppBar>
                {
                    iconBank ? iconBank.map((item) => (
                        <a onClick={() => onSelect(item)}>
                            <List key={item.id} sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Img src={item.img} alt />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={item.name}
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {t(item.fullName)}
                                                </Typography>

                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </a>

                    )) : null
                }

            </Dialog>
        </div>
    );
}