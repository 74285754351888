const fetchData = async (
    { url, method = 'POST', token = '', body = null, key = '' },
    dispatch
) => {
    const headers = token
        ? { 'Content-Type': 'application/json', authorization: `Bearer ${token}` }
        : key ? { 'Content-Type': 'application/json', Authorization: `${key}` }
            : { 'Content-Type': 'application/json' };
    body = body ? { body: JSON.stringify(body) } : {};
    //console.log('headers:', headers)
    //console.log('url:', url) 
    //console.log('token:', token)
    try {
        //console.log('Step 1')
        const response = await fetch(url, { method, headers, ...body });
        //console.log('response:',response)
        const data = await response.json();
        //console.log('data fatch--1--:', data)
        if (data.responseCode === "10006" || data.status === false) {
            dispatch({ type: 'END_LOADING' });
            dispatch({ type: 'UPDATE_USER', payload: null });
            throw new Error(data.message);
        }
        return data;
    } catch (error) {
        //console.log('Step 2')
        dispatch({
            type: 'UPDATE_ALERT',
            payload: { open: true, severity: 'error', message: error.message },
        });
        //console.log(error);
        return null;
    }
};

export default fetchData;
