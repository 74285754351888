import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next'
import { ThemeProvider } from "@mui/material/styles";
import OneSignal from 'react-onesignal';
import { useValue } from '../src/context/ContextProvider';
import RouteScreen from './Router/router'
import RouteLogin from './Router/routerLogin'
import i18n from './i18n';
import { theme } from './themes'

const App = () => {
  const {
    state: { currentUser }
  } = useValue();
  useEffect(() => {

    // if ('WebAuthn' in window || 'PasswordCredential' in window) {
    //   // Browser supports Web Authentication API
    //   console.log("Browser supports Web Authentication API")
    // } else {
    //   // Browser does not support Web Authentication API
    //   console.log("Browser does not support Web Authentication API")
    // }

    OneSignal.init(
      {
        appId: "2df0a88d-c6e5-45de-8909-39bda92e0c4aE", //STEP 9
        promptOptions: {
          slidedown: {
            enabled: true,
            actionMessage: "Please allow me you will receive all promotion",
            acceptButtonText: "Allow",
            cancelButtonText: "Cancel"
          }
        },
        welcomeNotification: {
          "title": "234Society",
          "message": "Thanks for subscribing!",
        }
      },
      OneSignal.sendTag("new_app_version", "new_app_version", tagsSent => {
      }),
      OneSignal.getExternalUserId().then(function (externalUserId) {
      })
    );
    
  }, []);
  return (
    <>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <I18nextProvider i18n={i18n}>
            {/* <button onClick={()=>fetchKey()}>nnn</button> */}
            {currentUser ? <RouteScreen />
              : <RouteLogin />}
          </I18nextProvider>
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
