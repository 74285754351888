import React from 'react'
import img from '../../themes/image'
export default function index() {
    return (
        <div className="page">
            <div className="page-boxed">
                <header className="headerOne lg">
                    <div className="container_one container_one1">
                        <a className="pageIconBank goback t-0" href="/">
                            <span className="iconsOne" />
                        </a>
                        <div className="item-center">
                            <h2 className="page-title pt-1">คะแนนสะสม</h2>
                        </div>
                        <div className="pageIconBank" />
                        <div className="card-info_one point">
                            <div className="card-icon">
                                <img src={img.iconStar} alt />
                            </div>
                            <div>
                                <h5 className='txtH5'>คะแนนสะสมทั้งหมด</h5>
                                <p className="grey mb-0"><small>Member point</small></p>
                            </div>
                            <div className="ms-auto text-end">
                                <h3 className="green">1,257</h3>
                                <p className="grey mb-0"><small>คะแนน</small></p>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="mt-2 p-1" />
                <div className="section d-flexOne vstack gap-2 mt-5">
                    <div className="card-info_one d-block">
                        <a href="/" className="card-link" />
                        <div className="d-flexOne align-items-center">
                            <div className="fs-12">
                                <p className="m-00 fw-light">ได้รับคะแนนจากการซื้อ</p>
                                <p className="m-00">#NKAS20220301254</p>
                                <p className="m-00 fs-10 grey">09/06/2565 10:29</p>
                            </div>
                            <div className="ms-auto">
                                <h5 className="fw-normal green">+ 15</h5>
                            </div>
                        </div>{/*row*/}
                    </div>{/*card-info_one*/}
                    <div className="p-4" />
                </div>{/*section*/}
            </div>
        </div>

    )
}
