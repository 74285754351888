import fetchData from "../utils/fetchData";
import fetchLogin from "../utils/fetchLogin";
import { encodeHmacSHA256 } from "../utils/utilsCommon";
import {
  requestGetToken,
  requestBodyRegiterAccount,
} from "../body/bodyRequest";
import { encodeBase64 } from "../utils/utilsCommon";
// import { useTranslation } from 'react-i18next'

const url = process.env.REACT_APP_SERVER_URL;
const second_key = process.env.REACT_APP_SECOND_KEY;
const user_agent = process.env.REACT_APP_USER_AGENT;

const username_sys = process.env.REACT_APP_USERNAME;
const password_sys = process.env.REACT_APP_PASSWORD;
const secret_key = process.env.REACT_APP_SECRET_KEY;

export const requestRegisterAccount = async (
  cusName,
  cusSurname,
  gender,
  msisdn,
  email,
  agentInvite,
  cusTypeId,
  bankAcc,
  bankName,
  username,
  password,
  carriedCode,
  carriedName,
  inviteStatus,
  parentId,
  dispatch
) => {
  if (username_sys && password_sys && secret_key) {
    const key = encodeBase64(
      username_sys + ":" + password_sys + ":" + secret_key
    );
    const data = requestGetToken(username_sys, password_sys, secret_key);
    //console.log('data body:', data)
    dispatch({ type: "START_LOADING" });

    if (key && data) {
      dispatch({ type: "START_LOADING" });
      const result = await fetchData(
        {
          url: url + "/generate_token",
          method: "POST",
          key: key,
          body: data,
        },
        dispatch
      );
      if (result) {
        //console.log('-----result-----', result)
        if (result.responseCode === "00000") {
          dispatch({ type: "TOKEN_INFO", payload: result });
          const dataRegister = requestBodyRegiterAccount(
            cusName,
            cusSurname,
            gender,
            msisdn,
            email,
            agentInvite,
            cusTypeId,
            bankAcc,
            bankName,
            username,
            password,
            carriedCode,
            carriedName,
            inviteStatus,
            parentId
          );
          const token = result.access_token;
          const Signature = encodeHmacSHA256(second_key, token);
          //console.log('token:', token)
          //console.log('Signature:', Signature)
          // console.log('dataRegister:', dataRegister)

          const result_Register = await fetchLogin(
            {
              url: url + "/admin/registerAccount",
              method: "POST",
              Signature: Signature,
              Authorization: token,
              ClientId: user_agent,
              body: dataRegister,
            },
            dispatch
          );

          //console.log('result_Register1:', result_Register)
          if (result_Register.responseCode === "00000") {
            //console.log('result_Register2:', result_Register)
            dispatch({ type: "OPEN_DIALOG" });
            dispatch({ type: "END_LOADING" });
          } else {
            dispatch({ type: "END_LOADING" });
            return dispatch({
              type: "UPDATE_ALERT",
              payload: {
                open: true,
                severity: "error",
                message: result_Register.message,
              },
            });
          }
        }
      }
      dispatch({ type: "END_LOADING" });
    } else {
      dispatch({ type: "END_LOADING" });
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Error can not get data and key",
        },
      });
    }
  } else {
    dispatch({ type: "END_LOADING" });
    return dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "error",
        message: "Error can not get user and pass",
      },
    });
  }
};

export const requestUpdateAccount = async (dataReq, token, dispatch) => {
  try {
    dispatch({ type: "START_LOADING" });
    if (dataReq && token) {
      const Signature = encodeHmacSHA256(second_key, token);
      //console.log('tokenInfo', token)
      //console.log('Signature', Signature)
      //console.log('dataReq', dataReq)
      const resultUpdateProfile = await fetchLogin(
        {
          url: url + "/admin/updateAccount",
          method: "POST",
          Signature: Signature,
          Authorization: token,
          ClientId: user_agent,
          body: dataReq,
        },
        dispatch
      );
      //console.log('resultUpdateProfile--update profile:', resultUpdateProfile)
      if (resultUpdateProfile.responseCode === "00000") {
        dispatch({ type: "OPEN_DIALOG" });
        dispatch({ type: "END_LOADING" });
        
      } else {
        dispatch({ type: "END_LOADING" });
        return dispatch({
          type: "UPDATE_ALERT",
          payload: {
            open: true,
            severity: "error",
            message: resultUpdateProfile.message,
          },
        });
      }
      dispatch({ type: "END_LOADING" });
    } else {
      dispatch({ type: "END_LOADING" });
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Body req  and token is not null, pls check",
        },
      });
    }
  } catch (error) {
    return dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "error",
        message: error,
      },
    });
  }
};

export const requestFindLotteryNumber = async (dataReq, token, dispatch) => {
  // const { t } = useTranslation();
  try {
    dispatch({ type: "START_LOADING" });
    if (dataReq && token) {
      const Signature = encodeHmacSHA256(second_key, token);
      //console.log('tokenInfo', token)
      //console.log('Signature', Signature)
      //console.log('dataReq', dataReq)
      const resultFindLoto = await fetchLogin(
        {
          url: url + "/service/findLotteryNumber",
          method: "POST",
          Signature: Signature,
          Authorization: token,
          ClientId: user_agent,
          body: dataReq,
        },
        dispatch
      );
      // console.log('requestFindLotteryNumber:', resultFindLoto)
      if (resultFindLoto) {
        if (resultFindLoto.responseCode === "00000") {
          //console.log('resultFindLoto.listLottery:', resultFindLoto.listLottery)
          if (resultFindLoto.listLottery.length > 0) {
            dispatch({
              type: "RESULT_SEARCH_LOTO",
              payload: resultFindLoto.listLottery,
            });
            dispatch({ type: "END_LOADING" });
          } else {
            dispatch({ type: "END_LOADING" });
            return dispatch({
              type: "UPDATE_ALERT",
              payload: {
                open: true,
                severity: "warning",
                message: "ບໍ່ພົບຂໍ້ມູນ",
              },
            });
          }
        } else {
          dispatch({ type: "END_LOADING" });
          return dispatch({
            type: "UPDATE_ALERT",
            payload: {
              open: true,
              severity: "error",
              message: resultFindLoto.message,
            },
          });
        }
      }
      dispatch({ type: "END_LOADING" });
    } else {
      dispatch({ type: "END_LOADING" });
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Body req  and token is not null, pls check",
        },
      });
    }
  } catch (error) {
    return dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "error",
        message: error,
      },
    });
  }
};

export const GetAppContentByType = async (
  token,
  param,
  displayAlert,
  dispatch
) => {
  try {
    dispatch({ type: "START_LOADING" });
    if (token && param) {
      const Signature = encodeHmacSHA256(second_key, token);
      //console.log('tokenInfo', token)
      //console.log('Signature', Signature)
      //console.log('param', param)
      //console.log('-----', url + `/service/getAppContentByType/${param}`)
      const result = await fetchLogin(
        {
          url: url + `/service/getAppContentByType/${param}`,
          method: "GET",
          Signature: Signature,
          Authorization: token,
          ClientId: user_agent,
        },
        dispatch
      );
      //console.log('getAppContentByType:', result)
      if (result) {
        if (result.responseCode === "00000") {
          dispatch({ type: "INFO_APP", payload: result.listAppContent[0] });
          dispatch({ type: "END_LOADING" });
        } else {
          dispatch({ type: "END_LOADING" });
          if (!displayAlert) {
            return dispatch({
              type: "UPDATE_ALERT",
              payload: {
                open: true,
                severity: "error",
                message: result.message,
              },
            });
          }
        }
      }

      dispatch({ type: "END_LOADING" });
    } else {
      dispatch({ type: "END_LOADING" });
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Pls check get token key and Param",
        },
      });
    }
  } catch (error) {
    return dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "error",
        message: error,
      },
    });
  }
};

export const GetAppImageByCategory = async (token, param, dispatch) => {
  try {
    dispatch({ type: "START_LOADING" });
    if (token && param) {
      const Signature = encodeHmacSHA256(second_key, token);
      //console.log('tokenInfo', token)
      //console.log('Signature', Signature)
      //console.log('param', param)
      //console.log('--getAppImageByCategory---', url + `/service/getAppImageByCategory/${param}`)
      const result = await fetchLogin(
        {
          url: url + `/service/getAppImageByCategory/${param}`,
          method: "GET",
          Signature: Signature,
          Authorization: token,
          ClientId: user_agent,
        },
        dispatch
      );
      //console.log('listAppImage:', result)
      if (result) {
        if (result.responseCode === "00000") {
          dispatch({ type: "BANNER_APP", payload: result.listAppImage });
          dispatch({ type: "END_LOADING" });
        } else {
          dispatch({ type: "END_LOADING" });
          return dispatch({
            type: "UPDATE_ALERT",
            payload: {
              open: true,
              severity: "error",
              message: result.message,
            },
          });
        }
      }

      dispatch({ type: "END_LOADING" });
    } else {
      dispatch({ type: "END_LOADING" });
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Pls check get token key and Param",
        },
      });
    }
  } catch (error) {
    return dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "error",
        message: error,
      },
    });
  }
};

//buyLottery

export const reqBuyLottery = async (token, dataReq, dispatch) => {
  try {
    dispatch({ type: "START_LOADING" });
    if (token) {
      if (dataReq) {
        const Signature = encodeHmacSHA256(second_key, token);
        //console.log('tokenInfo', token)
        //console.log('Signature', Signature)
        //console.log('dataReq', dataReq)
        //console.log('--Buy loto---', url + `/service/buyLottery`)

        const result = await fetchLogin(
          {
            url: url + "/service/buyLottery",
            method: "POST",
            Signature: Signature,
            Authorization: token,
            ClientId: user_agent,
            body: dataReq,
          },
          dispatch
        );

        //console.log('----buyLottery 1---:', result)
        if (result) {
         // console.log('----buyLottery 2---:', result)
         // console.log('----buyLottery responseCode 2---:', result.responseCode)
          // "00009"
          if (result.responseCode === "00009" || result.responseCode === "00000" ) {
            dispatch({ type: "LIST_LOTO_BUY_SUCCESS", payload: result });
            dispatch({ type: "GET_DASHBOARD", payload: [] });
            dispatch({ type: "END_LOADING" });
            // dispatch({ type: 'OPEN_PAYMENT' });
            dispatch({ type: "RESULT_SEARCH_LOTO", payload: [] });
            //doubleLuck
            localStorage.removeItem("doubleLuck");
            localStorage.removeItem("addCart");
          } else {
            dispatch({ type: "END_LOADING" });
            return dispatch({
              type: "UPDATE_ALERT",
              payload: {
                open: true,
                severity: "error",
                message: result.message,
              },
            });
          }
        }
      } else {
        dispatch({ type: "END_LOADING" });
        return dispatch({
          type: "UPDATE_ALERT",
          payload: {
            open: true,
            severity: "error",
            message: "Pls check body req",
          },
        });
      }
    } else {
      dispatch({ type: "END_LOADING" });
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Pls check get token key",
        },
      });
    }
  } catch (error) {
    return dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "error",
        message: error,
      },
    });
  }
};

// get history

export const reqGetHistoryBuyLoto = async (token, dataReq, dispatch) => {
  try {
    dispatch({ type: "START_LOADING" });
    if (token) {
      if (dataReq) {
        const Signature = encodeHmacSHA256(second_key, token);
        //console.log('tokenInfo', token)
        //console.log('Signature', Signature)
        //console.log('dataReq', dataReq)
        //console.log('--Buy loto---', url + `/service/customerHistoryById`)

        const result = await fetchLogin(
          {
            url: url + "/service/customerHistoryById",
            method: "POST",
            Signature: Signature,
            Authorization: token,
            ClientId: user_agent,
            body: dataReq,
          },
          dispatch
        );
        //console.log('----customerHistoryById---:', result)

        if (result) {
          if (result.responseCode === "00000") {
            //responseCode  lstHistory
            dispatch({ type: "GET_HISTORY_BUY_LOTO", payload: result });
            //console.log('----data history:', result.lstHistory)
            dispatch({ type: "END_LOADING" });
          } else {
            dispatch({ type: "END_LOADING" });
            return dispatch({
              type: "UPDATE_ALERT",
              payload: {
                open: true,
                severity: "error",
                message: result.message,
              },
            });
          }
        }
      } else {
        dispatch({ type: "END_LOADING" });
        return dispatch({
          type: "UPDATE_ALERT",
          payload: {
            open: true,
            severity: "error",
            message: "Pls check body req",
          },
        });
      }
    } else {
      dispatch({ type: "END_LOADING" });
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Pls check get token key",
        },
      });
    }
  } catch (error) {
    return dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "error",
        message: error,
      },
    });
  }
};

//getQuestionnaireItem
export const GetQuestionnaireItem = async (token, dispatch) => {
  try {
    dispatch({ type: "START_LOADING" });
    if (token) {
      const Signature = encodeHmacSHA256(second_key, token);
      //console.log('tokenInfo', token)
      //console.log('Signature', Signature)
      const result = await fetchLogin(
        {
          url: url + `/service/getQuestionnaireItem`,
          method: "GET",
          Signature: Signature,
          Authorization: token,
          ClientId: user_agent,
        },
        dispatch
      );
      //console.log('questionnaireItem:', result)
      if (result) {
        if (result.responseCode === "00000") {
          dispatch({ type: "GET_QUESTION", payload: result.listQuestionnaire });
          dispatch({ type: "END_LOADING" });
        } else {
          dispatch({ type: "END_LOADING" });
          return dispatch({
            type: "UPDATE_ALERT",
            payload: {
              open: true,
              severity: "error",
              message: result.message,
            },
          });
        }
      }

      dispatch({ type: "END_LOADING" });
    } else {
      dispatch({ type: "END_LOADING" });
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Pls check get token key and Param",
        },
      });
    }
  } catch (error) {
    return dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "error",
        message: error,
      },
    });
  }
};

export const reqCustomerQuestion = async (token, dataReq, cusId, dispatch) => {
  try {
    dispatch({ type: "START_LOADING" });
    if (token) {
      if (dataReq) {
        const Signature = encodeHmacSHA256(second_key, token);
        //console.log('tokenInfo', token)
        //console.log('Signature', Signature)
        //console.log('dataReq', dataReq)

        const result = await fetchLogin(
          {
            url: url + "/service/customerQuestion",
            method: "POST",
            Signature: Signature,
            Authorization: token,
            ClientId: user_agent,
            body: dataReq,
          },
          dispatch
        );
        //console.log('----buyLottery---:', result)

        if (result) {
          if (result.responseCode === "00000") {
            dispatch({ type: "END_LOADING" });
            dispatch({ type: "OPEN_PAYMENT" });
            let param = cusId;
            GetCustomerQuestionByCusId(token, param, dispatch);
          } else {
            dispatch({ type: "END_LOADING" });
            return dispatch({
              type: "UPDATE_ALERT",
              payload: {
                open: true,
                severity: "error",
                message: result.message,
              },
            });
          }
        }
      } else {
        dispatch({ type: "END_LOADING" });
        return dispatch({
          type: "UPDATE_ALERT",
          payload: {
            open: true,
            severity: "error",
            message: "Pls check body req",
          },
        });
      }
    } else {
      dispatch({ type: "END_LOADING" });
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Pls check get token key",
        },
      });
    }
  } catch (error) {
    return dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "error",
        message: error,
      },
    });
  }
};

//GetContact
export const GetContact = async (token, dispatch) => {
  try {
    dispatch({ type: "START_LOADING" });
    if (token) {
      const Signature = encodeHmacSHA256(second_key, token);
      //console.log('tokenInfo', token)
      //console.log('Signature', Signature)
      const result = await fetchLogin(
        {
          url: url + `/admin/getContact/all`,
          method: "GET",
          Signature: Signature,
          Authorization: token,
          ClientId: user_agent,
        },
        dispatch
      );
      //console.log('GetContact:', result)
      if (result) {
        if (result.responseCode === "00000") {
          dispatch({ type: "GET_CONTACT", payload: result.listContact });
          dispatch({ type: "END_LOADING" });
        } else {
          dispatch({ type: "END_LOADING" });
          return dispatch({
            type: "UPDATE_ALERT",
            payload: {
              open: true,
              severity: "error",
              message: result.message,
            },
          });
        }
      }

      dispatch({ type: "END_LOADING" });
    } else {
      dispatch({ type: "END_LOADING" });
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Pls check get token key and Param",
        },
      });
    }
  } catch (error) {
    return dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "error",
        message: error,
      },
    });
  }
};

export const GetHotNumberByLotId = async (token, param, dispatch) => {
  try {
    // dispatch({ type: 'START_LOADING' });
    if (token && param) {
      const Signature = encodeHmacSHA256(second_key, token);
      //console.log('tokenInfo', token)
      //console.log('Signature', Signature)
      //console.log('param', param)
      //http://150.95.27.137:8011/lottery/api/cms/service/getHotNumberByLotId/5
      const result = await fetchLogin(
        {
          url: url + `/service/getHotNumberByLotId/${param}`,
          method: "GET",
          Signature: Signature,
          Authorization: token,
          ClientId: user_agent,
        },
        dispatch
      );
      //console.log('listHotNumber:', result)
      if (result) {
        if (result.responseCode === "00000") {
          dispatch({
            type: "GET_HOTNUMBER_BY_LOTO_ID",
            payload: result.listHotNumber,
          });
          // dispatch({ type: 'END_LOADING' });
        } else {
          dispatch({ type: "END_LOADING" });
          // return dispatch({
          //   type: 'UPDATE_ALERT',
          //   payload: {
          //     open: true,
          //     severity: 'error',
          //     message: result.message,
          //   },
          // });
        }
      }

      // dispatch({ type: 'END_LOADING' });
    } else {
      // dispatch({ type: 'END_LOADING' });
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Pls check get token key and Param",
        },
      });
    }
  } catch (error) {
    return dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "error",
        message: error,
      },
    });
  }
};

export const GetCustomerQuestionByCusId = async (token, param, dispatch) => {
  try {
    dispatch({ type: "START_LOADING" });
    if (token) {
      const Signature = encodeHmacSHA256(second_key, token);
      //console.log('tokenInfo', token)
      //console.log('Signature', Signature)
      const result = await fetchLogin(
        {
          url: url + `/service/getCustomerQuestionByCusId/${param}`,
          method: "GET",
          Signature: Signature,
          Authorization: token,
          ClientId: user_agent,
        },
        dispatch
      );
      //console.log('GET_QUESTION_BY_CUSID:', result)
      if (result) {
        if (result.responseCode === "00000") {
          dispatch({
            type: "GET_QUESTION_BY_CUSID",
            payload: result.listCusQuestion,
          });
          dispatch({ type: "END_LOADING" });
        } else {
          dispatch({ type: "END_LOADING" });
          // return dispatch({
          //   type: 'UPDATE_ALERT',
          //   payload: {
          //     open: true,
          //     severity: 'error',
          //     message: result.message,
          //   },
          // });
        }
      }

      dispatch({ type: "END_LOADING" });
    } else {
      dispatch({ type: "END_LOADING" });
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Pls check get token key and Param",
        },
      });
    }
  } catch (error) {
    return dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "error",
        message: error,
      },
    });
  }
};

//getWinnerLotteryByCusId

export const GetWinnerLotteryByCusId = async (token, param, dispatch) => {
  try {
    dispatch({ type: "START_LOADING" });
    if (token && param) {
      const Signature = encodeHmacSHA256(second_key, token);
      //console.log('tokenInfo', token)
      //console.log('Signature', Signature)
      //console.log('param', param)
      // http://150.95.27.137:8011/lottery/api/cms/service/getWinnerLotteryByCusId/9
      const result = await fetchLogin(
        {
          url: url + `/service/getWinnerLotteryByCusId/${param}`,
          method: "GET",
          Signature: Signature,
          Authorization: token,
          ClientId: user_agent,
        },
        dispatch
      );
      //console.log('listWinner:', result)
      if (result) {
        if (result.responseCode === "00000") {
          dispatch({ type: "GET_WINNERLIST", payload: result.listWinner });
          dispatch({ type: "END_LOADING" });
        } else {
          dispatch({ type: "END_LOADING" });
          // return dispatch({
          //   type: 'UPDATE_ALERT',
          //   payload: {
          //     open: true,
          //     severity: 'error',
          //     message: result.message,
          //   },
          // });
        }
      }

      dispatch({ type: "END_LOADING" });
    } else {
      dispatch({ type: "END_LOADING" });
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Pls check get token key and Param",
        },
      });
    }
  } catch (error) {
    return dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "error",
        message: error,
      },
    });
  }
};

//getCustomerInfoByMsisdnName
export const GetCustomerInfoByMsisdnName = async (token, param, dispatch) => {
  try {
    dispatch({ type: "START_LOADING" });
    if (token && param) {
      const Signature = encodeHmacSHA256(second_key, token);
      //console.log('tokenInfo', token)
      //console.log('Signature', Signature)
      //console.log('param', param)
      const result = await fetchLogin(
        {
          url: url + `/admin/getCustomerInfoByMsisdnName/${param}/msisdn`,
          method: "GET",
          Signature: Signature,
          Authorization: token,
          ClientId: user_agent,
        },
        dispatch
      );
      //console.log('listCustomer:', result)
      if (result) {
        if (result.responseCode === "00000") {
          dispatch({
            type: "GET_CUSTOMER_INFO_BY_MSISDNNAME",
            payload: result.listCustomer,
          });
          dispatch({ type: "END_LOADING" });
        } else {
          dispatch({ type: "END_LOADING" });
          return dispatch({
            type: "UPDATE_ALERT",
            payload: {
              open: true,
              severity: "error",
              message: result.message,
            },
          });
        }
      }

      dispatch({ type: "END_LOADING" });
    } else {
      dispatch({ type: "END_LOADING" });
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Pls check get token key and Param",
        },
      });
    }
  } catch (error) {
    return dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "error",
        message: error,
      },
    });
  }
};
// dataGetDashboard

export const GetDataCusDashboard = async (token, lotiId, cusId, dispatch) => {
  try {
    // dispatch({ type: 'START_LOADING' });
    if (token && lotiId && cusId) {
      const Signature = encodeHmacSHA256(second_key, token);
      //console.log('tokenInfo', token)
      //console.log('Signature', Signature)
      //console.log(url + `/service/getCustomerDashboard/${lotiId}/${cusId}`)
      const result = await fetchLogin(
        {
          url: url + `/service/getCustomerDashboard/${lotiId}/${cusId}`,
          method: "GET",
          Signature: Signature,
          Authorization: token,
          ClientId: user_agent,
        },
        dispatch
      );
      //console.log('GetDataCusDashboard:', result)
      if (result) {
        if (result.responseCode === "00000") {
          dispatch({
            type: "GET_DASHBOARD",
            payload: result.listCustomerDashBoard,
          });
          dispatch({ type: "END_LOADING" });
        } else {
          dispatch({ type: "END_LOADING" });
          // return dispatch({
          //   type: 'UPDATE_ALERT',
          //   payload: {
          //     open: true,
          //     severity: 'error',
          //     message: result.message,
          //   },
          // });
        }
      }

      dispatch({ type: "END_LOADING" });
    } else {
      dispatch({ type: "END_LOADING" });
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Pls check get token key and Param",
        },
      });
    }
  } catch (error) {
    return dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "error",
        message: error,
      },
    });
  }
};

export const reqGetHistoryByLotId = async (token, dataReq, dispatch) => {
  try {
    dispatch({ type: "START_LOADING" });
    if (token) {
      if (dataReq) {
        const Signature = encodeHmacSHA256(second_key, token);
        //console.log('tokenInfo', token)
        //console.log('Signature', Signature)
        //console.log('dataReq', dataReq)
        //console.log('--Buy loto---', url + `/service/customerHistoryByIdAndLotId`)

        const result = await fetchLogin(
          {
            url: url + "/service/customerHistoryByIdAndLotId",
            method: "POST",
            Signature: Signature,
            Authorization: token,
            ClientId: user_agent,
            body: dataReq,
          },
          dispatch
        );
        //console.log('----customerHistoryById---:', result)

        if (result) {
          if (result.responseCode === "00000") {
            dispatch({
              type: "GET_HISTORY_BUY_LOTO_BY_LOT_ID",
              payload: result,
            });
            //console.log('----data history:', result.lstHistory)
            dispatch({ type: "END_LOADING" });
          } else {
            dispatch({ type: "END_LOADING" });
            return dispatch({
              type: "UPDATE_ALERT",
              payload: {
                open: true,
                severity: "error",
                message: result.message,
              },
            });
          }
        }
      } else {
        dispatch({ type: "END_LOADING" });
        return dispatch({
          type: "UPDATE_ALERT",
          payload: {
            open: true,
            severity: "error",
            message: "Pls check body req",
          },
        });
      }
    } else {
      dispatch({ type: "END_LOADING" });
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Pls check get token key",
        },
      });
    }
  } catch (error) {
    return dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "error",
        message: error,
      },
    });
  }
};

//(token, lotId, txn, dispatch)
export const reqcheckBillStatusById = async (token, lotId, txn, dispatch) => {
  try {
    // dispatch({ type: 'START_LOADING' });
    if (lotId && token) {
      if (txn) {
        //console.log('lotId:', lotId)
        //console.log('token:', token)
        //console.log('txn:', txn)
        const result = await fetchLogin(
          {
            url: url + `/admin/checkBillStatusById/${lotId}/${txn}`,
            method: "POST",
            Authorization: token,
          },
          dispatch
        );
        //console.log('----CHECK_BILL_STATUS---:', result)

        if (result) {
          if (result.responseCode === "00000") {
            dispatch({ type: "CHECK_BILL_STATUS", payload: result });
            //console.log('----data CHECK_BILL_STATUS:', result)
            // dispatch({ type: 'END_LOADING' });
          } else {
            // dispatch({ type: 'END_LOADING' });
            return dispatch({
              type: "UPDATE_ALERT",
              payload: {
                open: true,
                severity: "error",
                message: result.message,
              },
            });
          }
        }
      } else {
        // dispatch({ type: 'END_LOADING' });
        // return dispatch({
        //   type: 'UPDATE_ALERT',
        //   payload: {
        //     open: true,
        //     severity: 'error',
        //     message: 'Txn id null',
        //   },
        // });
      }
    } else {
      // dispatch({ type: 'END_LOADING' });
      // return dispatch({
      //   type: 'UPDATE_ALERT',
      //   payload: {
      //     open: true,
      //     severity: 'error',
      //     message: 'Lot id null',
      //   },
      // });
    }
  } catch (error) {
    return dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "error",
        message: error,
      },
    });
  }
};

export const GetNetworkMember = async (token, msisdn, lotId, dispatch) => {
  try {
    dispatch({ type: "START_LOADING" });
    if (token && msisdn) {
      const Signature = encodeHmacSHA256(second_key, token);
      const result = await fetchLogin(
        {
          url: url + `/admin/getNetworkMember/${msisdn}/${lotId}`,
          // url: url + `/admin/getNetworkMember/0874796852/68`,
          method: "GET",
          Signature: Signature,
          Authorization: token,
          ClientId: user_agent,
        },
        dispatch
      );
      // console.log('GetNetworkMember:', result)
      if (result) {
        if (result.responseCode === "00000") {
          // console.log('GetNetworkMember:', result)
          dispatch({ type: "GET_NETWORK_MEMBER", payload: result });
          dispatch({ type: "END_LOADING" });
        } else {
          dispatch({ type: "END_LOADING" });
          return dispatch({
            type: "UPDATE_ALERT",
            payload: {
              open: true,
              severity: "error",
              message: result.message,
            },
          });
        }
      }

      dispatch({ type: "END_LOADING" });
    } else {
      dispatch({ type: "END_LOADING" });
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Pls check get token key GetNetworkMember and Param",
        },
      });
    }
  } catch (error) {
    return dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "error",
        message: error,
      },
    });
  }
};

export const GetManageLottery = async (token, dispatch) => {
  try {
    dispatch({ type: "START_LOADING" });
    if (token) {
      const Signature = encodeHmacSHA256(second_key, token);
      // console.log('tokenInfo', token)
      // console.log('Signature', Signature)
      // console.log('url', url + `/admin/getNetworkMember/${msisdn}/${lotId}`)
      const result = await fetchLogin(
        {
          url: url + `/admin/getManageLottery/all`,
          method: "GET",
          Signature: Signature,
          Authorization: token,
          ClientId: user_agent,
        },
        dispatch
      );
      // console.log('555:', result)
      if (result) {
        if (result.responseCode === "00000") {
          dispatch({
            type: "LIST_MANAGE_LOTORY",
            payload: result.listManageLottery,
          });
          dispatch({ type: "END_LOADING" });
        } else {
          dispatch({ type: "END_LOADING" });
          return dispatch({
            type: "UPDATE_ALERT",
            payload: {
              open: true,
              severity: "error",
              message: result.message,
            },
          });
        }
      }

      dispatch({ type: "END_LOADING" });
    } else {
      dispatch({ type: "END_LOADING" });
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Pls check get token key and Param",
        },
      });
    }
  } catch (error) {
    return dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "error",
        message: error,
      },
    });
  }
};

export const GetCustomerPoint = async (token, param, dispatch) => {
  try {
    if (token && param) {
      const Signature = encodeHmacSHA256(second_key, token);
      const result = await fetchLogin(
        {
          url: url + `/service/getCustomerPoint/${param}`,
          method: "GET",
          Signature: Signature,
          Authorization: token,
          ClientId: user_agent,
        },
        dispatch
      );
      // console.log('GetCustomerPoint:', result)
      if (result) {
        if (result.responseCode === "00000") {
          dispatch({ type: "GET_CUSTOMER_POINT", payload: result.totalPoint });
        } else {
          dispatch({ type: "END_LOADING" });
        }
      }
    } else {
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Pls check get token key and Param",
        },
      });
    }
  } catch (error) {
    return dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "error",
        message: error,
      },
    });
  }
};

// checkMemberOfPartner
export const GetCheckMemberOfPartner = async (token, path, dispatch) => {
  // console.log("object", `${path}`);
  try {
    if (token && path) {
      const Signature = encodeHmacSHA256(second_key, token);
      const result = await fetchLogin(
        {
          url: url + `${path}`,
          method: "GET",
          Signature: Signature,
          Authorization: token,
          ClientId: user_agent,
        },
        dispatch
      );
      // console.log("GetCheckMemberOfPartner:", result);
      if (result) {
        if (result.responseCode === "00000") {
          // console.log("result.list:", result.list);
          dispatch({ type: "GET_MEMBEROFPARTNER", payload: result.list });
          dispatch({ type: "OPEN_PAYMENT" });
        } else {
          dispatch({ type: "END_LOADING" });
          return dispatch({
            type: "UPDATE_ALERT",
            payload: {
              open: true,
              severity: "error",
              message: result.message,
            },
          });
        }
      }
    } else {
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Pls check get token key and Param",
        },
      });
    }
  } catch (error) {
    return dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "error",
        message: error,
      },
    });
  }
};



export const PartnerGetReportOfPartnerTeam = async (token, msisdn, lotId, dispatch) => {
  try {
    if (token && msisdn && lotId) {
      const Signature = encodeHmacSHA256(second_key, token);
      const result = await fetchLogin(
        {
          url: url + `/admin/partnerGetReportOfPartnerTeam/${msisdn}/${lotId}`,
          method: "GET",
          Signature: Signature,
          Authorization: token,
          ClientId: user_agent,
        },
        dispatch
      );
      // console.log("partnerGetReportOfPartnerTeam:", result);
      if (result) {
        if (result.responseCode === "00000") {
          // console.log("result.list:", result.list);
          dispatch({ type: "GET_REPORT_OF_PARTNER_TEAM", payload: result });
          // dispatch({ type: "OPEN_PAYMENT" });
        } else {
          dispatch({ type: "END_LOADING" });
          return dispatch({
            type: "UPDATE_ALERT",
            payload: {
              open: true,
              severity: "error",
              message: result.message,
            },
          });
        }
      }
    } else {
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Pls check get token key and Param",
        },
      });
    }
  } catch (error) {
    return dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "error",
        message: error,
      },
    });
  }
};




export const GetRath = async (
  token,
  param,
  displayAlert,
  dispatch
) => {
  try {
    dispatch({ type: "START_LOADING" });
    if (token && param) {
      const Signature = encodeHmacSHA256(second_key, token);
      //console.log('tokenInfo', token)
      //console.log('Signature', Signature)
      //console.log('param', param)
      //console.log('-----', url + `/service/getAppContentByType/${param}`)
      const result = await fetchLogin(
        {
          url: url + `/service/getAppContentByType/${param}`,
          method: "GET",
          Signature: Signature,
          Authorization: token,
          ClientId: user_agent,
        },
        dispatch
      );
      //console.log('getAppContentByType:', result)
      if (result) {
        if (result.responseCode === "00000") {
          dispatch({ type: "GET_RATH", payload: result.listAppContent[0] });
          dispatch({ type: "END_LOADING" });
        } else {
          dispatch({ type: "END_LOADING" });
          if (!displayAlert) {
            return dispatch({
              type: "UPDATE_ALERT",
              payload: {
                open: true,
                severity: "error",
                message: result.message,
              },
            });
          }
        }
      }

      dispatch({ type: "END_LOADING" });
    } else {
      dispatch({ type: "END_LOADING" });
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "error",
          message: "Pls check get token key and Param",
        },
      });
    }
  } catch (error) {
    return dispatch({
      type: "UPDATE_ALERT",
      payload: {
        open: true,
        severity: "error",
        message: error,
      },
    });
  }
};