import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import parse from 'html-react-parser';

import { TransitionProps } from "@mui/material/transitions";
import { useValue } from "../../context/ContextProvider";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

DialogCompomentFull.propTypes = {
  fucName: PropTypes.string,
  txtTile: PropTypes.string,
  onComfirm: PropTypes.func,
};

export default function DialogCompomentFull({ fucName, txtTile, onComfirm }) {
  const { t } = useTranslation();
  const {
    state: { openDialogPayment },
    dispatch,
  } = useValue();
  //const [data, setData] = useState([]);

  const handleClose = () => {
    dispatch({ type: "CLOSE_PAYMENT" });
  };
  useEffect(() => {
    
  }, []);

  const acceptBtn = () => {
    localStorage.setItem("doubleLuck", 2);
    onComfirm()
  };
  const declineBtn = () => {
    localStorage.setItem("doubleLuck", 0);
    onComfirm()
  };
  return (
    <div>
      <Dialog
        fullScreen
        open={openDialogPayment}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {t(txtTile)}
            </Typography>
          </Toolbar>
        </AppBar>
          {fucName === "CART" ? (
            <>
              <div
                className="section wow fadeIn"
                style={{ visibility: "visible", animationName: "fadeIn" }}
              >
                <div class="wrapper">
                  <header>
                    <i class="fa fa-cookie-bite"></i>
                    <h2>{t(txtTile)}</h2>
                  </header>

                  <div class="data">
                    <p> {parse(t("txtAlertDoubleLuck"))}</p>
                  </div>

                  <div class="buttons">
                    <button class="buttonCart" onClick={()=>acceptBtn()}>
                    {t("txtJoinActivity")}
                    </button>
                    <button class="buttonCart" onClick={()=>declineBtn()}>
                      {t("txtNotJoinActivity")}
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : null}
      </Dialog>
    </div>
  );
}
