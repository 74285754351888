import React, { useState, useEffect } from "react";
import {
  Box, Paper, Button
} from '@mui/material';
import { useTranslation } from "react-i18next";
import { HeaderCompoment, TextinputComponent, DialogCompoment, OutlinedInputPass } from '../../compoment'
import { useValue } from '../../context/ContextProvider';
import { requestOTP, requestResetPassword } from '../../actions/token'
import { PoppuOTP } from '../../screen'
export default function ResetPassword() {
  const { t } = useTranslation();
  const {
    state: { tokenInfo },
    dispatch,
  } = useValue();

  const [msisdnError, setmsisdnError] = useState(null);
  const [msisdn, setmsisdn] = useState('');

  const [userNameError, setuserNameError] = useState(null);
  const [userName, setuserName] = useState('');

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);

  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setconfirmPasswordError] = useState(null);


  const [content, setContent] = useState('');
  const [contentError, setcontentError] = useState(null);

  const onmsisdn = (event) => {
    const value = event.target.value;
    const msisdnError =
      !value || value.length < 3 ? t("errorUsername") : null;
    setmsisdn(value);
    setmsisdnError(msisdnError);
  }

  const onUserName = (event) => {
    const value = event.target.value;
    const userNameError =
      !value || value.length < 3 ? t("errorUsername") : null;
    setuserName(value);
    setuserNameError(userNameError);
  }

  const onPassword = (event) => {
    const value = event.target.value;
    const passwordError =
      !value || value.length < 3 ? t("errorUsername") : null;
    setPassword(value);
    setPasswordError(passwordError);
  }

  const onConfirmPassword = (event) => {
    const value = event.target.value;
    const confirmPasswordError =
      !value || value.length < 3 ? t("errorUsername") : null;
    setConfirmPassword(value);
    setconfirmPasswordError(confirmPasswordError);
  }



  const onContent = (event) => {
    const value = event.target.value;
    const contentError =
      !value || value.length < 3 ? t("errorUsername") : null;
    setContent(value);
    setcontentError(contentError);
  }

  const onResetPassword = (event) => {
    event.preventDefault();
    if (userName && password && msisdn && confirmPassword) {
      if (password === confirmPassword) {
        const password_cus = password
        requestOTP(userName, password_cus, confirmPassword, msisdn, content, dispatch)
      } else {
        return dispatch({
          type: 'UPDATE_ALERT',
          payload: {
            open: true,
            severity: 'error',
            message: 'Passoword not map',
          },
        });
      }

    } else {
      return dispatch({
        type: 'UPDATE_ALERT',
        payload: {
          open: true,
          severity: 'error',
          message: t('PleaseEnterUserNamePassword'),
        },
      });
    }
  }


  const onConfrimOTP = (e) => {

    if (e && userName && password && confirmPassword && msisdn) {
      const otpValue = e
      requestResetPassword(userName, msisdn, password, confirmPassword, otpValue, content, tokenInfo, dispatch)

    } else {
      return dispatch({
        type: 'UPDATE_ALERT',
        payload: {
          open: true,
          severity: 'error',
          message: 'Pls check data OTP is not null',
        },
      });
    }

  }

  const onComfirm = () => {
    alert('Login')
  }


  return (
    <Box component="main" sx={{ flexGrow: 1 }}>
      <HeaderCompoment icon={true} iconRige={false} textHeader='ResetPassword' />
      <br />
      <br />
      <br />
      <br />


      <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
        <Paper elevation={12} sx={{ p: 1 }}>
          <TextinputComponent
            error={msisdnError ? true : false}
            margin="normal"
            required
            fullWidth
            label={t("msisdn")}
            value={`${msisdn}`}
            id="msisdn"
            name="msisdn"
            autoComplete="msisdn"
            onChange={onmsisdn}
            autoFocus
            helperText={msisdnError}
            numLength={15}
          />
          <TextinputComponent
            error={userNameError ? true : false}
            margin="normal"
            required
            fullWidth
            label={t("userName")}
            value={`${userName}`}
            id="userName"
            name="userName"
            autoComplete="userName"
            onChange={onUserName}
            autoFocus
            numLength={25}
            helperText={userNameError}
          />


          <OutlinedInputPass
            label={t("Password")}
            value={`${password}`}
            name="password"
            error={passwordError ? true : false}
            onChange={onPassword}
            autoComplete="current-password"
            numLength={10}
            inputMode='numeric'
          />



          {/* <TextinputComponent
            error={passwordError ? true : false}
            margin="normal"
            required
            fullWidth
            label={t("Password")}
            value={`${password}`}
            name="password"
            type="password"
            id="password"
            onChange={onPassword}
            autoComplete="current-password"
            helperText={passwordError}
            numLength={8}
          /> */}

          {/* <TextinputComponent
            error={confirmPasswordError ? true : false}
            margin="normal"
            required
            fullWidth
            label={t("confirmPassword")}
            value={`${confirmPassword}`}
            name="confirmPassword"
            type="password"
            id="confirmPassword"
            onChange={onConfirmPassword}
            autoComplete="current-password"
            helperText={confirmPasswordError}
            numLength={8}
          /> */}

          <OutlinedInputPass
            error={confirmPasswordError ? true : false}
            margin="normal"
            required
            fullWidth
            label={t("confirmPassword")}
            value={`${confirmPassword}`}
            name="confirmPassword"
            type="password"
            id="confirmPassword"
            onChange={onConfirmPassword}
            autoComplete="current-password"
            helperText={confirmPasswordError}
            numLength={10}
          />

          <TextinputComponent
            margin="normal"
            required
            fullWidth
            label={t("content")}
            value={`${content}`}
            name="content"
            type="content"
            id="content"
            onChange={onContent}
            autoComplete="current-password"
            numLength={50}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={(e) => onResetPassword(e)}
            disabled={
              userNameError ||
                passwordError || msisdnError || confirmPasswordError || !userName || !password || !msisdn || !confirmPassword
                ? true
                : false
            }
          >
            {t('comfirm')}
          </Button>

        </Paper>
      </Box>
      <PoppuOTP phoneNumber={msisdn} onConfrimOTP={(e) => onConfrimOTP(e)} />
      <DialogCompoment
        onComfirm={() => onComfirm()}
        Title={t('info')}
        ContentText={t('PasswordResetSuccessful')}
        Actions={t('Confirm')}
        Icon='SUCCESS' />
    </Box>
  )
}
