import React, { useEffect, useState } from "react";
import img from "../../themes/image";
import PropTypes from "prop-types";
import {
  IconButton,
  Grid,
  Badge,
  Avatar,
  Box,
  styled,
  Typography,
  Chip,
  Stack,
  Link,
} from "@mui/material";
import {
  ArrowBackIos,
  ShoppingCart,
  CalendarTodayOutlined,
  Close,
  Reorder,
  AccountCircle,
  Search,
  DeleteSweep,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useValue } from "../../context/ContextProvider";
import Image from "../../themes/image";
import Colors from "../../themes/Colors";
import { deepPurple } from "@mui/material/colors";
import Swal from "sweetalert2";
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "95%",
  maxHeight: "70%",
  borderRadius: 0,
});

HeaderCompoment.propTypes = {
  textHeader: PropTypes.string,
  icon: PropTypes.bool,
  iconRige: PropTypes.bool,
  icondat: PropTypes.bool,
  alertPopup: PropTypes.bool,
  lang: PropTypes.bool,
  onChangeLang: PropTypes.func,
  runCart: PropTypes.bool,
  pathBack: PropTypes.string,
  iconNav: PropTypes.bool,
  onOpenLeft: PropTypes.func,
  numCart: PropTypes.array,
  logoHeader: PropTypes.bool,
  onDatePicker: PropTypes.func,
  onBackClear: PropTypes.bool,
  height: PropTypes.number,
  showBoxHeader: PropTypes.bool,
  txtlotIdDate: PropTypes.string,
  showHeaderReport: PropTypes.bool,
  txttotalMember: PropTypes.number,
  txtCusSurname: PropTypes.string,
  txtDateUpdate: PropTypes.string,
  iconSearch: PropTypes.bool,
  oniconSearch: PropTypes.func,
  clearDoudle: PropTypes.bool,
};
export default function HeaderCompoment({
  oniconSearch,
  iconSearch,
  txtDateUpdate,
  txtCusSurname,
  txttotalMember,
  txtlotIdDate,
  textHeader,
  icon,
  iconRige,
  icondat,
  alertPopup,
  lang,
  onChangeLang,
  runCart,
  pathBack,
  iconNav,
  onOpenLeft,
  numCart,
  logoHeader,
  onDatePicker,
  onBackClear,
  height,
  showBoxHeader,
  showHeaderReport,
  clearDoudle,
}) {
  const { t, i18n } = useTranslation();
  const {
    state: {},
    dispatch,
  } = useValue();
  const [addCart, setAddCart] = useState([]);

  const navigate = useNavigate();

  const onCloseAert = () => {
    dispatch({ type: "CLOSE_POPUP_OTP" });
  };
  const onlanguagechange = () => {
    onChangeLang();
  };

  const onOpenNavLetf = () => {
    onOpenLeft();
  };

  useEffect(() => {
    if (runCart) {
      const addCart = JSON.parse(localStorage.getItem("addCart"));
      if (addCart) {
        setAddCart(addCart);
      }
    }
  }, []);

  const backfucClear = () => {
    navigate("/");
    dispatch({ type: "GET_CUSTOMER_INFO_BY_MSISDNNAME", payload: [] });
    dispatch({ type: "GET_HISTORY_BUY_LOTO", payload: [] });
  };

  const onclearDoudle = () => {
    Swal.fire({
      title: t("alert"),
      text: t("AreYouSureClearCart"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("doubleLuck");
        localStorage.removeItem("addCart");
        Swal.fire({
          title: t("alert"),
          text: t("successful"),
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Confirm",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "../Cart";
          }
        });
      }
    });
  };

  return (
    <div
      style={{
        backgroundImage: `url(${img.bgHeader})`,
        position: "fixed",
        width: "100%",
        height: height ? height : 80,
        right: 0,
        display: "flex",
        top: 0,
        left: 0,
        margin: 0,
        zIndex: 1040,
        color: Colors.White,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        borderBottomLeftRadius: 22,
        borderBottomRightRadius: 22,
        backgroundPosition: "center bottom",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "100%", padding: 20 }}>
        <Grid container>
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {icon ? (
              <IconButton
                sx={{ color: Colors.White }}
                onClick={() =>
                  pathBack
                    ? navigate(`/${pathBack}`)
                    : onBackClear
                    ? backfucClear()
                    : navigate("/")
                }
              >
                <ArrowBackIos sx={{ width: 20, height: 20 }} />
              </IconButton>
            ) : alertPopup ? (
              <IconButton
                sx={{ color: Colors.White }}
                onClick={() => onCloseAert()}
              >
                <Close sx={{ width: 20, height: 20 }} />
              </IconButton>
            ) : iconNav ? (
              <IconButton
                sx={{ color: Colors.White }}
                onClick={() => onOpenNavLetf()}
              >
                <Reorder sx={{ width: 20, height: 20 }} />
              </IconButton>
            ) : null}
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {logoHeader ? (
              <Img alt="Banner discount" src={Image.logiHeader} />
            ) : (
              <h2
                style={{ color: Colors.White, fontSize: 18, fontWeight: 600 }}
              >
                {t(textHeader)}
              </h2>
            )}
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {iconRige ? (
              <IconButton
                sx={{ color: Colors.White }}
                onClick={() => navigate("Cart")}
              >
                <Badge
                  badgeContent={addCart ? addCart.length : "0"}
                  color="White"
                >
                  <ShoppingCart sx={{ width: 20, height: 20 }} />
                </Badge>
              </IconButton>
            ) : icondat ? (
              <IconButton
                sx={{ color: Colors.White }}
                onClick={() => onDatePicker()}
              >
                <CalendarTodayOutlined sx={{ width: 20, height: 20 }} />
              </IconButton>
            ) : lang ? (
              <IconButton onClick={() => onlanguagechange()}>
                <Box
                  sx={{
                    width: 30,
                    height: 30,
                    borderRadius: 1,
                    backgroundColor: Colors.White,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Avatar
                    alt={`Langet`}
                    src={
                      i18n.language === "lo"
                        ? Image.ic_laosLang
                        : Image.ic_englishLang
                    }
                    sx={{ width: 28, height: 28 }}
                    variant="rounded"
                  />
                </Box>
              </IconButton>
            ) : numCart ? (
              <IconButton
                sx={{ color: Colors.White }}
                onClick={() => navigate("Cart")}
              >
                <Badge
                  badgeContent={numCart.length > 0 ? numCart.length : "0"}
                  color="White"
                >
                  <ShoppingCart sx={{ width: 20, height: 20 }} />
                </Badge>
              </IconButton>
            ) : iconSearch ? (
              <IconButton
                sx={{ color: Colors.White }}
                onClick={() => oniconSearch()}
              >
                <Search sx={{ width: 20, height: 20 }} />
              </IconButton>
            ) : clearDoudle ? (
              <IconButton
                sx={{ color: Colors.White }}
                onClick={() => onclearDoudle()}
              >
                <DeleteSweep sx={{ width: 20, height: 20 }} />
              </IconButton>
            ) : null}
          </Grid>
        </Grid>
      </div>
      {txtlotIdDate !== "" ? (
        showBoxHeader ? (
          <div
            class="card-info-header card-date"
            data-bs-toggle="offcanvas"
            data-bs-target="#calendarOffcanvas"
          >
            <Typography sx={{ color: "#000", m: 1 }}>
              {t("txtDateLot", { txtlotIdDate: txtlotIdDate })}
            </Typography>
            <IconButton
              sx={{ color: "#D90404" }}
              onClick={() => onDatePicker()}
            >
              <CalendarTodayOutlined sx={{ width: 20, height: 20 }} />
            </IconButton>
          </div>
        ) : null
      ) : null}
      {showHeaderReport ? (
        <div
          class="card-info-header card-date"
          data-bs-toggle="offcanvas"
          data-bs-target="#calendarOffcanvas"
        >
          <Stack>
            <Chip
              color="primary"
              variant="outlined"
              avatar={
                <Avatar>
                  <AccountCircle />
                </Avatar>
              }
              label={txtCusSurname || "You name"}
            />
          </Stack>
          <Stack>
            <Chip
              color="primary"
              variant="outlined"
              label={t("totalMember") + " : " + txttotalMember || 0}
            />
          </Stack>
        </div>
      ) : null}
    </div>
  );
}
