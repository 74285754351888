import React, { useEffect } from 'react';
import { Box, Alert, styled } from '@mui/material';
import { HeaderCompoment } from '../../compoment'
import './style.css';
// import { NotificationsNoneOutlined } from '@mui/icons-material';
import image from '../../themes/image'
import { useTranslation } from 'react-i18next'

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '50%',
  maxHeight: '50%'
});
const Notifications = () => {
  const { t } = useTranslation();

  const dataNotification = [
    // {
    //   id: 1,
    //   icon: <NotificationsNoneOutlined color='error' />,
    //   tile: 'สลากรอบใหม่พร้อมขายแล้ว',
    //   details: 'เลือกสลากแล้วซื้อเลย!',
    //   date: '18/11/2565 09:20'
    // }
  ]

  useEffect(() => {


  });



  return (
    <Box component="main" sx={{ flexGrow: 1 }}>
      <HeaderCompoment textHeader='info' />
      <br />
      <br />
      <br />
      <br />

      <Box component="main" sx={{ flexGrow: 1, p: 2 }}>

        {dataNotification.length > 0 ? dataNotification.map((item) => (
          <div key={item.id} class="card-notification">
            <a href="CongratLotto" class="card-link" />
            <div class="card-icon">
              {item.icon}
            </div>
            <div class="card-body">
              <div class="d-flex">
                <h6>{item.tile}</h6>
                <p><small>{item.date}</small></p>
              </div>
              <p>{item.details}</p>
            </div>
          </div>
        )) : <Box sx={{ m: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <Img src={image.iconNoData} alt='IconNodata' />
          <Alert severity="warning">{t('Nodata')}</Alert>
        </Box>}


      </Box>
    </Box>

  )
}
export default Notifications


