import React, { useState, useEffect } from "react";
import { Box, Paper, Button, Typography } from '@mui/material';
import { HeaderCompoment, TextinputComponent, DialogCompoment } from '../../compoment'
import { useValue } from '../../context/ContextProvider';
import { useTranslation } from "react-i18next";
import { requestBodyUpdateAccountAgentCode } from '../../body/bodyRequest'
import { requestUpdateAccount } from '../../actions/ResetClient'
// import { useNavigate } from 'react-router-dom';
import { GetCustomerInfoByMsisdnName } from '../../actions/ResetClient'
import Color from '../../themes/Colors'
// import { SignalWifiStatusbarNull } from "@mui/icons-material";

export default function UpdateCodeAgent() {
    const { t } = useTranslation();
    // const navigate = useNavigate();
    const {
        state: { currentUser, tokenInfo, dataCustomerInfoByMsisdnName },
        dispatch,
    } = useValue();

    const [AgentCode, setAgentCode] = useState('');
    const [AgentCodeError, setAgentCodeError] = useState(null);
    const [checkStatusUpdaetAgentCode, setCheckStatusUpdaetAgentCode] = useState(false);


    useEffect(() => {
        if (currentUser && tokenInfo) {
            if (currentUser.accInfo && tokenInfo.access_token) {
                if (dataCustomerInfoByMsisdnName.length === 0) {
                    const token = tokenInfo.access_token
                    const param = currentUser.accInfo.msisdn
                    GetCustomerInfoByMsisdnName(token, param, dispatch)

                } else {
                    setAgentCode(dataCustomerInfoByMsisdnName[0].agentInvite)

                }
            } else {
                return dispatch({
                    type: 'UPDATE_ALERT',
                    payload: {
                        open: true,
                        severity: 'error',
                        message: t('PleaseEnterUserNamePassword'),
                    },
                });
            }
        } else {
            return dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: t('PleaseEnterUserNamePassword'),
                },
            });
        }
        if (currentUser.agent) {
            setCheckStatusUpdaetAgentCode(true)
        }
    }, [dataCustomerInfoByMsisdnName]);


    const onAgentCode = (event) => {
        const value = event.target.value;
        const AgentCodeError =
            !value || value.length < 3 ? t("errorUsername") : null;
        setAgentCode(value);
        setAgentCodeError(AgentCodeError);
    }


    const onUpdateAccount = (event) => {
        event.preventDefault();
        if (currentUser && tokenInfo) {
            if (currentUser.accInfo) {
                if (AgentCode) {
                    const token = tokenInfo.access_token
                    const cusId = currentUser.accInfo.cusId
                    const cusName = currentUser.accInfo.cusName
                    const cusSurname = currentUser.accInfo.cusSurname
                    const gender = currentUser.accInfo.gender
                    const msisdn = currentUser.accInfo.msisdn
                    const email = currentUser.accInfo.email
                    const agentInvite = AgentCode
                    const cusTypeId = currentUser.accInfo.cusTypeId
                    const bankAcc = currentUser.accInfo.bankAcc
                    const bankName = currentUser.accInfo.bankName
                    const username = currentUser.accInfo.username
                    const password = currentUser.accInfo.password
                    const carriedCode = currentUser.accInfo.carriedCode
                    const carriedName = currentUser.accInfo.carriedName
                    let dataReq = requestBodyUpdateAccountAgentCode(cusId, cusName, cusSurname, gender, msisdn, email, agentInvite, cusTypeId, bankAcc, bankName, username, password, carriedCode, carriedName)
                    requestUpdateAccount(dataReq, token, dispatch)
                } else {
                    return dispatch({
                        type: 'UPDATE_ALERT',
                        payload: {
                            open: true,
                            severity: 'error',
                            message: 'Insufficient information',
                        },
                    });
                }
            } else {
                return dispatch({
                    type: 'UPDATE_ALERT',
                    payload: {
                        open: true,
                        severity: 'error',
                        message: 'Current User accI nfo null',
                    },
                });
            }
        } else {
            return dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: 'Current User null',
                },
            });
        }

    }

    const onRefresh = () => {
        if (currentUser && tokenInfo) {
            if (currentUser.accInfo && tokenInfo.access_token) {
                const token = tokenInfo.access_token
                const param = currentUser.accInfo.msisdn
                GetCustomerInfoByMsisdnName(token, param, dispatch)
            }
        }
    }

    return (
        <Box component="main" sx={{ width: '100%' }}>
            <HeaderCompoment onBackClear={true} icon={true} icondat={false} textHeader='UpdateCodeAgent' />
            <br />
            <br />
            <br />
            <br />
            <Paper elevation={16} sx={{ m: 1 }}>



                <Box sx={{ p: 1 }}>
                    <TextinputComponent
                        error={AgentCodeError ? true : false}
                        margin="normal"
                        required
                        fullWidth
                        label={t("AgentCode")}
                        value={`${AgentCode}`}
                        id="AgentCode"
                        name="AgentCode"
                        autoComplete="password"
                        onChange={onAgentCode}
                        autoFocus
                        disabled ={checkStatusUpdaetAgentCode ? true : false}
                        helperText={AgentCodeError}
                    />
                    {
                        checkStatusUpdaetAgentCode ? <Typography sx={{ color: Color.error, fontSize: 12 }}>{t('woningEditAgentInvite')}</Typography> : null
                    }
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={(e) => onUpdateAccount(e)}
                        disabled={
                            AgentCodeError ||
                                !AgentCode || checkStatusUpdaetAgentCode
                                ? true
                                : false
                        }
                    >
                        {t('Update')}
                    </Button>
                </Box>

            </Paper>


            <DialogCompoment
                onComfirm={() => onRefresh()}
                Title={t('info')}
                ContentText={t('AgentCodeInformationUpdatedSuccessfully')}
                Actions={t('Confirm')}
                Icon='SUCCESS' />
        </Box>

    )
}
