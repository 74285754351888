import React from 'react'
import TextField from '@mui/material/TextField';
import PropTypes from "prop-types";
TextinputComponent.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    autoComplete: PropTypes.string,
    error: PropTypes.bool,
    onChange: PropTypes.func,
    helperText: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    numLength: PropTypes.number,
    inputMode: PropTypes.string,
    disabled: PropTypes.bool,
    requiredStatus: PropTypes.bool,
};
export default function TextinputComponent({ label, name, autoComplete, error, onChange, helperText, type, value, numLength, inputMode, disabled, requiredStatus }) {
    return (
        <TextField
            error={error}
            margin="normal"
            fullWidth
            id={autoComplete}
            label={label}
            name={name}
            required={requiredStatus}
            autoComplete={autoComplete}
            size="small"
            type={type}
            value={value}
            disabled={disabled}
            onChange={onChange}
            helperText={helperText}
            inputProps={{
                maxLength: numLength,
                inputMode: inputMode
            }}

        />
    )
}
