import React, { useState, useEffect } from "react";
import {
  Grid,
  styled,
  Box
} from '@mui/material';
// import { useTranslation } from 'react-i18next'
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { useValue } from '../../context/ContextProvider'
import { GetAppImageByCategory } from '../../actions/ResetClient'
const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
  borderRadius: 10
});

const Sidebaner = () => {
  // const { t } = useTranslation();

  const {
    state: { tokenInfo, getAppImageByCategory },
    dispatch,
  } = useValue();
  const [imgBaner, setImgBaner] = useState([]);

  useEffect(() => {
    if (getAppImageByCategory.length > 0) {
      setImgBaner(getAppImageByCategory)
    } else {
      if (imgBaner.length === 0 && tokenInfo) {
        const param = 1
        const token = tokenInfo.access_token
        GetAppImageByCategory(token, param, dispatch);
        setImgBaner(getAppImageByCategory)
      }
    }

  });

  return (
      <Box sx={{ mb: 2 }}>
        {
          imgBaner.length > 0 ?
            <Grid container>
              <Grid item xs={12} sm={12}>
                <div className="slide-container">
                  <Slide>
                    {
                      imgBaner.map((item, index) => (
                        <div key={index}>
                          <Img alt={item.description} src={item.imgPath} />
                        </div>
                      ))
                    }
                  </Slide>
                </div>
              </Grid>
            </Grid> : null
        }

      </Box>
  )
}
export default Sidebaner
