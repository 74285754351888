import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useValue } from '../../context/ContextProvider';
import img from '../../themes/image'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';
// import moment from 'moment';
import { formatNumber } from '../../utils/Formater'
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PaymentCompleted() {
    const { t } = useTranslation();
    const {
        state: { openDialogPayment, dataBuySuccess, currentUser },
        dispatch,
    } = useValue();

    const [lotDate, setLotDate] = useState('')
    const [cusName, setCusName] = useState('')
    const [listArray, setListArray] = useState(null)
    const navigate = useNavigate();
    const handleClose = () => {
        dispatch({ type: 'CLOSE_PAYMENT' });
    };

    // useEffect
    useEffect(() => {
        if (currentUser) {
            if (currentUser.lotAvaiable) {
                if (currentUser.lotAvaiable.length > 0) {
                    setLotDate(currentUser.lotAvaiable[0].fromLotDate)
                }
            }

            if (currentUser.accInfo) {
                setCusName(currentUser.accInfo.cusName + ' ' + currentUser.accInfo.cusSurname)
            }
        }
        if (dataBuySuccess) {
            if (dataBuySuccess.lstSuccess) {
                if (dataBuySuccess.lstSuccess.length > 0) {
                    setListArray(dataBuySuccess.lstSuccess)
                }

            }
        }

    })

const onClickGoto = (a) => {
   
    dispatch({ type: 'GET_HISTORY_BUY_LOTO_BY_LOT_ID', payload:[]});
    dispatch({ type: 'GET_HISTORY_BUY_LOTO', payload:[]});
    if (a === 'HOME') {
        dispatch({ type: 'CLOSE_PAYMENT' });
        navigate('/')
    } else if (a === 'HISTORY') {
        dispatch({ type: 'UPDATE_SECTION', payload: 2 })
        dispatch({ type: 'CLOSE_PAYMENT' });
    }
}
return (
    <div>
        <Dialog
            fullScreen
            open={openDialogPayment}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <div className="offcanvas-body" style={{ backgroundColor: '#fafafa' }}>
                <div className="hgroup flex-column mt-4">
                    <div className="icons w-48">
                        <img className="w-100" src={img.iconSuccess} alt />
                    </div>
                    <h2 className="h1">{t('PaymentCompleted')}</h2>
                    {/* <div className="icons w-128">
                            <img className="w-100" src={img.ic_login} alt />
                        </div> */}
                </div>
                <div className="card-info receipt">
                    <p className="m-0 d-flex fs-12">
                        <span>{t('TransactionCode')}</span>
                        <span className="ms-auto">{dataBuySuccess ? dataBuySuccess.billId : null}</span>
                    </p>
                    <hr className="dashed my-2" />
                    <table className="table-total receipt">
                        <tbody><tr>
                            <td>{t('RecipientName')}</td>
                            <td>{cusName}</td>
                        </tr>
                            <tr>
                                <td>{t('installmentDate')}</td>
                                <td>{lotDate}</td>
                            </tr>
                            <tr>
                                <td>{t('period')}</td>
                                <td>{dataBuySuccess ? dataBuySuccess.lotId : null}</td>
                            </tr>
                            <tr>
                                <td>{t('BuyData')}</td>
                                <td>{dataBuySuccess ? dataBuySuccess.saleTime : null}</td>
                            </tr>

                        </tbody></table>
                </div>{/*card-info*/}
                <div className="card-info receipt">
                    <h5 className="mb-2">{t('items')}</h5>
                    <table className="table-total receipt">
                        <tbody>
                            {
                                listArray ? listArray.map((row) => (
                                    <tr key={row.lottoNumber}>
                                        <td>{row.lottoNumber}</td>
                                        <td className="text-center">x {row.qty}</td>
                                        <td>{row.total}  {t('bat')}</td>
                                    </tr>
                                )) : null
                            }


                        </tbody></table>
                </div>{/*card-info*/}
                {
                    listArray ?
                        <div className="card-info receipt">
                            <h5>{t('PriceSummary')}</h5>
                            <table className="table-total receipt">
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>
                                            <hr className="dashed my-2" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('TotalMoney')}</td>
                                        <th> {formatNumber(listArray.reduce((a, v) => a = a + parseInt(v.total), 0) + "")} {t('bat')}</th>

                                    </tr>
                                    <tr>
                                        <td>{t('Support')}</td>
                                        <th>0 {t('bat')}</th>
                                    </tr>
                                    <tr>
                                        <td>{t('UseTheDropPart')} 0%</td>
                                        <th>0 {t('bat')}</th>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <hr className="dashed my-2" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('sumTotal')}</td>
                                        <th><h3>{formatNumber(listArray.reduce((a, v) => a = a + parseInt(v.total), 0) + "")} {t('bat')}</h3></th>
                                    </tr>
                                </tbody>
                            </table>
                        </div> : null
                }


                <div className="d-flex pt-2">
                    <button className="btn-link fw-light mx-auto" data-bs-toggle="modal" data-bs-target="#saveSuccessModal" type="button">
                        <span className="icons icon-receipt w-18" />
                        {t('Record')}
                    </button>
                </div>
                {/* navigate('/DetailHistory') */}
                <div className="offcanvas-footer h-142">
                    <div className="card-info card-fixed">
                        <a className="btn btn-lg btn-yellow w-100" onClick={() => onClickGoto('HISTORY')}>
                            {t('ViewPurchaseHistory')}
                        </a>
                        <a className="btn-link btn-lg w-100" onClick={() => onClickGoto('HOME')}>
                            <span className="fs-14">{t('BackToMainPage')}</span>
                        </a>
                    </div>
                </div>
            </div>

        </Dialog>
    </div>
);
}