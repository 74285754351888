import React, { useEffect } from 'react';
import {
  Paper,
  Grid,
  styled,
  Box
} from '@mui/material';
import { useTranslation } from 'react-i18next'
import img from '../../themes/image'
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
  borderRadius:10
});
const slideImages = [
  {
    url: img.banerFooter,
    caption: 'Slide 1'
  },
  {
    url: img.banerFooter,
    caption: 'Slide 2'
  },
  {
    url: img.banerFooter,
    caption: 'Slide 3'
  },
];
const Sidebaner = () => {
  const { t } = useTranslation();


  useEffect(() => {


  });

  return (
    <Box sx={{ mb: 3 }}>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <div className="slide-container">
            <Slide>
              {slideImages.map((slideImage, index) => (
                <div key={index}>
                  <Img alt={slideImage.caption} src={slideImage.url} />
                </div>
              ))}
            </Slide>
          </div>
        </Grid>
      </Grid>
    </Box>
  )
}
export default Sidebaner
