import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Box, Stack } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions';
import { useValue } from '../../context/ContextProvider';
import PropTypes from "prop-types";
import parse from 'html-react-parser';
// import { WarningAmber } from '@mui/icons-material';
import images from '../../themes/image'
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

AertYesorNo.propTypes = {
    Title: PropTypes.string,
    ContentText: PropTypes.string,
    Actions: PropTypes.string,
    Icon: PropTypes.string,
    cancel: PropTypes.string,
    onConfrimDelete: PropTypes.func
};

export default function AertYesorNo({ Title, ContentText, Actions, Icon, cancel, onConfrimDelete }) {

    const {
        state: { openAlertDelete, dataGetInfo },
        dispatch,
    } = useValue();

    const handleClose = () => {
        dispatch({ type: 'CLOSE_ALERT_DELETE', payload: null });
    };

    useEffect(() => {



    });



    const handleConfirm = (e) => {
        onConfrimDelete(e)
    }

    return (
        <div>
            <Dialog
                open={openAlertDelete}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <Box sx={{
                    alignItems: 'center',
                    textAlign: 'center',
                    marginTop: 1
                }}>

                    <img src={Icon === 'ERROR' ? images.ic_WarningAmber : Icon === 'SUCCESS' ? images.ic_Safety : Icon === 'WONING' ? images.ic_woning : null} alt="" width="100" height="100" />

                </Box>

                <DialogTitle sx={{
                    alignItems: 'center',
                    textAlign: 'center',
                    fontSize: 25
                }}>{Title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {parse(ContentText)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Stack direction="row" gap={1}>
                        <Button fullWidth color={Icon === 'ERROR' ? 'error' : Icon === 'SUCCESS' ? 'secondary' : Icon === 'WONING' ? 'warning' : null}
                            variant="contained" onClick={() => handleConfirm(dataGetInfo)}>{Actions}
                        </Button>
                        <Button fullWidth color='DarkGray'
                            variant="contained" onClick={handleClose}>{cancel}
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </div >
    );
}