import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  styled,
  Grid,
  Typography,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { HeaderCompoment } from "../../compoment";
import { useValue } from "../../context/ContextProvider";
import {
  GetManageLottery,
  PartnerGetReportOfPartnerTeam
} from "../../actions/ResetClient";
import moment from "moment";
import Colors from "../../themes/Colors";
import Image from "../../themes/image";


const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "80%",
  maxHeight: "80%",
});

const ImageBox = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '50%',
  maxHeight: '50%'
});

export default function ReportOfTeam() {
  const { t } = useTranslation();
  const {
    state: {
      currentUser,
      tokenInfo,
      dataPartnerGetReportOfPartnerTeam,
      dataListManageLottery
    },
    dispatch,
  } = useValue();
  const [agentName, setAgentName] = useState(null);
  const [totalMember, setTotalMember] = useState(0);
  const [dateUpdate, setdateUpdate] = useState(null);
  const [open2, setOpen2] = useState(false);
  const [lotId, setlotId] = useState("");
  const [cusName, setcusName] = useState("");
  const [cusSurname, setcusSurname] = useState("");
  const toDate = moment(new Date()).format("YYYY-MM-DD");
  useEffect(() => {

    if (currentUser && tokenInfo) {
      if (currentUser.accInfo && tokenInfo.access_token) {
        const msisdn = currentUser.accInfo.msisdn;
        const lotId = currentUser.lotAvaiable
          ? currentUser.lotAvaiable[0].lotId
          : 0;
        const token = tokenInfo.access_token;
        setcusName(currentUser.accInfo.carriedName);
        setcusSurname(currentUser.accInfo.cusSurname);
        if (currentUser.whitelistStatus) {
          if (!dataPartnerGetReportOfPartnerTeam) {
            PartnerGetReportOfPartnerTeam(token, msisdn, lotId, dispatch);
          }
        }

        if (dataListManageLottery && dataListManageLottery.length === 0) {
          GetManageLottery(token, dispatch);
        }
      }
    }
  }, [dataPartnerGetReportOfPartnerTeam, dataListManageLottery]);

  const onlotId = (event) => {
    const value = event.target.value;
    setlotId(value);
    if (currentUser && tokenInfo && value) {
      if (currentUser.accInfo && tokenInfo.access_token) {
        if (currentUser.whitelistStatus) {
        const msisdn = currentUser.accInfo.msisdn;
        const lotId = value;
        const token = tokenInfo.access_token;
        PartnerGetReportOfPartnerTeam(token, msisdn, lotId, dispatch);
        }
      }
    }
  };



  const handleClose = () => {
    setOpen2(false);
  };

  function renderMenuDasbord(listItem) {
    if (listItem) {
      const dataList = [
        {
          id: 3,
          img: Image.ic_dataAnalytics,
          name: "totalPartnerBill",
          total: parseInt(listItem.totalPartnerBill) || 0,
          colors:
            "linear-gradient(90deg, rgba(217,18,18,1) 0%, rgba(210,0,11,1) 0%, rgba(255,120,120,1) 100%)",
          boxShadow: "0 3px 5px 2px",
        },
        {
          id: 4,
          img: Image.ic_dataAnalytics,
          name: "totalPartnerRevenue",
          total: parseInt(listItem.totalPartnerRevenue) || 0,
          colors:
          "linear-gradient(90deg, rgba(217,18,18,1) 0%, rgba(210,0,11,1) 0%, rgba(255,120,120,1) 100%)",
          boxShadow: "0 3px 5px 2px",
        },
        {
          id: 1,
          img: Image.ic_dataAnalytics,
          name: "totalAgentBill",
          total: parseInt(listItem.totalAgentBill) || 0,
          colors:
            "linear-gradient(90deg, rgba(217,18,18,1) 0%, rgba(0,122,16,1) 0%, rgba(3,223,32,1) 100%)",
          boxShadow: "0 3px 5px 2px",
        },
        {
          id: 2,
          img: Image.ic_dataAnalytics,
          name: "totalAgentRevenue",
          total: parseInt(listItem.totalAgentRevenue) || 0,

          colors:
            "linear-gradient(90deg, rgba(217,18,18,1) 0%, rgba(0,122,16,1) 0%, rgba(3,223,32,1) 100%)",
          boxShadow: "0 3px 5px 2px",
        },
        
        {
          id: 5,
          img: Image.ic_dataAnalytics,
          name: "totalSellerBill",
          total: parseInt(listItem.totalSellerBill) || 0,
          colors:
            "linear-gradient(90deg, rgba(217,18,18,1) 0%, rgba(1,136,147,1) 0%, rgba(4,198,249,1) 100%)",
          boxShadow: "0 3px 5px 2px",
        },
        {
          id: 6,
          img: Image.ic_dataAnalytics,
          name: "totalSellerRevenue",
          total: parseInt(listItem.totalSellerRevenue) || 0,
          colors:
            "linear-gradient(90deg, rgba(217,18,18,1) 0%, rgba(1,136,147,1) 0%, rgba(4,198,249,1) 100%)",
          boxShadow: "0 3px 5px 2px",
        },
        {
          id: 7,
          img: Image.ic_sum,
          name: "totalRevenueBuyThemSelf",
          total: parseFloat(listItem.totalPartnerRevenue) + parseFloat(listItem.totalAgentRevenue) + parseFloat(listItem.totalSellerRevenue),
          colors:
            "linear-gradient(90deg, rgba(217,18,18,1) 0%, rgba(6,2,138,1) 0%, rgba(85,144,250,1) 100%)",
          boxShadow: "0 3px 5px 2px",
        },
      ];
      return (
        <>

          <Box
            sx={{
              display: { xs: "grid", md: "grid", marginBottom: 10 },
              gridTemplateColumns: "repeat(2,1fr)",
              gridAutoRows: "minmax(50px, auto)",
              gap: 1,
              textAlign: "center",
              flexDirection: "column",
            }}
          >
            {dataList.slice(0, 6).map((listBox) => (
              <div
                key={listBox.id}
                style={{
                  background: listBox.colors,
                  border: 0,
                  borderRadius: 5,
                  boxShadow: listBox.boxShadow,
                  color: "white",
                  height: null,
                  padding: "0 10px",
                }}
              >
                <Grid container>
                  <Grid item xs={3} container>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Img alt="complex" src={listBox.img} />
                    </Box>
                  </Grid>
                  <Grid item xs={9} container>
                    <Grid item xs container direction="column">
                      <Grid item xs>
                        <Typography
                          gutterBottom
                          variant="subtitle1"
                          component="div"
                          sx={{
                            color: Colors.White,
                            fontSize: 12,
                          }}
                        >
                          {t(listBox.name)}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{
                            color: Colors.White,
                            fontSize: 25,
                            fontWeight: 600,
                          }}
                        >
                          {listBox.total}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            ))}
          </Box>

          <Box
          sx={{
            display: { xs: "grid", md: "grid" },
            gridTemplateColumns: "repeat(1,1fr)",
            gridAutoRows: "minmax(50px, auto)",
            gap: 1,
            textAlign: "center",
            flexDirection: "column",
          }}
        >
          {dataList.slice(6, 7).map((listBox) => (
            <div
              key={listBox.id}
              style={{
                background: listBox.colors,
                border: 0,
                borderRadius: 5,
                boxShadow: listBox.boxShadow,
                color: "white",
                height: null,
                padding: "0 10px",
              }}
            >
              <Grid container>
                <Grid item xs={3} container>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Img alt="complex" src={listBox.img} />
                  </Box>
                </Grid>
                <Grid item xs={9} container>
                  <Grid item xs container direction="column">
                    <Grid item xs>
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        component="div"
                        sx={{
                          color: Colors.White,
                          fontSize: 12,
                        }}
                      >
                        {t(listBox.name)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        sx={{
                          color: Colors.White,
                          fontSize: 25,
                          fontWeight: 600,
                        }}
                      >
                        {listBox.total}
                      </Typography>
                      
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          ))}
        </Box>

        </>
      );
    } else {
      return (
        <Box sx={{ m: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <ImageBox src={Image.iconNoData} alt='IconNodata' />
         
        </Box>
      );
    }
  }



  const oniconSearch = () => {
    setOpen2(true);
  };

  const onLoadData = () => {

  }

  return (
    <Box component="main" sx={{ flexGrow: 1 }}>
      <HeaderCompoment
        txtDateUpdate={dateUpdate}
        txtCusSurname={
          agentName
            ? agentName
            : cusName && cusSurname
              ? cusName + " " + cusSurname
              : ""
        }
        txttotalMember={totalMember}
        height={100}
        icon={true}
        iconRige={false}
        showHeaderReport={false}
        textHeader="TeamSales"
        iconSearch={true}
        oniconSearch={() => oniconSearch()}
      />
      <br />
      <br />
      <br />
      <br />
      <br />

      <Box sx={{ p: 2, overflow: "scroll", height: "100%", width: "100%" }}>
        {
          currentUser ? currentUser.whitelistStatus ? renderMenuDasbord(dataPartnerGetReportOfPartnerTeam) :
            <Box sx={{ m: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <ImageBox src={Image.iconNoData} alt='IconNodata' />
            </Box> : null
        }
        <Dialog
          open={open2}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t("searchLot")}</DialogTitle>
          <DialogContent>
            <TextField
              select
              label={t("lotId")}
              value={`${lotId}`}
              size="small"
              fullWidth
              requiredStatus={false}
              margin="normal"
              onChange={onlotId}
            >
              {dataListManageLottery.map((item) => (
                <MenuItem key={item.manageId} value={item.lotId}>
                  {t("period") +
                    ":" +
                    item.lotId +
                    " - " +
                    t("installmentDate") +
                    ":" +
                    item.fromLotDate}
                </MenuItem>
              ))}
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t("Cancel")}</Button>
          </DialogActions>
        </Dialog>
      </Box>
      <br />
      <br />
      <br />
    </Box>
  );
}
