import React, { useState, useEffect } from "react";
import {
  Box, Paper, Button, Slide, Typography, Dialog
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useValue } from '../../context/ContextProvider';
import { TextinputComponent, HeaderCompoment } from '../../compoment'
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

FullScreenDialog.propTypes = {
  onConfrimOTP: PropTypes.func,
  phoneNumber: PropTypes.string

};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ onConfrimOTP, phoneNumber }) {
  const {
    state: { openPopupOTP },
    dispatch,
  } = useValue();
  const { t } = useTranslation();


  const [otp, setOtp] = useState('');
  const [otpError, setotpError] = useState(null);


  const handleClose = () => {
    dispatch({ type: 'CLOSE_POPUP_OTP' });
  };

  const onSubmitOTP = () => {
    onConfrimOTP(otp)
  }
  const onOtp = (event) => {
    const value = event.target.value;
    const otpError =
      !value || value.length < 6 ? t("errorUsername") : null;
    setOtp(value);
    setotpError(otpError);
  }

  return (
    <div>

      <Dialog
        fullScreen
        open={openPopupOTP}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <HeaderCompoment icon={false} iconRige={false} textHeader='Request OTP' alertPopup={true} />
        <br /><br /><br /><br />
        <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
          <Typography sx={{ textAlign: 'center' }}>
            An OTP was sent to {phoneNumber} as a text message. Enter OTP to login.
          </Typography>
          <Box>

          </Box>

          <Paper elevation={12} sx={{ p: 1 }}>
            <TextinputComponent
              error={otpError ? true : false}
              margin="normal"
              required
              fullWidth
              label={t("otp")}
              value={`${otp}`}
              id="otp"
              name="otp"
              autoComplete="otp"
              onChange={onOtp}
              autoFocus
              helperText={otpError}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => onSubmitOTP()}
              disabled={
                otpError || !otp
                  ? true
                  : false
              }
            >
              {t('send')}
            </Button>
          </Paper>
        </Box>

      </Dialog>
    </div>
  );
}