import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Box,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { useValue } from "../../context/ContextProvider";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import images from "../../themes/image";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

AlertDialogSlide.propTypes = {
  Title: PropTypes.string,
  ContentText: PropTypes.string,
  Actions: PropTypes.string,
  Icon: PropTypes.string,
  onComfirm: PropTypes.func,
};

export default function AlertDialogSlide({
  Title,
  ContentText,
  Actions,
  Icon,
  onComfirm,
  type,
}) {
  const {
    state: { openDialog },
    dispatch,
  } = useValue();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClose = () => {
    if (type === "UPDATE_PROFILE") {
      dispatch({ type: "CLOSE_DIALOG" });
      setTimeout(() => {
        handleLogout()
      }, 1000)
      Swal.fire({
        title: t("info"),
        text: t("AlertLoguotUpdateProfile"),
        icon: 'question',
        confirmButtonText: t("Confirm")
      }).then((result) => {
        if (result.isConfirmed) {
          handleLogout()
        }
      });

    } else {
      onComfirm();
      dispatch({ type: "CLOSE_DIALOG" });
    }
  };

  const handleLogout = () => {
    dispatch({ type: 'UPDATE_USER', payload: null });
    dispatch({ type: 'TOKEN_INFO', payload: null });
    dispatch({ type: 'GET_CUSTOMER_INFO_BY_MSISDNNAME', payload: [] });
    dispatch({ type: 'GET_HISTORY_BUY_LOTO', payload: [] });
    dispatch({ type: 'GET_WINNERLIST', payload: [] });
    dispatch({ type: 'GET_HOTNUMBER_BY_LOTO_ID', payload: [] });
    dispatch({ type: 'GET_DASHBOARD', payload: [] });
    dispatch({ type: 'BANNER_APP', payload: [] });
    dispatch({ type: 'RESULT_SEARCH_LOTO', payload: [] })
    dispatch({ type: 'GET_HISTORY_BUY_LOTO_BY_LOT_ID', payload: [] });
    dispatch({ type: 'LIST_LOTO_BUY_SUCCESS', payload: [] });
    dispatch({ type: 'GET_NETWORK_MEMBER', payload: null });
    dispatch({ type: 'GET_CUSTOMER_POINT', payload: null });
    dispatch({type: 'GET_REPORT_OF_PARTNER_TEAM', payload: null});
    navigate('/');
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box
          sx={{
            alignItems: "center",
            textAlign: "center",
            marginTop: 1,
          }}
        >
          <img
            src={
              Icon === "ERROR"
                ? images.ic_WarningAmber
                : Icon === "SUCCESS"
                ? images.ic_Safety
                : null
            }
            alt=""
            width="100"
            height="100"
          />
        </Box>

        <DialogTitle
          sx={{
            alignItems: "center",
            textAlign: "center",
            fontSize: 25,
          }}
        >
          {Title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {ContentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            color={
              Icon === "ERROR"
                ? "error"
                : Icon === "SUCCESS"
                ? "secondary"
                : null
            }
            variant="contained"
            onClick={handleClose}
          >
            {Actions}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
