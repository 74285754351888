import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
} from "@mui/material";
import {
  PriceChange,
  Home,
  Notifications,
  AccountCircle,
  Archive,
  Diversity3,
} from "@mui/icons-material";
import { useEffect, useRef } from "react";
import {
  HomeScreen,
  Notification,
  Account,
  Flash,
  BuyLoto,
  Invite,
} from "../../screen";
import { useValue } from "../../context/ContextProvider";
import { useTranslation } from "react-i18next";
import { DialogCompoment } from "../../compoment";

const BottomNav = () => {
  const {
    state: { section },
    dispatch,
  } = useValue();
  const { t } = useTranslation();
  const ref = useRef();
  useEffect(() => {
    ref.current.ownerDocument.body.scrollTop = 0;
  }, [section]);
  const onComfirm = () => {};
  return (
    <Box ref={ref}>
      {
        {
          0: <HomeScreen />,
          1: <BuyLoto />,
          2: <Flash />,
          3: <Invite />,
          4: <Account />,
        }[section]
      }
      <Paper
        elevation={2}
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1010 }}
      >
        <BottomNavigation
          showLabels
          value={section}
          onChange={(e, newValue) =>
            dispatch({ type: "UPDATE_SECTION", payload: newValue })
          }
        >
          <BottomNavigationAction label={t("Home")} icon={<Home />} />
          <BottomNavigationAction
            label={t("BuyLabel")}
            icon={<PriceChange />}
          />
          <BottomNavigationAction label={t("SafeBox")} icon={<Archive />} />
          <BottomNavigationAction
            label={t("inviteFriend")}
            icon={<Diversity3 />}
          />
          <BottomNavigationAction
            label={t("member")}
            icon={<AccountCircle />}
          />
        </BottomNavigation>
      </Paper>
      <DialogCompoment
        onComfirm={() => onComfirm()}
        Title={t("info")}
        ContentText={t("success")}
        Actions={t("Confirm")}
        Icon="SUCCESS"
      />
      
    </Box>
  );
};

export default BottomNav;
