const reducer = (state, action) => {
  switch (action.type) {
    case "START_LOADING":
      return { ...state, loading: true };
    case "END_LOADING":
      return { ...state, loading: false };
    case "OPEN_LOGIN":
      return { ...state, openLogin: true };
    case "CLOSE_LOGIN":
      return { ...state, openLogin: false };
    case "UPDATE_ALERT":
      return { ...state, alert: action.payload };
    case "OPEN_DIALOG":
      return { ...state, openDialog: true };
    case "CLOSE_DIALOG":
      return { ...state, openDialog: false };

    case "OPEN_BANKLIST_DIALOG":
      return { ...state, openBankList: true };
    case "CLOSE_BANKLIST_DIALOG":
      return { ...state, openBankList: false };

    case "OPEN_ADD_EMP":
      return { ...state, openAddEmp: true };
    case "CLOSE_ADD_EMP":
      return { ...state, openAddEmp: false };
    case "OPEN_ALERT_UPDATE":
      return { ...state, openUpdateEmp: true, dataGetInfo: action.payload };
    case "CLOSE_ALERT_UPDATE":
      return { ...state, openUpdateEmp: false, dataGetInfo: null };
    case "OPEN_ALERT_DELETE":
      return { ...state, openAlertDelete: true, dataGetInfo: action.payload };
    case "CLOSE_ALERT_DELETE":
      return { ...state, openAlertDelete: false, dataGetInfo: null };
    case "UPDATE_SECTION":
      return { ...state, section: action.payload };
    case "UPDATE_USER":
      localStorage.setItem("currentUser", JSON.stringify(action.payload));
      return { ...state, currentUser: action.payload };
    case "TOKEN_INFO":
      localStorage.setItem("tokenInfo", JSON.stringify(action.payload));
      return { ...state, tokenInfo: action.payload };

    case "OPEN_POPUP_OTP":
      return { ...state, openPopupOTP: true };
    case "CLOSE_POPUP_OTP":
      return { ...state, openPopupOTP: false };
    //resultSearchLoto
    case "RESULT_SEARCH_LOTO":
      return { ...state, resultSearchLoto: action.payload };

    case "ADD_TO_CART":
      return { ...state, addCart: action.payload };

    case "OPEN_CART_DIALOG":
      return { ...state, openDialogCart: true, dataAddCart: action.payload };
    case "CLOSE_CART_DIALOG":
      return { ...state, openDialogCart: false, dataAddCart: action.payload };

    case "INFO_APP":
      return { ...state, getAppContentByType: action.payload };
    case "BANNER_APP":
      localStorage.setItem("StorImgBanner", JSON.stringify(action.payload));
      return { ...state, getAppImageByCategory: action.payload };

    case "OPEN_PAYMENT":
      return { ...state, openDialogPayment: true };
    case "CLOSE_PAYMENT":
      return { ...state, openDialogPayment: false };

    case "GET_HISTORY_BUY_LOTO":
      return { ...state, getHistoryBuyLoto: action.payload };

    case "INFO_DATAIL_BUY_LOTO":
      return { ...state, detailInfoBuyLoto: action.payload };
    case "GET_QUESTION":
      return { ...state, questionnaireItem: action.payload };
    // getContact
    case "GET_CONTACT":
      return { ...state, getContact: action.payload };

    //dataHotNumberByLotId
    case "GET_HOTNUMBER_BY_LOTO_ID":
      return { ...state, dataHotNumberByLotId: action.payload };
    //GET_QUESTION_BY_CUSID
    case "GET_QUESTION_BY_CUSID":
      return { ...state, dataCustomerQuestionByCusId: action.payload };
    case "GET_WINNERLIST":
      return { ...state, dataWinner: action.payload };

    case "DETAIL_LIST_WINNER":
      return { ...state, detailListWinner: action.payload };

    //GetCustomerInfoByMsisdnName
    case "GET_CUSTOMER_INFO_BY_MSISDNNAME":
      return { ...state, dataCustomerInfoByMsisdnName: action.payload };

    //success
    case "LIST_LOTO_BUY_SUCCESS":
      return { ...state, dataBuySuccess: action.payload };

    // dataGetDashboard
    case "GET_DASHBOARD":
      localStorage.setItem("StorDashboard", JSON.stringify(action.payload));
      return { ...state, dataGetDashboard: action.payload };

    case "INFO_REMEMBER":
      localStorage.setItem("infoRemember", JSON.stringify(action.payload));
      return { ...state, dataRemember: action.payload };

    case "GET_HISTORY_BUY_LOTO_BY_LOT_ID":
      return { ...state, getHistoryBuyLotoByLotId: action.payload };

    case "CHECK_BILL_STATUS":
      return { ...state, checkBillStatusById: action.payload };
    case "GET_NETWORK_MEMBER":
      return { ...state, dataNetworkMember: action.payload };
    // dataListManageLottery
    case "LIST_MANAGE_LOTORY":
      return { ...state, dataListManageLottery: action.payload };

    case "GET_CUSTOMER_POINT":
      return { ...state, dataCustomerPoint: action.payload };
    case "GET_MEMBEROFPARTNER":
      return { ...state, dataMemberOfPartner: action.payload };
  
    case "GET_REPORT_OF_PARTNER_TEAM":
      return { ...state, dataPartnerGetReportOfPartnerTeam: action.payload };
      case "GET_RATH":
        return { ...state, dataReth: action.payload };
    default:
      throw new Error("No matched action!");
  }
};
export default reducer;
