import React, { useEffect, useState } from "react";
import { Box, Button, styled, Alert } from "@mui/material";
import { HeaderCompoment } from "../../compoment";
import { useTranslation } from "react-i18next";
import { useValue } from "../../context/ContextProvider";
import { reqGetHistoryBuyLoto } from "../../actions/ResetClient";
import { reqBodyGetHistoryBuyLoto } from "../../body/bodyRequest";
import { formatNumber } from "../../utils/Formater";
import { useNavigate } from "react-router-dom";
import { Autorenew } from "@mui/icons-material";
import moment from "moment";
import { DrawerBottom } from "../../screen";
import image from "../../themes/image";
import { red, green, grey } from "@mui/material/colors";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "50%",
  maxHeight: "50%",
});

export default function HistoryBuyTyleAll() {
  const { t } = useTranslation();
  const [dataGroupHis, setDataGroupHis] = useState([]);
  const {
    state: { tokenInfo, getHistoryBuyLoto, currentUser },
    dispatch,
  } = useValue();
  const navigate = useNavigate();
  const [list, setList] = useState(2);

  useEffect(() => {
    if (currentUser && tokenInfo) {
      if (getHistoryBuyLoto) {
        if (getHistoryBuyLoto.responseCode === "00000") {
          groupData(getHistoryBuyLoto.lstHistory);
        } else {
          const token = tokenInfo.access_token;
          const cusId = currentUser.accInfo.cusId;
          const firstResult = 0;
          const maxResult = 50;
          var date = new Date();
          date.setDate(date.getDate() - 30);
          let fromDate = date.toISOString().split("T")[0];
          let toDate = moment(new Date()).format("YYYY-MM-DD");
          let dataReq = reqBodyGetHistoryBuyLoto(
            cusId,
            fromDate,
            toDate,
            firstResult,
            maxResult
          );
          reqGetHistoryBuyLoto(token, dataReq, dispatch);
        }
      }
    } else {
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "warning",
          message: "Current user or token Info null pls check",
        },
      });
    }
  }, [getHistoryBuyLoto]);

  const onLoadData = () => {
    const token = tokenInfo.access_token;
    const cusId = currentUser.accInfo.cusId;
    const firstResult = 0;
    const maxResult = 50;
    var date = new Date();
    date.setDate(date.getDate() - 30);
    let fromDate = date.toISOString().split("T")[0];
    let toDate = moment(new Date()).format("YYYY-MM-DD");
    let dataReq = reqBodyGetHistoryBuyLoto(
      cusId,
      fromDate,
      toDate,
      firstResult,
      maxResult
    );
    reqGetHistoryBuyLoto(token, dataReq, dispatch);
  };

  const groupData = (data) => {
    const listHis = data.reduce(
      (
        hisSoFar,
        {
          id,
          billId,
          lotId,
          saleDate,
          cusId,
          cusName,
          agentId,
          agentName,
          amount,
          discount,
          totalAmount,
          tax,
          saleStatus,
          payStatus,
          timePay,
          createdDate,
          carriedCode,
          carriedName,
          modifiedDate,
          modifiedCode,
          modifiedName,
          promoId,
          nodeName,
          lottoNumber,
          price,
          qty,
          total,
          winnerStatus,
          payWinnerStatus,
          winnerAmount,
          timePayOfNumber,
          costPrice,
        }
      ) => {
        if (
          !hisSoFar[billId + "|" + saleDate + "|" + cusName + "|" + saleStatus]
        )
          hisSoFar[billId + "|" + saleDate + "|" + cusName + "|" + saleStatus] =
            [];
        hisSoFar[
          billId + "|" + saleDate + "|" + cusName + "|" + saleStatus
        ].push({
          id: id,
          billId: billId,
          lotId: lotId,
          saleDate: saleDate,
          cusId: cusId,
          cusName: cusName,
          agentId: agentId,
          agentName: agentName,
          amount: amount,
          discount: discount,
          totalAmount: totalAmount,
          tax: tax,
          saleStatus: saleStatus,
          payStatus: payStatus,
          timePay: timePay,
          createdDate: createdDate,
          carriedCode: carriedCode,
          carriedName: carriedName,
          modifiedDate: modifiedDate,
          modifiedCode: modifiedCode,
          modifiedName: modifiedName,
          promoId: promoId,
          nodeName: nodeName,
          lottoNumber: lottoNumber,
          price: price,
          qty: qty,
          total: total,
          winnerStatus: winnerStatus,
          payWinnerStatus: payWinnerStatus,
          winnerAmount: winnerAmount,
          timePayOfNumber: timePayOfNumber,
          costPrice: costPrice,
        });
        return hisSoFar;
      },
      {}
    );

    const groupArrays = Object.keys(listHis).map((billId) => {
      return {
        bull: billId,
        data: JSON.stringify(listHis[billId]),
      };
    });
    setDataGroupHis(groupArrays);
  };
  function LoopInfoHistory(item) {
    return Object.entries(item).map(([key, value]) =>
      value.bull.split("|")[3] === "1" ? (
        <div key={key} className="card-info receipt mb-2">
          {/* <a onClick={() => onNavigateDetailHistory(value)}> */}
          <table className="table-total receipt mb-0">
            <tbody>
              <tr>
                <td className="fs-12">
                  {t("ReferenceNumber") + ": " + value.bull.split("|")[0]}
                </td>
                <td
                  className="fs-12"
                  style={{
                    color:
                      value.bull.split("|")[3] === "1"
                        ? green[500]
                        : value.bull.split("|")[3] === "0"
                        ? red[500]
                        : grey[500],
                  }}
                >
                  <strong>
                    {value.bull.split("|")[3] === "1"
                      ? t("successful")
                      : value.bull.split("|")[3] === "0"
                      ? t("NotSuccec")
                      : null}
                  </strong>
                </td>
              </tr>
              <tr>
                <td>{t("myPurchase")}</td>
                <td className="fs-20">
                  <strong>
                    {formatNumber(
                      JSON.parse(value.data).reduce(
                        (a, v) => (a = a + parseInt(v.total)),
                        0
                      ) + ""
                    )}{" "}
                    {t("bat")}
                  </strong>
                </td>
              </tr>
              <tr className="grey">
                <td className="fs-12">
                  <div className="date">
                    <span className="icons1 icon-clock"></span>
                    {t("BuyData") + ": " + value.bull.split("|")[1]}
                  </div>
                </td>
                <td className="fs-12">0 {t("score")}</td>
              </tr>
            </tbody>
          </table>
          {/* </a> */}
          <div className="lotto-lists pt-2">
            {JSON.parse(value.data)
              .slice(0, list)
              .map((row) =>
                currentUser.viewImageLottery ? (
                    <img
                      src={
                        process.env.REACT_APP_IMAGE + row.lotId +'/'+ row.lottoNumber + ".png"
                      }
                      alt="Snow"
                      className="img-hover-shadow"
                      style={{ width: "100%" }}
                    />
                ) : (
                  <div key={row.id} className="card-lotto">
                    <div className="card-row row-1">
                      <div className="set s-2">
                        <span>
                          {t("period")} {row.lotId}
                        </span>
                      </div>
                      <div className="logo">
                        <span className="icons"></span>
                        <span>{t("GovernmentLottery")}</span>
                      </div>
                    </div>
                    <div className="card-row row-2">
                      <div className="number-lists textgroup">
                        <div style={{ left: 3 }}>
                          <span style={{ letterSpacing: "0.5cm" }}>
                            {row.lottoNumber}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-row row-3">
                      <div className="textgroup">
                        <span>
                          {t("period")} {row.lotId}
                        </span>
                        <span>{t("set")} 1</span>
                        <span className="viewer">{t("totalAndService")}</span>
                      </div>
                      <span className="price">
                        <strong>{row.price}</strong> {t("bat")}
                      </span>
                    </div>
                  </div>
                )
              )}

            {JSON.parse(value.data).length > 2 ? (
              list != 1000 ? (
                <div className="d-flex py-2">
                  <button
                    onClick={() => setList(1000)}
                    className="btn btn-xs viewmore mx-auto"
                  >
                    {t("SeeAll")}
                  </button>
                </div>
              ) : null
            ) : null}

            {list === 1000 ? (
              <div className="d-flex py-2">
                <button
                  onClick={() => setList(2)}
                  className="btn btn-xs viewmore mx-auto"
                >
                  {t("ShowLess")}
                </button>
              </div>
            ) : null}
          </div>
        </div>
      ) : null
    );
  }
  // const onNavigateDetailHistory = (value) => {

  //     navigate('/DetailHistory')
  //     dispatch({ type: 'INFO_DATAIL_BUY_LOTO', payload: value });
  // }

  const onDatePicker = () => {
    dispatch({ type: "OPEN_CART_DIALOG", payload: null });
  };

  const valuesData = (startDate, EndDate) => {
    let time = moment(new Date()).format("HHmmss");
    let s = moment(startDate).format("YYYYMMDD") + time;
    let e = moment(EndDate).format("YYYYMMDD") + time;
    let now = moment(new Date()).format("YYYYMMDDHHmmss");
    if (s <= e) {
      if (e <= now) {
        const token = tokenInfo.access_token;
        const cusId = currentUser.accInfo.cusId;
        const firstResult = 0;
        const maxResult = 50;
        let fromDate = startDate;
        let toDate = EndDate;
        let dataReq = reqBodyGetHistoryBuyLoto(
          cusId,
          fromDate,
          toDate,
          firstResult,
          maxResult
        );
        reqGetHistoryBuyLoto(token, dataReq, dispatch);
      } else {
        return dispatch({
          type: "UPDATE_ALERT",
          payload: {
            open: true,
            severity: "warning",
            message: t("fromEndDateNow"),
          },
        });
      }
    } else {
      return dispatch({
        type: "UPDATE_ALERT",
        payload: {
          open: true,
          severity: "warning",
          message: t("formatData"),
        },
      });
    }
  };

  return (
    <Box component="main" sx={{ width: "100%" }}>
      <HeaderCompoment
        onBackClear={true}
        icon={true}
        icondat={true}
        textHeader="myLottery"
        onDatePicker={() => onDatePicker()}
      />
      <br />
      <br />
      <br />
      <br />

      <div className="section">
        {dataGroupHis.length > 0 ? (
          LoopInfoHistory(dataGroupHis)
        ) : (
          <Box
            sx={{
              m: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Img src={image.iconNoData} alt="IconNodata" />
            {/* <Alert severity="warning">  {t('Nodata')} </Alert> */}
            <Button
              onClick={() => onLoadData()}
              size="small"
              variant="outlined"
              startIcon={<Autorenew />}
            >
              {t("reloadData")}
            </Button>
          </Box>
        )}
      </div>
      <DrawerBottom
        tyleBar="DATA_HISTOLY"
        valuesData={(startDate, EndDate) => valuesData(startDate, EndDate)}
      />
    </Box>
  );
}
