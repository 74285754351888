import React, { useState, useEffect } from "react";
import { Box, Paper, List, ListItem, ListItemAvatar, Typography, ListItemText, styled, IconButton, Button, Alert } from '@mui/material';
import { HeaderCompoment, TextinputComponent, DialogCompoment } from '../../compoment'
import img from '../../themes/image'
import { Edit, LibraryAddCheck } from '@mui/icons-material';
import { useValue } from '../../context/ContextProvider';
import { useTranslation } from "react-i18next";
import { BankList } from '../../screen'
import { requestBodyUpdateAccount } from '../../body/bodyRequest'
import { requestUpdateAccount } from '../../actions/ResetClient'
import { useNavigate } from 'react-router-dom';
import { GetCustomerInfoByMsisdnName } from '../../actions/ResetClient'

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '50%',
    maxHeight: '50%',
    borderRadius: 10
});
export default function BankAccount() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        state: { currentUser, tokenInfo, dataCustomerInfoByMsisdnName },
        dispatch,
    } = useValue();
    const [newBankAccError, setnewBankAccError] = useState(null);
    const [bankAcc, setbankAcc] = useState('');
    const [bankName, setbankName] = useState(null);
    const [edit, setEdit] = useState(false);
    const [logoBank, setlogoBank] = useState(null);
    const [codeBank, setcodeBank] = useState(null);
    const [fullName, setfullName] = useState(null);
    const [newNameBank, setnewNameBank] = useState(null);
    const [newBankAcc, setnewBankAcc] = useState('');

    const [getCodeBank, setgetCodeBank] = useState(null);



    useEffect(() => {

        //console.log('----dataCustomerInfoByMsisdnName-- AccounBank-:', dataCustomerInfoByMsisdnName)
        if (currentUser && tokenInfo) {
            if (currentUser.accInfo && tokenInfo.access_token) {
                if (dataCustomerInfoByMsisdnName.length === 0) {
                    const token = tokenInfo.access_token
                    const param = currentUser.accInfo.msisdn
                    GetCustomerInfoByMsisdnName(token, param, dispatch)

                } else {

                    setbankAcc(dataCustomerInfoByMsisdnName[0].bankAcc)
                    setbankName(dataCustomerInfoByMsisdnName[0].bankName)
                    // setnewBankAcc(dataCustomerInfoByMsisdnName[0].bankAcc)
                }
            }
            if (bankName) {
                let str = bankName.split('-')
                const code = str[0]
                // const name = str[1]
                setgetCodeBank(code)
            }

        }

    });

    const onNewbankAcc = (event) => {
        const value = event.target.value;
        const newBankAccError =
            !value || value.length < 3 ? t("errorUsername") : null;
        setnewBankAcc(value);
        setnewBankAccError(newBankAccError);
    }


    const onUpdateAccount = (event) => {
        event.preventDefault();
        if (newBankAcc) {

            if (currentUser && tokenInfo) {

                if (currentUser.accInfo) {
                    const token = tokenInfo.access_token
                    const cusId = currentUser.accInfo.cusId
                    const cusName = currentUser.accInfo.cusName
                    const cusSurname = currentUser.accInfo.cusSurname
                    const gender = currentUser.accInfo.gender
                    const msisdn = currentUser.accInfo.msisdn
                    const email = currentUser.accInfo.email
                    const agentInvite = currentUser.accInfo.agentInvite
                    const cusTypeId = currentUser.accInfo.cusTypeId
                    const bankAcc = newBankAcc
                    const bankNameNew = newNameBank ? newNameBank : bankName
                    const username = currentUser.accInfo.username
                    const password = currentUser.accInfo.password
                    const carriedCode = currentUser.accInfo.carriedCode
                    const carriedName = currentUser.accInfo.carriedName
                    let dataReq = requestBodyUpdateAccount(cusId, cusName, cusSurname, gender, msisdn, email, agentInvite, cusTypeId, bankAcc, bankNameNew, username, password, carriedCode, carriedName)
                    //console.log('----------bankNameNew---------', dataReq)
                    requestUpdateAccount(dataReq, token, dispatch)
                } else {
                    return dispatch({
                        type: 'UPDATE_ALERT',
                        payload: {
                            open: true,
                            severity: 'error',
                            message: 'Current User accI nfo null',
                        },
                    });
                }
            } else {
                return dispatch({
                    type: 'UPDATE_ALERT',
                    payload: {
                        open: true,
                        severity: 'error',
                        message: 'Current User null',
                    },
                });
            }

        } else {
            return dispatch({
                type: 'UPDATE_ALERT',
                payload: {
                    open: true,
                    severity: 'error',
                    message: t('InformationIsncomplete'),
                },
            });
        }

    }

    const onSelectBank = () => {
        dispatch({ type: 'OPEN_BANKLIST_DIALOG' });
    }

    const onComfirm = (item) => {
        let name = item.name + '-' + item.fullName
        setnewNameBank(name)
        setlogoBank(item.img)
        setcodeBank(item.name)
        setfullName(item.fullName)
    }

    const onRefresh = () => {
        setEdit(false)
        if (currentUser && tokenInfo) {
            if (currentUser.accInfo && tokenInfo.access_token) {
                const token = tokenInfo.access_token
                const param = currentUser.accInfo.msisdn
                GetCustomerInfoByMsisdnName(token, param, dispatch)
            }
        }
    }

    const onClickEdit =()=>{
        setEdit(true)
        setnewBankAcc(bankAcc)
    }

    return (
        <Box component="main" sx={{ width: '100%' }}>
            <HeaderCompoment icon={true} icondat={false} textHeader='bankAccount' />
            <br />
            <br />
            <br />
            <br />
            <Paper elevation={16} sx={{ m: 1 }}>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <ListItem alignItems="flex-start" secondaryAction={
                        <IconButton aria-label="comment" onClick={() =>onClickEdit()}>
                            <Edit />
                        </IconButton>
                    }>
                        <ListItemAvatar>
                            {
                                getCodeBank === 'BBL' ? <Img src={img.thaiBank01BBL} alt />
                                    : getCodeBank === 'UOBT' ? <Img src={img.thaiBank02UOBT} alt />
                                        : getCodeBank === 'TTB' ? <Img src={img.thaiBank02UOBT} alt />
                                            : getCodeBank === 'BAY' ? <Img src={img.thaiBank04BAY} alt />
                                                : getCodeBank === 'KBANK' ? <Img src={img.thaiBank05KBANK} alt />
                                                    : getCodeBank === 'KTB' ? <Img src={img.thaiBank06KTB} alt />
                                                        : getCodeBank === 'SCB' ? <Img src={img.thaiBank07SCB} alt /> : null
                            }

                        </ListItemAvatar>
                        <ListItemText
                            primary={bankName ? bankName : null}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        {bankAcc ? bankAcc : null}
                                    </Typography>

                                </React.Fragment>
                            }
                        />
                    </ListItem>
                </List>
            </Paper>

            {edit ?
                <Paper elevation={16} sx={{ m: 1 }}>
                    <Box sx={{ p: 1 }}>
                        <Alert severity="success">
                            <Typography align='left'>{t('EditBankAccount')}</Typography>
                        </Alert>
                    </Box>

                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <ListItem alignItems="flex-start" secondaryAction={
                            <IconButton aria-label="comment" onClick={() => onSelectBank()}>
                                <LibraryAddCheck />
                            </IconButton>
                        }>
                            <ListItemAvatar>

                                {
                                    logoBank ? <Img src={logoBank} alt /> :
                                        getCodeBank === 'BBL' ? <Img src={img.thaiBank01BBL} alt />
                                            : getCodeBank === 'UOBT' ? <Img src={img.thaiBank02UOBT} alt />
                                                : getCodeBank === 'TTB' ? <Img src={img.thaiBank02UOBT} alt />
                                                    : getCodeBank === 'BAY' ? <Img src={img.thaiBank04BAY} alt />
                                                        : getCodeBank === 'KBANK' ? <Img src={img.thaiBank05KBANK} alt />
                                                            : getCodeBank === 'KTB' ? <Img src={img.thaiBank06KTB} alt />
                                                                : getCodeBank === 'SCB' ? <Img src={img.thaiBank07SCB} alt /> : null
                                }
                            </ListItemAvatar>
                            <ListItemText
                                primary={codeBank ? codeBank : bankName ? bankName : null}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {fullName ? fullName : bankAcc ? bankAcc : null}
                                        </Typography>

                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    </List>
                    <Box sx={{ p: 1 }}>
                        <TextinputComponent
                            error={newBankAccError ? true : false}
                            margin="normal"
                            required
                            fullWidth
                            label={t("bankAcc")}
                            value={`${newBankAcc}`}
                            id="bankAcc"
                            name="bankAcc"
                            autoComplete="bankAcc"
                            onChange={onNewbankAcc}
                            autoFocus
                            helperText={newBankAccError}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={(e) => onUpdateAccount(e)}
                            disabled={newBankAccError || !bankAcc || !newBankAcc ? true : false}>
                            {t('Update')}
                        </Button>
                    </Box>

                </Paper>
                : null}
            <BankList onComfirm={(item) => onComfirm(item)} />
            <DialogCompoment
                onComfirm={() => onRefresh()}
                Title={t('info')}
                ContentText={t('BankAccountUpdated')}
                Actions={t('Confirm')}
                Icon='SUCCESS' />
        </Box>

    )
}
