const fetchData = async (
    { url, method = '', body = null, Authorization = '', Signature = '', ClientId = '' },
    dispatch
) => {
    body = body ? { body: JSON.stringify(body) } : {};
    const headers = {
        "Content-Type": "application/json",
        "Signature": Signature,
        "Authorization": `Bearer ${Authorization}`,
        "ClientId": ClientId
    }
    try {
        const response = await fetch(url, { method, headers, ...body });
        if (response.status === 401) {
            dispatch({ type: 'UPDATE_USER', payload: null });
            dispatch({ type: 'END_LOADING' });
            throw new Error('JWT token was expired. Please get new token and try again');
        } else {
            
            //console.log('response:', response)
            const data = await response.json();
            if (data.responseCode === "10912" || data.status === false) {
                //console.log("JWT token was expired. Please get new token and try again")
                dispatch({ type: 'UPDATE_USER', payload: null });
                dispatch({ type: 'END_LOADING' });
                throw new Error(data.message);
            }
            return data;
        }

    } catch (error) {
        //console.log('fetch error:')
        dispatch({
            type: 'UPDATE_ALERT',
            payload: { open: true, severity: 'error', message: error.message },
        });
        //console.log(error);
        return null;
    }
};
export default fetchData;
