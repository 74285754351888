import { Alert, Snackbar } from '@mui/material';
import React from 'react';
import { useValue } from '../../context/ContextProvider';
import { useTranslation } from 'react-i18next'
const Notification = () => {
  const { t } = useTranslation();
  const {
    state: { alert },
    dispatch,
  } = useValue();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    dispatch({ type: 'UPDATE_ALERT', payload: { ...alert, open: false } });
  };

  return (
    <Snackbar
      open={alert.open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={handleClose}
        severity={alert.severity}
        sx={{ width: '100%' }}
        variant="filled"
        elevation={6}>
        {t('info')} - {alert.message}
      </Alert>

    </Snackbar>
  );
};

export default Notification;



// import * as React from 'react';
// import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Box } from '@mui/material'
// import { TransitionProps } from '@mui/material/transitions';
// import { useValue } from '../../context/ContextProvider';
// import { useTranslation } from 'react-i18next'
// import images from '../../themes/image'
// const Transition = React.forwardRef(function Transition(
//   props: TransitionProps & {
//     children: React.ReactElement<any, any>;
//   },
//   ref: React.Ref<unknown>,
// ) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });



// export default function Notification() {

//   const {
//     state: { alert },
//     dispatch,
//   } = useValue();
//   const { t } = useTranslation();

//   const handleClose = (event, reason) => {
//     if (reason === 'clickaway') return;
//     dispatch({ type: 'UPDATE_ALERT', payload: { ...alert, open: false } });
//   };
//   return (
//     <div>
//       <Dialog
//         open={alert.open}
//         TransitionComponent={Transition}
//         keepMounted
//         onClose={handleClose}
//         aria-describedby="alert-dialog-slide-description"
//         fullWidth={true}
//         maxWidth='sm'
//       >
//         <Box sx={{
//           alignItems: 'center',
//           textAlign: 'center',
//           marginTop: 1
//         }}>

//           <img src={alert.severity === 'error' ? images.ic_WarningAmber : alert.severity === 'info' || alert.severity ==='success' ? images.ic_Safety : null} alt="" width="100" height="100" />

//         </Box>

//         <DialogTitle sx={{
//           alignItems: 'center',
//           textAlign: 'center',
//           fontSize: 25
//         }}>{t('info')}</DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-slide-description" textAlign={'center'}>
//             {alert ? alert.message : null}
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           {
//             alert ? <Button fullWidth color={alert.severity === 'error' ? 'error' : alert.severity === 'info' ? 'secondary' : null}
//               variant="contained" onClick={handleClose}>Contine</Button> : null
//           }
//         </DialogActions>
//       </Dialog>
//     </div >
//   );
// }
