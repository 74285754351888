import React, { useEffect } from 'react';
import {
  Box
} from '@mui/material';
import { Sidebaner, LotoScreen, MenuTeb, Messages, NumberLucky, NavBar } from '../../screen'
import { useValue } from '../../context/ContextProvider';

const HomeScreen = () => {
  const { dispatch } = useValue();
  useEffect(() => {
    dispatch({ type: 'START_LOADING' });
    setTimeout(() => {
      dispatch({ type: 'END_LOADING' });
    }, 1000)
  },[]);
  //e00404
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 2, backgroundColor: '#DA0404' }}>
      <br /><br /><br />
      <NavBar />
      <Sidebaner />
      <Messages />
      <MenuTeb />
      <NumberLucky />
      <LotoScreen hien={false} />
      {/* <SidebarFooter/> */}
      {/* <MyLoto textColor='white' /> */}
      {/* <LotoHot textColor='white'/> */}
      {/* <ResultsLoto/> */}
      <br />
      <br />
      <br />
      <br />
    </Box>
  )
}
export default HomeScreen
