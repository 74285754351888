export function requestLogin(staffId, password) {
  let data = {
    "staffId": staffId,
    "password": password,
  };
  return data;
}


export function requestGetToken(username, password, secret_key) {
  let data = {
    "username": username,
    "password": password,
    "secret_key": secret_key
  };
  return data;
}
export function requestHMACSHA256(second_key, token) {
  // second_key=premier_dev@2023!,signed_field_names=second_key,signed_field_names,username,token,username=thai_lottery_cms,token=Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0aGFpX2xvdHRlcnlfY21zIiwiZXhwIjoxNjgwODg1Mzk1LCJpYXQiOjE2ODA4ODE3OTV9.nw1gAUlFEhms9innIMvekVgHUJ-Zp_Aro41MejfifmY
  let data = `second_key=${second_key},signed_field_names=second_key,signed_field_names,username,token,username=thai_lottery_customer,token=Bearer ${token}`;
  return data
}

export function requestLoginCustomer(user_cus, pass_cus,lang) {
  let data = {
    "username": user_cus,
    "password": pass_cus,
    "otpValue": null,
    "language": lang
  };
  return data;
}

export function requestBodyOTP(msisdn, content) {
  let data = {
    "msisdn": msisdn,
    "content": content ? content : 'customer  reset password'
  };
  return data;
}

export function requestBodyResetPassword(msisdn, content, username, password, confirmPassword, otpValue) {
  let data = {
    "msisdn": msisdn,
    "content": content ? content : 'Customer reset password',
    "username": username,
    "password": password,
    "confirmPassword": confirmPassword,
    "otpValue": otpValue
  };
  return data;
}

export function requestLoginCustomerReset(user_cus, pass_cus) {
  let data = {
    "username": user_cus,
    "password": pass_cus,
    "otpValue": null
  };
  return data;
}

//cusName, cusSurname, gender, msisdn, email, agentInvite, cusTypeId, bankAcc, bankName, username, password, carriedCode, carriedName, inviteStatus, parentId
export function requestBodyRegiterAccount(cusName, cusSurname, gender, msisdn, email, agentInvite, cusTypeId, bankAcc, bankName, username, password, carriedCode, carriedName,  inviteStatus, parentId) {
  let data = {
    "cusName": cusName,
    "cusSurname": cusSurname,
    "gender": gender,
    "msisdn": msisdn,
    "email": email,
    "agentInvite": agentInvite,
    "cusTypeId": cusTypeId,
    "bankAcc": bankAcc,
    "bankName": bankName,
    "username": username,
    "password": password,
    "carriedCode": carriedCode,
    "carriedName": carriedName,
    "inviteStatus": inviteStatus,
    "parentId" :parentId
  };
  return data;
}

export function requestBodyUpdateAccount(cusId, cusName, cusSurname, gender, msisdn, email, agentInvite, cusTypeId, bankAcc, bankNameNew, username, password, carriedCode, carriedName) {
  let data = {
    "cusId": cusId,
    "cusName": cusName,
    "cusSurname": cusSurname,
    "gender": gender,
    "msisdn": msisdn,
    "email": email,
    "agentInvite": agentInvite,
    "cusTypeId": cusTypeId,
    "bankAcc": bankAcc,
    "bankName": bankNameNew,
    "username": username,
    "password": password,
    "carriedCode": carriedCode,
    "carriedName": carriedName,
    "cusStateId": "1",
    "updateAgentCode": 'null'
  };
  return data;
}

export function requestBodyUpdateAccountAgentCode(cusId, cusName, cusSurname, gender, msisdn, email, agentInvite, cusTypeId, bankAcc, bankNameNew, username, password, carriedCode, carriedName) {
  let data = {
    "cusId": cusId,
    "cusName": cusName,
    "cusSurname": cusSurname,
    "gender": gender,
    "msisdn": msisdn,
    "email": email,
    "agentInvite": agentInvite,
    "cusTypeId": cusTypeId,
    "bankAcc": bankAcc,
    "bankName": bankNameNew,
    "username": username,
    "password": password,
    "carriedCode": carriedCode,
    "carriedName": carriedName,
    "cusStateId": "1",
    "updateAgentCode": "update"
  };
  return data;
}


export function reqBodyFindLotteryNumber(lotId, number, digit, lottType, limit) {
  let data = {
    "lotId": lotId,
    "number": number,
    "digit": digit,
    "lottType": lottType,
    "limit": limit
  };
  return data;
}

export function reqBodyBuyLoto(billId, lotId, cusId, cusName, agentId, agentName, amount, discount, totalAmount, tax, carriedCode, carriedName, promoId, lotoBuy, doubleLuck) {
  let data = {
    "billId": billId,
    "lotId": lotId,
    "cusId": cusId,
    "cusName": cusName,
    "agentId": agentId,
    "agentName": agentName,
    "amount": amount,
    "discount": discount,
    "totalAmount": totalAmount,
    "tax": tax,
    "carriedCode": carriedCode,
    "carriedName": carriedName,
    "promoId": promoId,
    "listNumber": lotoBuy,
    "doubleLuck": doubleLuck,
    "uploadStatementFile": null,
    "paymentMethod": null,
    "statementStatus":null
  };
  return data;
}

export function reqBodyBuyLotoPayment(billId, lotId, cusId, cusName, agentId, agentName, amount, discount, totalAmount, tax, carriedCode, carriedName, promoId, lotoBuy, doubleLuck,fileName) {
  let data = {
    "billId": billId,
    "lotId": lotId,
    "cusId": cusId,
    "cusName": cusName,
    "agentId": agentId,
    "agentName": agentName,
    "amount": amount,
    "discount": discount,
    "totalAmount": totalAmount,
    "tax": tax,
    "carriedCode": carriedCode,
    "carriedName": carriedName,
    "promoId": promoId,
    "listNumber": lotoBuy,
    "doubleLuck": doubleLuck,
    "uploadStatementFile": fileName,
    "paymentMethod": "umoney",
    "statementStatus": "uploaded"
  };
  return data;
}




export function reqBodyGetHistoryBuyLoto(cusId, fromDate, toDate, firstResult, maxResult) {
  let data = {
    "cusId": cusId,
    "fromDate": fromDate,
    "toDate": toDate,
    "firstResult": firstResult,
    "maxResult": maxResult
  };
  return data;
}


export function reqBodCcustomerQuestion(cusId, cusName, msisdn, questId, questionContent, carriedCode, carriedName, language) {
  let data = {
    "cusId": cusId,
    "cusName": cusName,
    "msisdn": msisdn,
    "questId": questId,
    "questionContent": questionContent,
    "carriedCode": carriedCode,
    "carriedName": carriedName,
    "language": language
  };
  return data;
}


export function reqBodyGetHistoryByLotId(cusId, fromDate, toDate, firstResult,maxResult, lotId) {
  let data = {
    "cusId": cusId,
    "fromDate": fromDate,
    "toDate": toDate,
    "firstResult": firstResult,
    "maxResult": maxResult,
    "lotId": lotId
  };
  return data;
}
